.tagsList {
    width: 100%;
    height: 100%;
    align-items: stretch;

    &__block {
        width: 50%;
        padding: 24px 24px 0 36px;

        &._current {
            background: $colorBlueBack;
            padding: 24px 36px 0 24px;
        }

        &Content {
            width: 100%;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                pointer-events: none;
                width: 100%;
                height: 20px;
            }

            &Scroll {
                width: 100%;
                height: 100%;
                @extend %noscroll;
                position: relative;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
                transition: $trans;
            }
        }

        &._loading &ContentScroll {
            opacity: 0;
        }

        &._current &Content {
            height: 490px;

            &::before {
                height: 10px;
                background: linear-gradient(to bottom, $colorBlueBack, rgba($colorBlueBack, 0));
            }
        }

        &._list &Content {
            height: 446px;

            &::before {
                background: linear-gradient(to bottom, #fff, #fff 50%, rgba(#fff, 0));
            }
        }

        &Empty {
            pointer-events: none;

            & .empty {
                padding: 14px !important;
                border-radius: 9px;
            }

            &._active {
                width: 100%;
                height: auto;
                position: absolute;
                top: auto;
                bottom: 24px;
                left: 0;

                &._withButton {
                    bottom: 80px;
                }
            }

            &:not(._active)._withButton {
                padding-bottom: 24px;
            }
        }

        &Button {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 20;
            padding: 20px 0;
            background: linear-gradient(to top, $colorBlueBack, rgba($colorBlueBack, 0));
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                transform: translate(0, 10px);
            }
        }
    }

    &:not(._ready) &__blockContentScroll {
        opacity: 0;
    }

    &__title {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.3;
        font-weight: 600;
        color: $colorDark2;
        margin-bottom: 10px;

        &Item {
            display: inline-block;
            font-size: 16px;
            font-weight: 300;
            line-height: .7;

            &._parent {
                margin-left: 1px;
            }
        }
    }

    &__block._current &__title {
        margin-bottom: 0;
    }

    &__search {
        width: 100%;
    }

    &__items {
        width: 100%;
        position: relative;
        padding: 20px 0;
        transition: $trans;
    }

    ._parentCalc._current &__items,
    &__block._current &__items {
        padding-top: 10px;
    }

    &__item {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    ._parentForce &__item,
    &__items._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    ._parentForce &__item:not(._last),
    &__items._static &__item:not(._last) {
        margin-bottom: 6px;
    }

    ._parentForce &__item._group:not(._first),
    &__items._static &__item._group:not(._first) {
        margin-top: 16px;
    }

    &__item {
        width: 100%;
        padding-left: 12px;
        font-size: 15px;
        line-height: 1.3;
        font-weight: 300;

        &._group {
            font-weight: 600;
            padding-bottom: 2px;
        }

        &Point {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: 0;
            height: calc(100% - 22px);
            width: 2px;
            border-radius: 0 10px 10px 0;
            z-index: 2;
        }

        &._group &Point {
            width: 6px;
            height: 6px;
            border-radius: 50%;
        }

        &:not(._group) {
            border-radius: 9px;
            background: $colorBlueBack;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 40px;
        }

        &._active {
            background: #fff;
        }

        &Action {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 4px;
            width: 28px;
            height: 28px;
            z-index: 2;
        }
    }
}