.contentBlogInner {
    width: 100%;
    height: 100%;

    &__content {
        width: 100%;
        height: calc(100% - 60px);
        height: 100%;
        // min-height: calc(100vh - #{$heightWidgetHead} - 2 * #{$paddingContent} - 60px);
        padding: var(--paddingWidget);

        &Inner {
            position: relative;
            width: 100%;
            transition: $trans;
        }
    }
}