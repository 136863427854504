.v2cursorSortes {
    position: absolute;
    z-index: 10000;

    &__item {
        position: absolute;
        z-index: 1;
        overflow: hidden;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._left {
                transform: translate(-3px, 0);
            }

            &._right {
                transform: translate(3px, 0);
            }
        }

        &Action {
            position: relative;
            color: $colorDark2;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            transition: $trans;

            &:not(:last-child) {
                border-bottom: 1px solid rgba($colorBlue, 0.64);
            }

            &Inner {
                @include button(0.96);

                width: 100%;
                padding: 12px 38px 13px 16px;
                background: #fff;
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 20px;
                    height: 20px;
                }
            }

            &Box {
                position: absolute;
                top: 50%;
                right: 10px;
                width: 20px;
                height: 20px;
                background: $colorBlueBack;
                border-radius: 6px;
                transform: translate(0, -50%);
                transition: $trans;

                &._active {
                    background: $colorMain;
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 14px;
                height: 14px;
                transform: translate(-50%, -50%);
                transition: $trans;

                @include icon(#fff);

                &:not(._current) {
                    opacity: 0;
                }
            }
        }
    }
}
