.v2popupCall {
    width: 100%;

    &__blocks {
        width: 100%;

        &Item {
            width: 100%;
        }
    }

    &__block {
        align-items: center;
        width: 100%;

        &Loader {
            width: 24px;
            height: 24px;
            margin-bottom: 12px;
        }

        &Title {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.3;
            text-align: center;
        }

        &Description {
            color: $colorDark2;
            font-weight: 300;
            font-size: 15px;
            line-height: 1.4;
            text-align: center;

            & b {
                font-weight: 500;
            }
        }

        &Button {
            width: 100%;
            margin-top: 16px;
        }

        &Error {
            width: 100%;
            color: $colorAlert;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.3;
            text-align: center;
            transition: $trans;

            &._parent:not(._empty) {
                margin-top: 12px;
            }
        }
    }
}
