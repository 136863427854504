.publicBlog {
    width: 100%;
    padding: 9rem 0 11.5rem;

    @include mob {
        padding: 6rem 0 4rem;
    }

    &__inner {
        width: $widthPublicWideInner;

        @include mob {
            width: 100%;
            padding: 0 3rem;
        }
    }

    &__head {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 4rem;

        @include mob {
            position: relative;
            flex-direction: column;
            justify-content: flex-start;

            &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                width: calc(100% + 6rem);
                height: 1px;
                background: rgba(#b4bec8, 0.5);
                transform: translate(-50%, 0);
                content: "";
                pointer-events: none;
            }
        }

        &Block {
            @include mob {
                width: 100%;
            }
        }

        &Title {
            margin-bottom: 3.5rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 5.2rem;

            @include mob {
                font-size: 4.7rem;
            }
        }

        &TabsWrapper {
            @extend %noscroll;

            @include mob {
                width: calc(100% + 6rem);
                margin-left: -3rem;
                overflow-x: auto;
            }
        }

        &Tabs {
            align-items: center;
            transition: $trans;

            @include mob {
                display: inline-flex;
                padding: 0 3rem;
            }

            &:not(._show) {
                opacity: 0;
            }
        }

        &Tab {
            position: relative;
            padding-bottom: 1rem;
            color: $colorDark2;
            font-size: 2rem;
            line-height: 1.3;
            transition: $trans;

            @include mob {
                padding-bottom: 2.2rem;
                font-size: 2.4rem;
                white-space: nowrap;
            }

            &._current {
                font-weight: 600;

                &::after {
                    opacity: 1;
                }
            }

            & + & {
                margin-left: 4rem;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background: $colorMain;
                border-radius: 10px 10px 0 0;
                opacity: 0;
                transition: $trans;
                content: "";
            }
        }

        &Search {
            width: 37rem;
            height: 5rem;

            @include mob {
                width: 100%;
                height: 7rem;
                margin-bottom: 3.5rem;
            }

            & .search {
                background: rgba(#eff2f5, 0.72) !important;
            }
        }
    }

    &__loader._loader {
        height: 40rem;

        @include mob {
            height: 35rem;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        transition: $trans;
    }

    &:not(._ready) &__articles {
        opacity: 0;
    }

    &__articles {
        position: relative;
        width: calc(100% + 2rem);
        margin-left: -1rem;
        transition: $trans;

        @include mob {
            width: 100%;
            margin-left: 0;
        }

        &._static {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            @include mob {
                flex-flow: column nowrap;
                align-items: center;
            }
        }
    }

    &__article {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: calc(100% / 3);
        min-height: 38rem;
        padding: 0 1rem;
        transition: $trans;

        @include mob {
            width: 100%;
            height: auto;
            padding: 0;
        }

        &._main {
            width: calc(calc(100% / 3) * 2);

            @include mob {
                width: 100%;
            }
        }

        &._info {
            z-index: 1;

            @include mob {
                height: 35rem;
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &._info &Inner {
            background: $colorBlueBack;
            border-radius: 3rem;

            @include mob {
                border-radius: 4.5rem;
            }
        }

        &:not(._show) &Inner {
            opacity: 0;
        }
    }

    &__articles._static &__article {
        position: relative;
        margin-bottom: 3rem;

        &._info {
            flex: 1 auto;
        }

        &:not(._show) {
            display: none;
        }
    }

    &__loader._loaderScroll {
        bottom: -11.5rem;
        height: 30rem;
        padding: 19rem 0 3rem;
    }
}
