.v2sideBar {
    width: 100%;
    height: 100%;

    &__head {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 110px;

        &Wrapper {
            position: relative;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            height: 100%;
            padding: 0 24px;
            overflow: hidden;
            background: $colorBlueBack;
            border: 1px solid transparent;
            border-radius: 14px;
            transition: $trans;
        }

        &Info {
            position: relative;
            z-index: 1;
            display: inline-flex;
            height: 100%;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                border-radius: 14px;
                box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
                opacity: 0;
                transition: $trans;
                content: "";
            }
        }

        &List {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 0;
            width: 100%;
            padding-top: 2px;
            transition: $trans;
        }

        &:not(._showList) &List {
            opacity: 0;
            pointer-events: none;
        }

        &:hover &Info::before,
        &._showList &Info::before {
            opacity: 1;
        }

        &Inner {
            width: 60px;
            transition: $trans;
        }

        &:hover &Wrapper,
        &._showList &Wrapper {
            background: #eaedee;
            border-color: #fff;
            border-radius: 14px 3px 3px 14px;
        }

        &._showList &Wrapper {
            border-radius: 14px 3px 3px;
        }

        &:hover &Inner,
        &._showList &Inner {
            width: var(--sideBarHeadWidth);
        }

        &:not(:hover, ._showList) &CompanyContent {
            opacity: 0;
        }

        &Company {
            display: flex;
            align-items: center;
            height: 100%;

            &Logo {
                position: relative;
                z-index: 1;
                width: 60px;
                height: 60px;

                &::after {
                    position: absolute;
                    top: -18px;
                    left: -18px;
                    z-index: 2;
                    width: 44px;
                    height: 44px;
                    opacity: 0;
                    transition: $trans;
                    content: "";

                    @include bg("/img/crm/newYear/kolpak.svg", contain, left top);
                }

                ._design-newYear &::after {
                    opacity: 1;
                }

                & .v2avatar__text {
                    font-size: 22px;
                }

                &Admin {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    z-index: 2;
                    width: 23px;
                    height: 23px;
                    padding: 6px;
                    background: linear-gradient(to bottom, #ff7514, #ff9f29);
                    border-radius: 50%;
                    opacity: 0;
                    transition: $trans;
                }

                &Inner {
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background: #fff;
                    border-radius: 14px;
                    box-shadow: 0 0 3px 0 rgba(#1f2e3e, 0.08);
                }
            }

            &Content {
                position: relative;
                margin-left: 16px;
                transition: $trans;
            }

            &Title {
                position: relative;
                margin-bottom: 2px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 17px;
                line-height: 1.4;
                white-space: nowrap;
                transition: $trans;
            }

            &._withList &Content {
                cursor: pointer;
                user-select: none;

                @include button(0.98);
            }

            &._withList &Title {
                padding-right: 12px;

                &::before {
                    position: absolute;
                    top: 10px;
                    right: 0;
                    border: 2px solid transparent;
                    border-right-color: $colorDark2;
                    border-bottom-color: $colorDark2;
                    transform: rotate(45deg);
                    transition: $trans;
                    content: "";
                }
            }

            &Description {
                color: $colorDark2;
                font-weight: 400;
                font-size: 13px;
                line-height: 1.3;
                white-space: nowrap;
            }
        }

        &._showList &CompanyTitle {
            color: $colorMain;

            &::before {
                margin-top: 3px;
                border-right-color: $colorMain;
                border-bottom-color: $colorMain;
                transform: rotate(-135deg);
            }
        }

        &:not(:hover, ._showList) &Settings {
            opacity: 0;
            pointer-events: none;
        }

        &Settings {
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            padding-left: 2px;
            transition: $trans;

            &Inner {
                justify-content: center;
                height: 100%;
                padding: 16px 25px;
                background: #eaedee;
                border: 1px solid #fff;
                border-radius: 3px 14px 14px 3px;
                box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
                transition: $trans;
            }

            &Buttons {
                position: relative;
                height: 32px;

                &._corporation {
                    width: 100%;
                }

                &._static {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    width: auto;
                }

                &:not(._empty) + &:not(._empty) {
                    margin-top: 6px;
                }

                &Item {
                    height: 100%;
                }
            }

            &Buttons._corporation &ButtonsItem {
                width: 100%;
            }

            &Button {
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 6px 12px 7px 10px;
                color: $colorDark2;
                font-size: 14px;
                white-space: nowrap;
                background: #fff;

                @include icon($colorDark2);

                border-radius: 9px;

                &._admin {
                    padding: 6px 12px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 12px;
                    text-transform: uppercase;
                    background: linear-gradient(to bottom, #ff7514, #ff9f29);

                    @include icon(#fff);
                }

                @include button(0.97);

                &Icon {
                    &._prevArrow {
                        width: 18px;
                        height: 18px;
                        margin-right: 5px;
                        transform: translate(0, 1px);
                    }

                    &._settings {
                        width: 18px;
                        height: 18px;
                        margin-right: 6px;
                    }

                    &._admin {
                        width: 15px;
                        height: 15px;
                        margin-right: 7px;
                        transform: translate(0, -1px);
                    }

                    &._nextArrow {
                        width: 20px;
                        height: 20px;
                        margin-left: 5px;
                    }
                }
            }
        }

        // &SettingsInner._static {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: flex-end;
        // }

        &SettingsButtons._static &SettingsButtonsItem {
            &:not(._last) {
                margin-right: 6px;
            }
        }
    }

    &._admin &__head:hover &__headWrapper,
    &._admin &__head._showList &__headWrapper {
        background: #e2ecff;
    }

    &._admin &__headWrapper,
    &._admin &__contentScroll,
    &._admin &__footUser {
        background: #eaf2ff;
    }

    &._admin &__headSettingsInner,
    &._admin &__navListInner,
    &._admin &__footActionsInner {
        background: #e2ecff;
    }

    &._admin &__headCompanyLogoAdmin {
        opacity: 1;
    }

    &__content {
        width: 100%;
        padding: 5px 0 3px;

        &Inner {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
        }

        &Scroll {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            overflow: hidden;
            overflow-y: auto;
            background: $colorBlueBack;
            border-radius: 14px 14px 3px 3px;
            transition: $trans;
        }
    }

    &__navList {
        position: absolute;
        top: 0;
        left: 100%;
        z-index: 1;
        padding-left: 8px;

        &:not(._show) {
            pointer-events: none;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }

        &Inner {
            position: relative;
            z-index: 1;
            width: 100%;
            padding: 22px;
            overflow: hidden;
            background: #eaedee;
            border: 1px solid #fff;
            border-radius: 14px;
            box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
            transition: $trans;

            &::before {
                position: absolute;
                top: 18px;
                left: 0;
                z-index: 2;
                width: 1px;
                height: 30px;
                background: $colorMain;
                border-radius: 0 360px 360px 0;
                content: "";
            }
        }

        &[data-bottom] &Inner::before {
            top: auto;
            bottom: 18px;
        }

        &Link {
            color: $colorDark2;
            font-weight: 300;
            font-size: 15px;
            line-height: 1.3;
            white-space: nowrap;
            transition: $trans;

            &._current {
                color: $colorMain;
                font-weight: 500;
            }

            & + & {
                margin-top: 8px;
            }

            & span {
                color: $colorAlert;
                font-weight: 500;
            }
        }
    }

    &._admin &__navItem::before {
        background: linear-gradient(to right, #eaf2ff, #deebff, #eaf2ff);
    }

    &__nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 0;

        &Item {
            z-index: 2;
            align-items: center;
            width: 100%;
            padding: 14px 8px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, $colorBlueBack, #eaedee, $colorBlueBack);
                opacity: 0;
                transition: $trans;
                content: "";
            }

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                z-index: 2;
                width: 1px;
                height: 30px;
                background: $colorMain;
                border-radius: 360px 0 0 360px;
                transform: translate(0, -50%);
                opacity: 0;
                transition: $trans;
                content: "";
            }

            &._current::before,
            &._active::before,
            &._active::after {
                opacity: 1;
            }

            &._chats &IconItem,
            &._verification &IconItem {
                transform: scale(1.2);
            }

            &Icon {
                position: relative;
                width: 20px;
                height: 20px;
                margin-bottom: 3px;

                @include icon($colorDark2);

                &Item {
                    width: 100%;
                    height: 100%;
                }
            }

            &Counter {
                height: 13px;
                color: $colorAlert;
                font-weight: 600;
                font-size: 13px;

                &._parent {
                    position: absolute;
                    bottom: 100%;
                    left: 100%;
                    z-index: 2;
                    margin: 0 0 -5px 4px;
                }
            }

            &Content {
                max-width: 100%;
                color: $colorDark2;
                font-size: 13px;
                line-height: 1.3;
                text-align: center;
                transition: $trans;
            }

            &._current &Icon {
                @include icon($colorMain);
            }

            &._current &Content {
                color: $colorMain;
            }
        }
    }

    &._admin &__foot:hover &__footUser {
        background: #e2ecff;
    }

    &__foot {
        position: relative;
        z-index: 3;
        width: 100%;
        margin-top: auto;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 3px 3px 3px 14px;
            box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
            opacity: 0;
            transition: $trans;
            content: "";
        }

        &:hover::before {
            opacity: 1;
        }

        &:hover &User {
            background: #eaedee;
            border-color: #fff;
            border-radius: 3px 3px 3px 14px;
        }

        &User {
            position: relative;
            z-index: 4;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 16px 4px;
            background: $colorBlueBack;
            border: 1px solid transparent;
            border-radius: 3px 3px 14px 14px;
            transition: $trans;

            &Avatar {
                position: relative;
                z-index: 1;
                width: 48px;
                height: 48px;
                margin-bottom: 6px;
                overflow: hidden;
                border-radius: 50%;
            }

            &Name {
                margin-bottom: 2px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.3;
                text-align: center;
            }

            &Role {
                color: $colorDark2;
                font-weight: 300;
                font-size: 13px;
                line-height: 1.3;
                text-align: center;
            }
        }

        &Actions {
            position: absolute;
            bottom: 0;
            left: 100%;
            z-index: 3;
            height: 100%;
            padding-left: 2px;
            transition: $trans;

            &Inner {
                align-items: center;
                justify-content: center;
                height: 100%;
                padding: 10px 24px;
                background: #eaedee;
                border: 1px solid #fff;
                border-radius: 3px 14px 14px 3px;
                box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
                transition: $trans;
            }

            &Button {
                align-items: center;
                justify-content: center;
                font-size: 14px;

                @include button(0.97);

                &._edit {
                    height: 32px;
                    padding: 4px 12px 4px 10px;
                    color: $colorDark2;
                    background: #fff;
                    border-radius: 9px;

                    @include icon($colorDark2);
                }

                &._exit {
                    margin-top: 8px;
                    margin-left: -5px;
                    color: $colorAlert;

                    @include icon($colorAlert);
                }

                &Icon {
                    width: 17px;
                    height: 17px;
                    margin-right: 6px;
                }

                &._exit &Icon {
                    margin-right: 4px;
                }
            }

            &Rules {
                display: inline-block;
                margin-top: 8px;
                color: $colorDark2;
                font-weight: 300;
                font-size: 12px;
                line-height: 1.4;
                border-bottom: 1px solid rgba($colorDark2, 0.32);

                @include button(0.96);
            }
        }
    }

    &__foot:not(:hover) &__footActions {
        opacity: 0;
        pointer-events: none;
    }
}
