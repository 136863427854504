.v2widgetDoc {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);

    &._editFiles {
        background: none;
        border-radius: 0;
        box-shadow: none;
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 22px;
        border-bottom: 1px solid $colorBlue;
    }

    &__name {
        width: calc(100% - 32px);
        margin-left: -8px;

        & .v2input {
            border-radius: 9px;
        }

        & .v2input__support,
        & .v2input__field {
            padding: 6px 12px;
            font-size: 16px;
        }

        & .v2input__field {
            font-weight: 600;
        }

        &._disabled {
            margin-left: 0;

            & .v2input {
                background: none !important;
                border-radius: 0;
            }

            & .v2input__support,
            & .v2input__field {
                padding-right: 0;
                padding-left: 0;
                cursor: default;
            }
        }
    }

    &__counter {
        margin-left: auto;
        color: $colorDark2;
        font-weight: 300;
        font-size: 16px;
    }

    &__content {
        position: relative;
        width: 100%;
    }

    &__filesWrapper {
        width: 100%;
        height: 146px;
        padding: 22px 22px 0;
        overflow: hidden;
        overflow-x: auto;
    }

    &._editFiles &__filesWrapper {
        width: calc(100% + 56px);
        height: 122px;
        margin-left: -28px;
        padding: 0 28px;
    }

    &__files {
        height: 100%;

        &._static {
            display: inline-flex;
        }
    }

    &__file {
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 122px;
        height: 122px;
        overflow: hidden;
        border-radius: 16px;

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        &._add &Back .borderDashed__rect {
            // stroke: $colorGreyDark !important;
        }

        &Icon {
            display: block;
            width: 18px;
            height: 18px;
            margin-bottom: 4px;

            @include icon($colorMain);
        }

        &Text {
            color: $colorMain;
            font-size: 14px;
            line-height: 1.3;
            text-align: center;

            & b {
                font-weight: 600;
            }
        }

        &Info &Text {
            margin: 14px 0 8px;
            color: #fff;
            font-weight: 600;
        }

        // &._add &Text {
        //     color: $colorGreyDark;
        // }

        &Input {
            display: none;
        }

        &Action {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 3;
            width: 26px;
            height: 26px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 0 3px 0 #adb7c58f;

            &._more {
                top: auto;
                bottom: 8px;
            }

            &Icon {
                width: 16px;
                height: 16px;
                margin: auto;
            }

            &._more &Icon {
                width: 20px;
                height: 20px;
            }
        }

        &Preview {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: $trans;

            &._parent {
                background: #fff;
                border: 1px solid rgba($colorGreyDark, 0.32);
                border-radius: 16px;
            }

            &._empty {
                opacity: 0;
                pointer-events: none;
            }

            &Image {
                width: 100%;
                height: 100%;
            }
        }

        &:not(:hover) &Info {
            opacity: 0;
        }

        &Info {
            position: absolute;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 12px;
            background: rgba(#000, 0.72);
            transition: $trans;

            &Actions {
                align-items: center;
            }

            &Action {
                position: relative;
                width: 26px;
                height: 26px;
                margin: 0 2px;
                background: rgba(#000, 0.9);
                border-radius: 8px;

                @include button(0.96);

                &Icon {
                    width: 12px;
                    height: 12px;
                    margin: auto;
                }
            }
        }
    }

    &__files._static &__file:not(._last) {
        margin-right: 10px;
    }

    &__foot {
        width: 100%;
        margin-top: auto;
        padding: 0 22px 22px;
    }

    &__date {
        width: 190px;
    }

    &__more {
        position: absolute;
        right: 22px;
        bottom: 22px;
        z-index: 2;
        width: 28px;
        height: 28px;
        background: $colorBlueBack;
        border-radius: 7px;

        @include button(0.97);

        &Icon {
            display: block;
            width: 20px;
            height: 20px;
            margin: auto;

            @include icon($colorDark2);
        }
    }
}
