.manualContent {
    width: 100%;

    &._full {
        height: 100%;
    }

    &__inner {
        width: 100%;
        position: relative;
    }

    &._full &__inner {
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        transition: $trans;

        &._hide {
            opacity: 0;
        }
    }

    &._full &__content {
        height: 100%;
    }

    &__cards {
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        margin-bottom: -12px;
        position: relative;
        transition: $trans;
        z-index: 2;
    }

    &._absolute &__cards {
        display: block;
        margin-bottom: 0;
    }

    &._absolute &__cards._static {
        @extend %row;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: -12px;
    }

    &__card {
        width: 100%;
        position: relative;
        margin-bottom: 12px;
        border-radius: 14px;
    }

    &__cards._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    ._executor &__card,
    &._executor &__card {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;
        }

        &._main,
        &._passport {
            width: 100%;
        }

        &._driverPassport {
            width: 46%;
            margin-right: 12px;
        }

        &._tags {
            width: calc(34% - 12px);
            margin-right: 12px;
        }

        &._comments {
            width: calc(34% - 12px);
            margin-right: 12px;
        }

        &._verification {
            width: 32%;
        }
    }

    &._executor._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &._car &__card {

        &._main,
        &._params {
            width: 100%;
        }

        &._services,
        &._images {
            width: calc(50% - 8px);
            height: 248px;
        }

        &._images {
            margin-left: auto;
        }
    }

    &._organization &__card {

        &._main,
        &._pay {
            width: 100%;
        }

        &._organizationInfo,
        &._addressMail {
            width: 60%;
        }

        &._comments {
            width: calc(40% - 12px);
            margin-left: auto;
        }
    }

    &._absolute &__card {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__cards._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__actions {
        margin: 0 0 0 auto;
        height: 58px;
        transition: $trans;
        z-index: 1;
        position: relative;

        &:not(._show) {
            height: 0;
            opacity: 0;
        }

        &Inner {
            padding-top: 16px;
        }

        &Button {
            height: 40px;

            &+& {
                margin-left: 8px;
            }
        }
    }

    &__items {
        width: 100%;
        position: relative;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            width: 100%;
            position: relative;
        }

        &._static &Inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: -12px;
        }
    }

    &__item {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: $trans;

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }

        &._info {
            height: 184px;
        }
    }

    ._parentForce &__item,
    &__items._static &__item {
        position: relative;

        &._show {
            margin-bottom: 12px;
        }

        &:not(._show) {
            display: none;
        }
    }
}