.publicBlogInner {
    width: 100%;
    padding: 11.5rem 0 0;

    @include mob {
        padding: 6rem 0 0;
    }

    &__inner {
        position: relative;
        width: $widthPublicWideInner;

        @include mob {
            width: 100%;
            padding: 0 3rem;
        }
    }

    &__head {
        align-items: center;
        margin-bottom: 2.5rem;

        &._mob {
            position: absolute;
            top: 0;
            right: 0;
            padding-top: 0.5rem;

            @include mob {
                justify-content: flex-end;
            }
        }

        @include mob {
            justify-content: space-between;
            padding-top: 7.5rem;
        }

        &Info {
            color: $colorGreyDark;
            font-size: 1.9rem;

            @include mob {
                font-size: 2.3rem;
            }

            &Icon {
                width: 2rem;
                height: 2rem;
                margin-right: 0.7rem;

                @include icon(rgba($colorGreyDark, 0.72));

                @include mob {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

            &._time {
                @include mob {
                    margin: -1.5rem 0 3rem;
                }
            }

            &._time &Icon {
                transform: scale(0.8);
            }

            & + & {
                margin-left: 3rem;

                @include mob {
                    margin-left: 0;
                }
            }

            &._left {
                margin-left: auto;

                @include mob {
                    margin-left: 0;
                }
            }
        }
    }

    &__loader {
        z-index: 2;
        height: 60rem;

        @include mob {
            height: calc(100vh - 10rem);
        }
    }

    &__content {
        position: relative;
        align-items: center;
        width: 100%;

        &Inner {
            width: 112rem;
            min-height: calc(100vh - 20rem);
            transition: $trans;

            @include mob {
                width: 100%;
                min-height: calc(100vh - 10rem);
            }
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__back {
        position: absolute;
        top: -0.4rem;
        left: 0;
        align-items: center;
        color: $colorMain;
        font-size: 1.9rem;

        @include mob {
            left: -0.3rem;
            font-size: 2.3rem;
        }

        &Icon {
            width: 2.7rem;
            height: 2.7rem;
            margin-right: 0.4rem;

            @include icon($colorMain);

            @include mob {
                width: 3.4rem;
                height: 3.4rem;
                margin-top: 0.3rem;
                margin-right: 0.6rem;
            }
        }
    }

    &__article {
        width: 100%;
        padding-bottom: 11rem;

        &Title {
            max-width: 100%;
            margin-bottom: 3rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 5.5rem;
            line-height: 1.25;

            @include mob {
                font-size: 4.5rem;
            }
        }

        &Image {
            display: block;
            width: 100%;
            margin-bottom: 3rem;
            border-radius: 3rem;

            @include mob {
                width: calc(100% + 6rem);
                min-height: 26rem;
                margin-left: -3rem;
                object-fit: cover;
                border-radius: 0;
            }
        }

        &Inner {
            @extend %editorContent;

            &,
            & * {
                font-size: 1.9rem;

                @include mob {
                    font-size: 2.3rem;
                }
            }

            & h2,
            & h2 * {
                font-weight: 600;
                font-size: 3.1rem;

                @include mob {
                    font-size: 3.2rem;
                }
            }

            & h3,
            & h3 * {
                font-weight: 600;
                font-size: 2.7rem;
            }

            & br + br {
                display: block;
                margin-top: 12px;
                content: "";
            }

            & ._ancor {
                color: $colorMain;

                & * {
                    color: $colorMain;
                }
            }

            & img {
                position: relative;
                z-index: 1;
                overflow: hidden;
                border-radius: 3rem;

                @include mob {
                    width: 100% !important;
                }
            }
        }
    }

    &__foot {
        align-items: stretch;
        width: 100%;
        padding-bottom: 1rem;

        &Block {
            width: calc(50% - 0.5rem);
            padding: 8rem;
            background: #eff2f5;

            @include mob {
                padding: 4rem 2.5rem;
            }

            & + & {
                margin-left: 1rem;
            }

            &._one {
                align-items: center;
                width: 100%;

                & * {
                    text-align: center;
                }
            }

            &:not(._one) {
                &._prevArticle {
                    border-radius: 0 1rem 1rem 0;

                    @include mob {
                        padding-left: 1rem;
                    }
                }

                &._nextArticle {
                    border-radius: 1rem 0 0 1rem;

                    @include mob {
                        padding-right: 1rem;
                    }
                }
            }

            &Inner {
                width: 33rem;

                @include mob {
                    width: 100%;
                }
            }

            &._one &Inner {
                align-items: center;
            }

            &:not(._one)._prevArticle {
                align-items: flex-end;
            }

            &:not(._one)._prevArticle &Inner {
                align-items: flex-end;

                & * {
                    text-align: right;
                }
            }

            &._one &Title {
                // margin-left: -1rem;
            }

            &Title {
                position: relative;
                max-width: 100%;
                margin-bottom: 2rem;
                color: $colorDark2;
                font-weight: 600;
                font-size: 2.3rem;

                @include mob {
                    margin-bottom: 1rem;
                    font-size: 2.7rem;
                    line-height: 1.3;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    width: 3rem;
                    height: 3rem;
                    margin-top: 0.1rem;
                    transform: translate(0, -50%);

                    @include icon($colorMain);

                    @include mob {
                        top: 0.1rem;
                        width: 3.5rem;
                        height: 3.5rem;
                        transform: translate(0, 0);
                    }
                }
            }

            &._prevArticle &Title {
                padding-left: 3.5rem;

                @include mob {
                    padding-left: 4rem;
                }

                &Icon {
                    left: 0;
                }
            }

            &._nextArticle &Title {
                padding-right: 3.5rem;

                @include mob {
                    padding-right: 4rem;
                }

                &Icon {
                    right: 0;
                }
            }

            &Name {
                max-width: 100%;
                color: $colorDark2;
                font-size: 2rem;
                line-height: 1.4;

                @include mob {
                    font-size: 2.2rem;
                    line-height: 1.5;
                }
            }
        }
    }
}
