.deals {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    &__pages {
        width: 100%;
        height: 100%;
        transition: $trans;
    }

    &:not(._init) &__pages {
        opacity: 0;
    }

    &__page {
        width: 100%;
        height: 100%;
    }

    &__dragDeal {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999999;

        &._smooth {
            transition: $trans;
        }
    }

    &__scrollDrag {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 10;
    }

    &__inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-x: auto;
    }

    &__steps {
        display: inline-flex;
        height: 100%;
    }

    &__steps._drag &__step[data-current] .dealStep__content {
        background: #ebf0f7;
    }

    &__step {
        width: 376px;
        height: 100%;
        padding: 0 2px;

        &:first-child {
            // padding-left: 0;

            & .dealStep {
                border-bottom-left-radius: 28px;
            }
        }

        &:last-child {
            // padding-right: 0;

            & .dealStep {
                border-bottom-right-radius: 28px;
            }
        }
    }
}
