.dealGroupHead {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 22px;

    &__block {
        align-items: center;
    }

    &__back {
        width: 28px;
        height: 28px;
        margin-right: 10px;
        background: #fff;
        border-radius: 50%;

        &Icon {
            display: block;
            width: 20px;
            height: 20px;
            margin: auto;

            @include icon($colorDark2);
        }
    }

    &__title {
        margin-right: 12px;
        color: $colorDark2;
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;

        & b {
            font-weight: 600;
        }
    }

    &:not(._ready) &__name {
        opacity: 0;
    }

    &__name {
        position: relative;
        height: 32px;
        padding: 4px 32px 4px 12px;
        background: #fff;
        border-radius: 9px;
        transition: $trans;

        & .v2input__support,
        & .v2input__field {
            font-weight: 600;
            font-size: 15px;
        }

        & .v2input__support {
            font-weight: 500;
            white-space: nowrap;
        }

        &Field {
            height: 100%;
            transition: $trans;
        }

        &Icon {
            position: absolute;
            top: 50%;
            right: 11px;
            z-index: 2;
            width: 13px;
            height: 13px;
            transform: translate(0, -50%);

            @include icon($colorGreyDark);
        }
    }

    &__button {
        & + & {
            margin-left: 10px;
        }
    }
}
