.joinsTemplates {
    width: 100%;
    height: 100%;

    &__card {
        width: 100%;

        &+& {
            margin-top: 16px;
        }

        &Head {
            align-items: center;
            width: 100%;
            height: 34px;
            margin-bottom: 1px;
            padding: 6px 18px;
            background: #fbfcfd;
            border-radius: 12px 12px 0 0;
        }

        &._sticky &Head {
            border-radius: 0;
        }

        &._content &Head {
            position: sticky;
            position: -webkit-sticky;
            top: 16px;
            z-index: 11;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
                width: calc(100% + #{var(--paddingWidget)} * 2);
                height: calc(100% + 16px);
                z-index: -2;
                background: $colorBlueBack;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #fbfcfd;
                z-index: -1;
                border-radius: 12px 12px 0 0;
            }
        }

        &Title {
            position: relative;
            margin-right: 6px;
            color: $colorDark;
            font-size: 14px;
            font-weight: 600;
        }

        &Content {
            width: 100%;
            padding: 14px 18px;
            background: #fff;
            border-radius: 0 0 12px 12px;
            box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
            transition: $trans;
        }

        &._content {
            height: calc(100% - 98px);
        }
    }

    &__name {
        width: 100%;
    }

    &__actions {
        width: 100%;
        height: 110px;
        padding: 18px 0 18px 0;
        background: #fff;
        z-index: 10;
        position: sticky;
        position: -webkit-sticky;
        top: 50px;
        left: 0;
        border-bottom: 1px solid transparent;
        transition: $trans;
    }

    &__card._sticky &__actions {
        border-bottom-color: $colorBlue;
    }

    &__logo {
        width: calc(50% - 6px);
        height: 100%;
        border-radius: 15px;
        background: $colorBlueBack;
        padding: 14px;

        &Inner {
            width: 100%;
            height: 100%;
            position: relative;
        }

        &Item {
            width: 100%;
            height: 100%;
            transition: $trans;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            padding-right: 180px;

            &:not(._show) {
                opacity: 0;
                transform: translate(0, -6px);

                &._hide {
                    transform: translate(0, 6px);
                }
            }
        }

        &._static &Item {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Item._image &Preview._svg {
            padding: 8px;
        }

        &Item._image &Preview._svg &PreviewImage {
            object-fit: contain;
        }

        &Preview {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 10px;
            z-index: 1;
            overflow: hidden;

            &Icon {
                width: 100%;
                height: 100%;
                opacity: .48;
            }

            &Image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &Done {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                background: rgba($colorMain, .56);

                &Icon {
                    display: block;
                    margin: auto;
                    width: 24px;
                    height: 24px;
                    @include icon(#fff);
                }
            }
        }

        &Content {
            max-width: calc(100% - 40px);
            padding-left: 10px;
        }

        &Item._image &Title {
            color: $colorDark2;
        }

        &Title {
            max-width: 100%;
            font-size: 16px;
            line-height: 1.2;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            color: $colorGreyDark;
            margin-bottom: 5px;
        }

        &Description {
            max-width: 100%;
            font-size: 13px;
            color: $colorGreyDark;
        }

        &Actions {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            z-index: 2;
            justify-content: flex-end;
            align-items: center;
        }

        &Button {
            padding: 12px 14px;
            border-radius: 8px;
            font-weight: 500;
            background: #fff;
            color: $colorMain;
            font-size: 14.5px;
            transition: $trans;
            @include button(.97);
            display: block;

            & input {
                display: none;
            }
        }

        &Delete {
            width: 22px;
            height: 22px;
            margin-left: 12px;
            @include icon(#C7CED6);
            transition: $trans;
            @include button(.96);
        }
    }

    &__patterns {
        width: calc(50% - 6px);
        height: 100%;
        margin-left: auto;
        position: relative;

        &._active &Head {
            @extend %shadow;

            &Icon {
                transform: rotate(180deg);
            }
        }

        &Head {
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border: 2px solid #fff;
            background: #F3F6FF;
            border-radius: 15px;
            padding: 16px 24px;
            justify-content: center;
            position: relative;
            transition: $trans;
            @include button(.98);
            margin: -2px 0 0 -2px;

            &Title {
                max-width: 100%;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.2;
                margin-bottom: 5px;
                color: $colorDark2;
            }

            &Description {
                max-width: 100%;
                font-size: 13px;
                font-weight: 300;
                color: $colorDark2;
            }

            &Icon {
                position: absolute;
                top: 18px;
                right: 18px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                z-index: 2;
                justify-content: center;
                align-items: center;
                background: #E0E8FF;
                transition: $trans;

                &::before {
                    content: "";
                    border: 2px solid transparent;
                    border-bottom-color: $colorMain;
                    border-right-color: $colorMain;
                    transform: rotate(45deg);
                    margin-top: -2px;
                }
            }
        }

        &List {
            @extend %shadow;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            margin-top: 6px;
            z-index: 2;
            padding: 0 24px;
            background: #fff;
            border-radius: 15px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                transform: translate(0, 6px);
            }

            &Inner {
                width: 100%;
            }

            &Search {
                width: 100%;
                margin: 24px 0 8px 0;
                height: 36px;
            }

            &ItemsWrapper {
                @extend %noscroll;
                width: 100%;
                overflow-y: auto;
                position: relative;
                z-index: 1;
            }

            &Items {
                width: 100%;
                position: relative;
                transition: $trans;
            }

            &Item {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                transition: $trans;
                padding: 12px 0;
                border-bottom: 1px solid transparent;
                align-items: center;

                &:not(._last):not(._end) {
                    border-bottom-color: $colorBlue;
                }

                &:not(._show) {
                    opacity: 0;
                }

                &Support {
                    font-size: 15px;
                    line-height: 1.3;
                    color: $colorDark2;
                    padding-right: 20px;
                }

                &Value {
                    margin-left: auto;
                    border-radius: 9px;
                    background: #F2F5FF;
                    padding: 7px 8px;
                    align-items: center;
                    font-size: 15px;
                    color: $colorMain;
                    transition: $trans;
                    white-space: nowrap;
                    @include button(.97);
                }

                &Copy {
                    width: 14px;
                    height: 14px;
                    margin-left: 5px;
                    @include icon($colorMain);
                }
            }

            &Items._static &Item {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }
        }
    }


}