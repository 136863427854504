.v2widgetCard {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);

    &._hidden {
        z-index: 1;
        overflow: hidden;
    }

    &._empty {
        background: none;
        border-radius: 0;
        box-shadow: none;
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 10px 26px;
        border-bottom: 1px solid $colorBlue;

        &Info {
            margin-left: 10px;
        }

        &Avatar {
            z-index: 1;
            width: 32px;
            height: 32px;
            overflow: hidden;
            border-radius: 50%;
        }
    }

    &__title {
        align-items: center;
        height: 16px;
        color: $colorDark2;
        font-weight: 600;
        font-size: 16px;
        transition: $trans;
    }

    &__description {
        display: inline-block;
        height: 11px;
        font-weight: 300;
        font-size: 16px;
        white-space: nowrap;

        &._parent {
            margin-left: 3px;
        }

        &Inner {
            height: 100%;
            font-weight: 300;
            font-size: 16px;
            line-height: 0.7;
        }
    }

    &__actions {
        align-items: center;
        margin-left: auto;
    }

    &__more {
        display: flex;
        width: 28px;
        height: 28px;
        background: $colorBlueBack;
        border-radius: 8px;

        &Icon {
            width: 22px;
            height: 22px;
            margin: auto;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 26px;

        &._fullHeight {
            height: calc(100% - 60px);
            overflow: hidden;
            overflow-y: auto;
        }

        &._full {
            height: 100%;
        }

        &._empty {
            padding: 0;
        }
    }

    &._empty &__content {
        padding: 0;
    }

    &__fields {
        width: 100%;
        margin-bottom: -14px;

        &._bottom {
            margin-bottom: 0;
        }

        & + & {
            // margin-top: 12px;
        }
    }

    &__field {
        margin-bottom: 14px;

        &._top {
            margin-top: 16px;
            margin-bottom: 0;
        }

        &._full {
            width: 100%;
        }

        &._typeline {
            margin: 10px 0 20px;
            border-bottom: 1px solid $colorBlue;

            &._bottom {
                margin-bottom: 12px;
            }
        }

        &._typecheckbox {
            padding-top: 8px;
        }
    }

    &._payEdit &__field._name,
    &._payEdit &__field._date {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $colorBlue;
    }

    &._contract &__field._createdDate,
    &._userEdit &__field._email {
        margin-top: 8px;
        padding-top: 20px;
        border-top: 1px solid $colorBlue;
    }

    &__blocks {
        align-items: stretch;
        width: 100%;
    }

    &__block {
        width: 100%;
    }

    &._corporationOther &__blocks._COL &__block._logo {
        margin-bottom: 14px;
    }

    &._corporationOther &__blocks._ROW &__block {
        &._logo {
            width: 240px;
            padding-right: 24px;
        }

        &._info {
            width: calc(100% - 240px);
        }
    }

    &._userMain &__block {
        &._logo {
            width: 170px;
        }

        &._info {
            width: calc(100% - 170px - 16px);
            margin-left: auto;
        }
    }

    &._contentBlogMain &__block {
        &._logo {
            width: 250px;
        }

        &._info {
            width: calc(100% - 250px - 16px);
            margin-left: auto;
        }
    }

    &._contentUsersKnowledgeMain &__block,
    &._contentClientsKnowledgeMain &__block {
        &._logo {
            width: 112px;
        }

        &._info {
            width: calc(100% - 112px - 16px);
            margin-left: auto;
        }
    }

    &__tags {
        &._static {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__tags._static &__tag {
        margin: 0 6px 6px 0;
    }

    &__comments {
        position: relative;
        z-index: 1;
        min-height: 100%;
        padding: 26px;
        overflow: hidden;

        &Inner {
            position: relative;
            width: 100%;
        }
    }

    &__comments._static &__comment:not(._last) {
        margin-bottom: 6px;
    }

    &__comment {
        width: 100%;
        min-height: 44px;
        background: $colorBlueBack;
        border-radius: 8px;

        &Btn {
            top: 8px;
            right: 8px;
        }
    }

    &__wallets {
        width: 100%;
    }

    &__wallet {
        width: 100%;
    }

    &__wallets._static &__wallet:not(._last) {
        margin-bottom: 12px;
    }
}
