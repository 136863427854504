.dealCustomFields {
    width: 100%;

    &__list {
        width: 100%;
        transition: $trans;

        &Item {
            width: 100%;

            &Inner {
                width: 100%;
                padding-bottom: 12px;
                border-bottom: 1px solid transparent;
                transition: $trans;
            }

            &._show &Inner {
                border-color: $colorBlue;
            }
        }

        &._static &Item {
            margin-bottom: 12px;
        }
    }

    &__button {
        width: 100%;
        height: 36px;
    }
}
