.page404 {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - #{$paddingContent} * 2);
    padding-bottom: 20px;

    &__inner {
        align-items: center;
    }

    &__title {
        max-width: 100%;
        text-align: center;
        color: $colorMain;
        font-size: 140px;
        letter-spacing: 2px;
        font-weight: 900;
        margin-bottom: 12px;
        position: relative;

        & span {
            color: $colorBlue;
            opacity: 0.72;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 140px;
            height: 140px;
            @include bg('../../img/rocket.gif', contain, center);
            z-index: 2;
        }
    }

    &__description {
        max-width: 100%;
        text-align: center;
        font-size: 19px;
        line-height: 1.4;
        font-weight: 300;
        color: $colorDark;
        margin-bottom: 24px;
    }

    &__button {
        width: 170px;
    }
}