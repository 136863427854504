.publicExecutorsInfo {
    @extend %section;

    width: 100%;
    padding: 7rem 0;

    @include media(0, $mediaM) {
        position: relative;
        z-index: 1;
        padding: 3rem 0;
        overflow: hidden;
    }

    &._fns {
        padding-bottom: 21rem;

        @include media(0, $mediaM) {
            padding-bottom: 5rem;
        }
    }

    &__inner {
        @extend %publicInner;

        width: calc(#{$widthPublicInner} + 10rem);

        @include media(0, $mediaM) {
            width: calc(92vw + 3rem);
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        justify-content: center;
        width: 100%;
        height: 49rem;
        padding: 10rem 18rem;
        border-radius: 4rem;

        @include media(0, $mediaM) {
            justify-content: flex-start;
            height: auto;
            padding: 13.5rem 3rem;
            border-radius: 5rem;
        }

        &::before {
            position: absolute;
            top: 3.7rem;
            right: 3.7rem;
            width: 4.5rem;
            height: 4.5rem;
            content: "";

            @include bg("/img/crm/logo-white.svg", contain, right top);

            @include media(0, $mediaM) {
                top: 7.5rem;
                right: auto;
                left: 50%;
                width: 7.5rem;
                height: 7.5rem;
                background-position: center top;
                transform: translate(-50%, 0);
            }
        }

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: $colorMain;
            border-radius: 4rem;
            mask:
                radial-gradient(circle 1.5rem at top center, #0000 99%, #000) top center,
                radial-gradient(circle 1.5rem at bottom center, #0000 99%, #000) bottom center;
            mask-size: 100% 51%;
            mask-repeat: no-repeat;

            @include media(0, $mediaM) {
                border-radius: 5rem;
                mask:
                    radial-gradient(circle 2.5rem at top center, #0000 99%, #000) top center,
                    radial-gradient(circle 2.5rem at bottom center, #0000 99%, #000) bottom center;
                mask-size: 100% 51%;
                mask-repeat: no-repeat;
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                width: 100%;
                height: 100%;
                background-repeat: repeat-y !important;
                opacity: 0.4;
                content: "";

                @include bg("/img/crm/public/executors-info-back.png", 100% auto, center top);

                @include media(0, $mediaM) {
                    background-size: 200% auto;
                    opacity: 0.4;
                }
            }
        }
    }

    &._fns &__content {
        padding-left: 13rem;

        @include media(0, $mediaM) {
            padding-left: 3rem;
        }
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 1rem;
        z-index: 2;
        width: 80rem;
        height: 80rem;
        margin-top: 1rem;
        object-fit: contain;
        transform: translate(0, -50%);
        pointer-events: none;

        @include media(0, $mediaM) {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 50rem;
            transform: scale(1.21);
            transform-origin: center top;
        }
    }

    &._fns &__image {
        right: 1rem;
        left: auto;

        @include media(0, $mediaM) {
            right: auto;
            margin-top: 0;
        }
    }

    &__info {
        width: 40rem;
        margin-left: auto;

        @include media(0, $mediaM) {
            align-items: center;
            width: 100%;
        }
    }

    &._fns &__info {
        width: auto;
        margin-left: 0;

        @include media(0, $mediaM) {
            width: 100%;
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 1.7rem;
        color: #fff;
        font-weight: 700;
        font-size: 3.8rem;
        line-height: 1.3;

        @include media(0, $mediaM) {
            margin-bottom: 1.3rem;
            font-size: 4rem;
            line-height: 1.2;
            text-align: center;
        }
    }

    &__description {
        max-width: 100%;
        color: #fff;
        font-size: 1.9rem;
        line-height: 1.55;

        @include media(0, $mediaM) {
            font-size: 2.3rem;
            text-align: center;
        }
    }

    &._fns &__description {
        max-width: 41rem;

        @include media(0, $mediaM) {
            max-width: 100%;
        }
    }

    &__actions {
        width: 100%;
        margin-top: 3rem;

        &Button {
            height: 5.6rem;
            margin-top: 2rem;

            @include media(0, $mediaM) {
                width: 100%;
                height: 8rem;
                margin-top: 2.5rem;
            }
        }
    }

    &__appBtns {
        align-items: center;
        height: 5.6rem;

        @include media(0, $mediaM) {
            flex-wrap: wrap;
            justify-content: center;
            width: calc(100% + 2rem);
            height: auto;
            margin-left: -1rem;
        }
    }

    &__appBtn {
        height: 100%;
        margin-right: 1.3rem;

        @include media(0, $mediaM) {
            width: auto;
            height: 8rem;
            margin: 0.5rem;

            & + & {
                // margin-top: 1.5rem;
            }
        }
    }
}
