.contentBlogInnerImage {
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
    background: #fbfcfd;
    transition: $trans;

    &__preview {
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            width: 100%;
            height: 100%;
        }

        &Image {
            position: absolute;
            transition: $trans;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__about {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(#000, .48);
        opacity: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &:hover &__about {
        opacity: 1;
    }

    &__info {
        transition: $trans;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__about &__info {
        opacity: 1;
        position: relative;
    }

    &__icon {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
        @include icon(rgba(#9DA8B4, .48));
    }

    &__title {
        max-width: 100%;
        margin-bottom: 8px;
        color: $colorGreyDark;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        transition: $trans;
    }

    &__about &__title {
        color: #fff;
    }

    &__description {
        max-width: 100%;
        margin-bottom: 12px;
        color: $colorGreyDark;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        transition: $trans;
    }

    &__about &__description {
        color: #fff;
    }

    &__upload {
        align-items: center;
        justify-content: center;
        padding: 8px 14px 9px 14px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        background: $colorMain;
        border-radius: 10px;
    }

    &__input {
        display: none;
    }
}