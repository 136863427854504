.contentBlog {
    width: 100%;
    padding: var(--paddingWidget);

    &__cards {
        width: calc(100% + 12px);
        margin-left: -6px;
        position: relative;
        transition: $trans;

        &:not(._static) {
            margin-bottom: -12px;
        }

        &Inner {
            width: 100%;
        }

        ._parentForce &,
        &._static {
            @extend %row;
            flex-wrap: wrap;
            align-items: stretch;
        }
    }

    ._googleVision &__card,
    &._googleVision &__card {
        width: calc(100% / 5);
        height: 220px;

        &._info {
            height: 220px;
        }
    }

    &__card {
        width: calc(100% / 4);
        padding: 0 6px;
        position: absolute;
        top: 0;
        right: 0;
        // transition: $trans;
        
        &._info {
            width: auto;
            flex: 2 auto;
            min-height: 260px;
        }

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }
    }

    ._parentForce &__card,
    &__cards._static &__card {
        position: relative;
        margin-bottom: 12px;

        &:not(._show) {
            display: none;
        }
    }
}