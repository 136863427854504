.publicBlogPreview {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    border-radius: 3rem;
    overflow: hidden;
    padding: 4.5rem 6rem;
    transition: $trans;
    @include button(.98);

    @include media(0, $mediaM) {
        border-radius: 4.5rem;
        padding: 3rem;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(to top, rgba(#000, .88), rgba(#000, .32));
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
    }

    &__content {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }

    &__head {
        width: 100%;
        align-items: center;
        margin-bottom: auto;

        @include media(0, $mediaM) {
            margin-bottom: 3.5rem;
        }

        &Info {
            align-items: center;
            height: 4rem;
            border-radius: 36rem;
            background: rgba(#000, .8);
            padding: .6rem 1.5rem;
            color: rgba(#fff, .8);
            font-size: 1.95rem;
            font-weight: 600;

            @include media(0, $mediaM) {
                height: 4.5rem;
                font-size: 2rem;
                padding: .8rem 1.7rem;
            }

            &._section {
                color: #fff;
                margin-right: auto;
            }

            &Icon {
                width: 2rem;
                height: 2rem;
                margin-right: 0.7rem;

                @include media(0, $mediaM) {
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }
        }
    }

    &__title {
        width: 60rem;
        max-width: 100%;
        line-height: 1.3;
        font-weight: 600;
        color: #fff;
        font-size: 3.1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;

        @include media(0, $mediaM) {
            padding: 0 1.5rem;
            font-size: 3.4rem;
            min-height: 16rem;
            margin-bottom: 3rem;
        }
    }

    &._main &__title {
        font-size: 3.7rem;
        margin-bottom: 3.5rem;

        @include media(0, $mediaM) {
            font-size: 3.4rem;
            margin-bottom: 3rem;
        }
    }

    &__foot {
        width: 100%;
        align-items: center;
        padding-bottom: 1rem;

        @include media(0, $mediaM) {
            padding: 0 1.5rem 1rem 1.5rem;
        }

        &Time {
            align-items: center;
            font-size: 1.8rem;
            color: #CBFF5D;
            font-weight: 600;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
            }

            &Icon {
                width: 1.7rem;
                height: 1.7rem;
                margin-right: 1rem;

                @include media(0, $mediaM) {
                    width: 1.8rem;
                    height: 1.8rem;
                }
            }
        }

        &Date {
            margin-left: auto;
            font-weight: 500;
            color: #fff;
            font-size: 1.8rem;

            @include media(0, $mediaM) {
                font-size: 2.1rem;
            }
        }
    }
}