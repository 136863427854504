.publicButton {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5.6rem;
    padding: 1.8rem 3.5rem 1.8rem 5rem;
    color: #fff;
    font-weight: 700;
    font-size: 1.7rem;
    background: $colorMain;
    border-radius: 1.4rem;
    box-shadow: 0 0.4rem 0.6rem rgb(62 106 237 / 64%);
    transition: $trans;

    @include button(0.97);

    @include media(0, $mediaM) {
        height: 8rem;
        font-size: 2.3rem;
        border-radius: 2rem;
    }

    &:hover {
        background: $colorMainDark;
    }

    &._white {
        height: 100%;
        padding-right: 3rem;
        padding-left: 3rem;
        background: rgba(#fff, 0.14);
        border-radius: 1.2rem;

        @include media(0, $mediaM) {
            border-radius: 1.8rem;
        }

        &:hover {
            color: $colorMain;
            background: #fff;
        }
    }

    &__icon {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.1rem 0 0 0.5rem;

        @include icon(#fff);

        @include media(0, $mediaM) {
            width: 3.5rem;
            height: 3.5rem;
            margin: 0.2rem 0 0 1.2rem;
        }
    }

    &._white:hover &__icon {
        @include icon($colorMain);
    }
}
