.v2popupKnowledge {
    width: 100%;

    &__blocks {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;

            &._current {
                z-index: 1000 !important;
                transition: none;
            }
        }
    }

    &._static &__blocksItem {
        position: relative;

        &:not([data-last]) {
            padding-bottom: 12px;
        }
    }

    &__block {
        width: 100%;
        padding: 20px;
        background: $colorBlueBack;
        border-radius: 20px;

        &Head {
            position: relative;
            width: 100%;
            margin-bottom: 16px;
            padding-right: 32px;

            &Drag {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 2;
                width: 20px;
                height: 20px;
            }
        }

        &Title {
            color: $colorDark2;
            font-weight: 500;
            font-size: 16px;
            line-height: 1.3;

            & b {
                font-weight: 600;
            }
        }

        &Content {
            position: relative;
            width: 100%;
        }

        &Items {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &Content._static &Item {
            position: relative;
        }

        &Item {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;

            &:not([data-last]) {
                padding-bottom: 6px;
            }

            &._current {
                z-index: 1000 !important;
                transition: none;
            }
        }

        &Option {
            position: relative;
            width: 100%;
            padding: 12px 40px 12px 12px;
            background: #fff;
            border-radius: 12px;

            &._withIcon {
                padding-left: 40px;
            }

            &Icon {
                position: absolute;
                top: 12px;
                left: 12px;
                width: 20px;
                height: 20px;
            }

            &Drag {
                position: absolute;
                top: 12px;
                right: 16px;
                z-index: 2;
                width: 20px;
                height: 20px;
            }

            &Title {
                color: $colorDark2;
                font-weight: 300;
                font-size: 15px;
                line-height: 1.3;
            }
        }
    }
}
