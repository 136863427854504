.dealGroup {
    width: 100%;
    height: 100%;
    background: $colorBlueBack;

    &__head {
        width: 100%;
        height: 58px;
    }

    &__content {
        width: 100%;
        height: calc(100% - 58px);
        overflow: hidden;
        overflow-x: auto;
        background: #ebf0f7;

        &Wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &Inner {
            display: inline-flex;
            min-width: 100%;
            height: 100%;
            transition: $trans;
        }

        &Wrapper:not(._ready) &Inner {
            opacity: 0;
        }

        &Block {
            position: relative;
            height: 100%;

            &._list,
            &._info {
                transition: $trans;
            }

            &._info {
                border-left: 1px solid #fff;
            }

            & .v2infoBlock__title,
            & .v2infoBlock__description {
                color: $colorGreyDark;
            }
        }

        &Steps {
            position: relative;
            z-index: 1;
            display: flex;
            height: 100%;
        }

        &Step {
            width: 376px;
            height: 100%;
        }

        &Block._list &Step {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: $trans;

            &._hide,
            &._new {
                opacity: 0;
            }

            &._current {
                z-index: 1000 !important;
                transition: none;
            }
        }

        ._parentCalc._static &Step {
            position: relative;

            &._hide {
                display: none;
            }
        }

        &Block._default &Step:last-child .dealGroupStep__head {
            border-right-color: #fff;
        }
    }
}
