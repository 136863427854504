.publicBusinessAbout {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 17rem 0;

    @include mob {
        padding: 10rem 0;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        width: calc(100% - 7rem);
        height: 100%;
        background: linear-gradient(to bottom, #e3edfc, rgba(#e3edfc, 0));
        border-radius: 3rem;
        transform: translate(-50%, 0);
        content: "";
        mask:
            radial-gradient(circle 2rem at top center, #0000 99%, #000) top center,
            radial-gradient(circle 2rem at bottom center, #0000 99%, #000) bottom center;
        mask-size: 100% 50%;
        mask-repeat: no-repeat;

        @include mob {
            width: calc(100% - 3rem);
        }
    }

    &__inner {
        align-items: center;

        @include mob {
            padding: 0 2rem;
        }
    }

    &__problem {
        align-items: center;
        margin-bottom: 18rem;

        @include mob {
            margin-bottom: 8rem;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 5rem;
            text-align: center;
        }

        &Cards {
            align-items: stretch;
            justify-content: center;
            width: 100%;

            @include mob {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        }

        &Card {
            padding: 4.5rem 4rem 4rem;
            background: #fff;
            border-radius: 3.5rem;
            box-shadow: 0 0.1rem 0.7rem rgba(#3e454c, 0.04);

            @include mob {
                margin-bottom: 3rem;
            }

            &._1 {
                width: 37rem;

                @include mob {
                    width: 100%;
                }
            }

            &._2 {
                width: 34rem;
                margin-right: 3rem;
                margin-left: 3rem;

                @include mob {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                }
            }

            &._3 {
                width: 41rem;

                @include mob {
                    width: 100%;
                }
            }

            &Icon {
                width: 6.5rem;
                height: 6.5rem;
                margin-bottom: 3rem;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 2rem;
                color: $colorDark2;
                font-weight: 600;
                font-size: 2.5rem;
                line-height: 1.3;

                @include mob {
                    font-size: 2.8rem;
                }
            }

            &Content {
                max-width: 100%;
                color: $colorDark2;
                font-weight: 300;
                font-size: 1.9rem;
                line-height: 1.5;

                @include mob {
                    font-size: 2.1rem;
                }
            }
        }
    }

    &__info {
        align-items: center;
        width: 100%;

        &Head {
            align-items: center;
            width: 42rem;
            margin-bottom: 3rem;

            @include mob {
                margin-bottom: 6rem;
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 2.5rem;
            text-align: center;

            @include mob {
                margin-bottom: 1.5rem;
            }
        }

        &Description {
            max-width: 100%;
            text-align: center;
        }

        &Content {
            position: relative;
            width: 100%;
            height: 85rem;

            @include mob {
                height: auto;
            }
        }

        &CardWrapper {
            position: absolute;

            @include mob {
                position: relative;

                & + & {
                    margin-top: 3rem;
                }
            }

            &::after {
                position: absolute;
                content: "";
            }

            &._1 {
                top: 0;
                left: -2rem;
                width: 38rem;

                @include mob {
                    left: 0;
                }

                &::after {
                    top: 50%;
                    left: 100%;
                    z-index: 2;
                    width: 12.5rem;
                    height: 12.5rem;
                    margin-left: 1rem;

                    @include bg("../../../../img/crm/public/business/about-arrow-1.svg", contain, left top);

                    @include mob {
                        top: 100%;
                        left: -1rem;
                        width: 10rem;
                        height: 10rem;
                        margin: 1rem 0 0;
                        transform: rotateX(180deg) rotateZ(-90deg);
                    }
                }
            }

            &._2 {
                top: 20rem;
                left: 20rem;
                width: 35rem;

                @include mob {
                    top: 0;
                    left: 0;
                    margin-left: auto;
                }

                &::after {
                    top: 4.5rem;
                    left: 100%;
                    z-index: 2;
                    width: 7rem;
                    height: 7rem;
                    margin-left: 1rem;

                    @include bg("../../../../img/crm/public/business/about-arrow-2.svg", contain, left top);

                    @include mob {
                        top: 100%;
                        right: 10rem;
                        left: auto;
                        width: 6rem;
                        height: 6rem;
                        margin: 2rem 0 0;
                        transform: rotateX(180deg) rotate(-90deg);
                    }
                }
            }

            &._3 {
                top: 8rem;
                left: 61rem;
                width: 26rem;

                @include mob {
                    top: 0;
                    left: 0;
                }

                &::after {
                    bottom: -3rem;
                    left: 100%;
                    z-index: 2;
                    width: 8rem;
                    height: 8rem;
                    margin-left: 1rem;

                    @include bg("../../../../img/crm/public/business/about-arrow-3.svg", contain, left top);

                    @include mob {
                        bottom: -1rem;
                    }
                }
            }

            &._4 {
                top: 28rem;
                right: 6rem;
                width: 47rem;

                @include mob {
                    top: 0;
                    right: 0;
                    width: 100%;
                }

                &::after {
                    top: 100%;
                    right: 4rem;
                    z-index: 2;
                    width: 12rem;
                    height: 12rem;
                    margin-top: 1rem;

                    @include bg("../../../../img/crm/public/business/about-arrow-4.svg", contain, left top);

                    @include mob {
                        right: -3rem;
                        width: 10rem;
                        height: 10rem;
                    }
                }
            }

            &._5 {
                right: 23.5rem;
                bottom: 20rem;
                width: 40.5rem;

                @include mob {
                    right: 0;
                    bottom: 0;
                    width: 36rem;
                }

                &::after {
                    top: 100%;
                    right: 5.5rem;
                    z-index: 2;
                    width: 12rem;
                    height: 12rem;
                    margin-top: 1.5rem;

                    @include bg("../../../../img/crm/public/business/about-arrow-5.svg", contain, left top);

                    @include mob {
                        right: auto;
                        left: 1rem;
                    }
                }
            }

            &._6 {
                right: -2rem;
                bottom: 0;
                width: 30rem;

                @include mob {
                    right: 0;
                    margin-left: auto;
                }
            }
        }

        &CardWrapper._6 &Card {
            background: linear-gradient(to top, $colorMain, #72a3f7);

            &Number {
                color: $colorMain;
                background: #fff;
            }

            &Title,
            &Content {
                color: #fff;
            }

            &Content {
                font-weight: 500;
            }
        }

        &Card {
            width: 100%;
            padding: 3.5rem 2.5rem 3rem 3.5rem;
            background: #fff;
            border-radius: 3rem;
            box-shadow: 0 0.2rem 0.8rem rgba(#363c42, 0.06);

            @include mob {
                padding: 3rem 2rem 3rem 2.5rem;
            }

            &Number {
                position: absolute;
                top: 2.3rem;
                right: 2.3rem;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.6rem;
                height: 2.6rem;
                color: #fff;
                font-weight: 600;
                font-size: 1.9rem;
                background: $colorDark2;
                border-radius: 0.9rem;
            }

            &Title {
                margin-bottom: 1.2rem;
                color: $colorDark2;
                font-weight: 600;
                font-size: 2.3rem;

                @include mob {
                    font-size: 2.5rem;
                }
            }

            &Content {
                color: $colorDark2;
                font-weight: 300;
                font-size: 1.85rem;
                line-height: 1.5;

                @include mob {
                    font-size: 1.9rem;
                }
            }
        }

        &Support {
            position: absolute;
            bottom: 20rem;
            left: 21.5rem;
            width: 34rem;
            padding: 3.5rem 2rem 3.5rem 4rem;
            color: $colorDark2;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.5;
            background: rgba($colorMain, 0.06);
            border-radius: 3rem;

            @include mob {
                position: relative;
                bottom: 0;
                left: 0;
                width: 100%;
                margin-top: 6rem;
                font-size: 2rem;
                line-height: 1.6;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 0.2rem;
                height: calc(100% - 8rem);
                background: $colorMain;
                border-radius: 0 3rem 3rem 0;
                transform: translate(0, -50%);
                content: "";
            }

            & b {
                font-weight: 600;
            }
        }
    }
}
