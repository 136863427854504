.manualCardMainPreview {
    width: 100%;
    height: 100%;
    background: $colorBlueBack;
    border-radius: 10px;
    transition: $trans;

    &._executor {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &._edit {
        background: $colorBlueActive;
    }

    &._error {
        background: rgba($colorAlert, 0.075);
    }

    &._executor &__item._image {
        padding: 0;
    }

    &__item {
        position: relative;
        height: 100%;

        &._image {
            position: relative;
            width: 42%;
            padding: 8px;
            border-right: 2px solid #fff;

            & .avatar__item {
                font-size: 28px;
            }
        }

        &._content {
            justify-content: center;
            width: 58%;
            padding: 10px;
        }

        &Inner {
            width: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &._executor &__image {
        &Item {
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        &Support {
            max-width: 100%;
            margin: auto;
            color: rgba($colorMain, 0.7);
            font-weight: 500;
            font-size: 13px;
            line-height: 1.3;
            text-align: center;
        }

        &Item {
            width: 100%;
            height: 100%;
            margin: auto;
            object-fit: contain;
            transition: $trans;
        }

        &Change {
            position: absolute;
            bottom: 14px;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, 0);
            transition: $trans;

            &:not(._show) {
                transform: translate(-50%, 4px);
                opacity: 0;
            }

            &Link {
                color: $colorMain;
                font-weight: 500;
                font-size: 11px;
                border-bottom: 1px dashed rgba($colorMain, 0.7);
                transition: $trans;

                @include button(0.95);
            }
        }
    }

    &._empty &__image {
        &Item {
            opacity: 0.64;
        }
    }

    &._car._edit &__image {
        &Item {
            transform: translate(0, -4px);
        }
    }

    &__field {
        width: calc(100% + 6px);
        margin-left: -6px;
        transition: $trans;

        &._select {
            height: 18px;
        }

        & + & {
            margin-top: 2px;
        }
    }

    &._edit &__field {
        width: 100%;
        margin-left: 0;
    }
}
