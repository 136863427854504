.v2widgetDocs {
    width: 100%;
    height: 100%;

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__items {
        height: 100%;

        &._static {
            display: inline-flex;
        }
    }

    &__item {
        width: 300px;
        height: 100%;

        &._add {
            background: #fbfcfd;
            border-radius: 16px;
        }
    }

    &._editFiles &__item {
        width: 100%;
    }

    &__items._static &__item:not(._last) {
        margin-right: 12px;
    }
}
