.manualList {
    width: 100%;
    height: 100%;

    &__inner {
        @extend %noscroll;
        width: calc(100% + 44px);
        height: calc(100% + 36px);
        position: relative;
        margin-top: -18px;
        margin-left: -22px;
        overflow-y: auto;
        padding: 12px 22px;
    }

    &__items {
        width: 100%;
        position: relative;
    }

    &._static &__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__item {
        width: 100%;
        padding: 2px 0;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Content {
            font-size: 14.5px;
            line-height: 1.3;
            font-weight: 500;
            color: $colorDark2;
            transition: $trans;
        }

        &:not(._active) &Content {
            color: $colorMain;
        }

        &Value {
            position: relative;
            margin-left: auto;
            width: 56px;
            height: 24px;

            &Inner {
                transition: $trans;
                height: 100%;
                padding: 6px 16px;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.3;
                justify-content: center;
                align-items: center;
                background: $colorBlueBack;
                border-radius: 8px;

                &:not(._show) {
                    opacity: 0;
                }
            }
        }

        &Action {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            width: 26px;
            height: 26px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &._static &__item {
        position: relative;

        &:not(._last) {
            margin-bottom: 6px;
        }

        &:not(._show) {
            display: none;
            margin-bottom: 0;
        }
    }
}