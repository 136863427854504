.manualCard {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);

    &._hidden {
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &._executorTags {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            top: -12px;
            left: -12px;
            z-index: -1;
            width: 100%;
            height: 100%;
            content: "";
        }
    }

    &._tariff {
        border: 1px solid $colorMain;
    }

    &._tariffParams {
        background: none;
        border-radius: 0;
        box-shadow: none;
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 10px 26px;
        border-bottom: 1px solid $colorBlue;

        &._notBorder {
            border-bottom: 0;
        }

        &Title {
            position: relative;
            align-items: center;

            // width: 100%;
            max-width: 100%;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            white-space: nowrap;
            transition: $trans;

            &Icon {
                // width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        &._hide:not(._show) &Title {
            opacity: 0;
        }

        &Description {
            width: auto;
            max-width: 100%;
            height: 19px;
            color: $colorDark2;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.2;
            white-space: nowrap;
            transition: $trans;

            &._parent {
                max-width: 100%;
                margin-left: 4px;
            }

            &._child {
                // max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &._successColor {
                color: $colorSuccess;
                font-weight: 600;
            }

            &._mediumColor {
                color: $colorMedium;
                font-weight: 600;
            }

            &._errorColor {
                color: $colorError;
                font-weight: 600;
            }
        }

        &._hide:not(._show) &Description {
            opacity: 0;
        }

        &Best {
            position: absolute;
            top: 50%;
            left: 100%;
            width: 20px;
            height: 20px;
            margin-left: 4px;
            transform: translate(0, -50%);
            transition: $trans;

            &:not(._show) {
                transform: translate(0, -50%) scale(1.2);
                opacity: 0;
            }
        }

        &Info {
            margin-left: auto;
        }

        &Arrows {
            align-items: center;
        }

        &Arrow {
            width: 32px;
            height: 32px;

            & + & {
                margin-left: 8px;
            }
        }

        &Actions {
            position: relative;
            height: 27px;
            transition: $trans;

            &._static {
                @extend %row;

                align-items: center;
                justify-content: flex-end;
            }
        }

        &Action {
            position: absolute;
            top: 0;
            right: 0;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Actions._static &Action {
            position: relative;

            &._show {
                margin-left: 6px;
            }

            &:not(._show) {
                display: none;
            }
        }

        &Link {
            position: relative;
            z-index: 1;
            align-items: center;
            height: 100%;
            padding: 6px 12px 7px;
            overflow: hidden;
            color: $colorMain;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            border: 1px solid $colorMain;
            border-radius: 8px;
            transition: $trans;

            @include button;

            &._main {
                color: #fff;
                font-weight: 600;
                background: $colorMain;
                box-shadow: 0 2px 4px rgba($colorMain, 0.56);
            }

            &._blue {
                background: #f1f5ff;
                border-color: #f1f5ff;
            }

            &._alert {
                color: $colorAlert;
                border-color: $colorAlert;
            }

            &Icon {
                width: 15px;
                height: 14px;
                margin-left: 5px;
                transform: translate(0, 1px);

                @include icon($colorMain);

                &._best {
                    transform: scale(1.2);
                }
            }

            &._alert &Icon {
                @include icon($colorAlert);
            }

            &Loader {
                background: #fff;

                &Item {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &Status {
            align-items: center;
            margin-left: 7px;
            padding: 4px 8px 5px;
            color: #fff;
            font-weight: 600;
            font-size: 13.5px;
            background: $colorMedium;
            border-radius: 7px;

            &._wait {
                background: $colorGreyDark;
            }

            &._alert {
                background: $colorAlert;
            }

            &._active {
                background: $colorSuccess;
            }

            &Info {
                width: 12px;
                height: 12px;
                margin-left: 4px;
                transform: translate(0, 1px);

                @include icon(rgba(#fff, 0.32));
            }
        }

        &Copy {
            align-items: center;
            margin-left: 10px;

            &::before {
                margin-right: 10px;
                color: rgba($colorGreyDark, 0.32);
                content: "|";
            }

            &Inner {
                display: block;
                max-width: 400px;
                overflow: hidden;
                font-weight: 300;
                line-height: 1.3;
                text-overflow: ellipsis;
            }

            &Icon {
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                margin-left: 6px;
                background: $colorBlueBack;
                border-radius: 6px;
                transition: $trans;

                @include button(0.94);

                &Inner {
                    width: 12px;
                    height: 12px;

                    @include icon($colorMain);
                }
            }
        }
    }

    &._isEdit &__headArrow .arrowBtn {
        @include forType("crm", "light") {
            background: $colorBlueActive;
            border-color: $colorBlueActive;
        }
    }

    &._tariffConfig &__head {
        position: relative;
        z-index: 2;
    }

    &._tariffConfig &__content {
        z-index: 1;
        min-height: 148px;
        padding: 0;
        overflow: hidden;
        border-radius: 0 0 14px 14px;
        transition: $trans;
    }

    &._tariffParams &__content {
        height: auto;
        min-height: auto;
        padding: 0;
        border-radius: 0;
    }

    &._organizationComments &__content._fix {
        height: 128px;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: calc(100% - 60px);
        padding: 24px 26px;

        &._full {
            padding: 0;
        }

        &._fullHeight {
            height: 100%;
        }

        &._fix {
            height: 162px;

            // height: 80px;
        }

        &Empty {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-bottom: 8px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &List {
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &__main {
        width: 100%;

        &Item {
            &._preview {
                width: 220px;
                height: 90px;
            }

            &._fields {
                width: calc(100% - 220px);
                padding-left: 44px;
            }
        }
    }

    &._blogMain &__main {
        &Item {
            &._preview {
                width: 240px;
                height: 90px;
                padding-left: 20px;
            }

            &._fields {
                width: calc(100% - 240px);
                padding-left: 0;
            }
        }
    }

    &._blogMain._legal &__main {
        &Item {
            &._fields {
                width: 100%;
            }
        }
    }

    &__params {
        width: 100%;

        &Item {
            &._preview {
                width: 286px;
            }

            &._fields {
                width: calc(100% - 286px);
                padding-left: 44px;
            }
        }
    }

    &._tariffParams._car &__params {
        &Item {
            &._preview {
                width: 186px;
            }

            &._fields {
                width: calc(100% - 186px);
                padding-left: 24px;
            }
        }
    }

    &._tariffParams._worker &__params,
    &._tariffParams._courier &__params {
        &Item {
            &._preview {
                width: 90px;
            }

            &._fields {
                width: calc(100% - 90px);
                padding-left: 24px;
            }
        }
    }

    &__comments {
        width: 100%;
        height: 100%;
    }

    &__fields {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: -10px;
    }

    &._executorMain &__fields {
        max-width: 638px;
    }

    &._executorPassport &__fields {
        max-width: 902px;
    }

    &._executorDriverPassport &__fields {
        max-width: 390px;
    }

    &__services {
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            position: relative;
            width: 100%;

            &._static {
                @extend %row;

                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: -8px;
            }
        }
    }

    &__service {
        position: absolute;
        top: 0;
        left: 0;
        width: 47%;
        height: 24px;
        transition: $trans;

        &Inner {
            align-items: center;
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }

        &Support {
            color: $colorDark;
            font-weight: 500;
            font-size: 15px;
        }

        &Content {
            position: relative;
            margin-left: auto;
        }

        &Value {
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 24px;
            padding: 4px 8px;
            color: $colorDark;
            font-weight: 500;
            font-size: 14px;
            background: $colorBlueBack;
            border-radius: 6px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &._text._active &Value {
            transform: translate(-24px, 0);

            & .field {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }

        &._text &Value {
            padding: 0;
            background: none;
        }

        &Select {
            width: 96px;
            height: 24px;
        }

        &Action {
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 2;
            width: 26px;
            height: 26px;
            transform: translate(0, -50%);
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &__servicesInner._static &__service {
        position: relative;

        &._show {
            margin-bottom: 8px;
        }

        &:not(._show) {
            display: none;
        }
    }

    &__files {
        width: 100%;
        height: 100%;

        &Box {
            @extend %noscroll;

            width: calc(100% + 44px);
            margin-bottom: 10px;
            margin-left: -22px;
            overflow: hidden;
            overflow-x: auto;
        }

        &Inner {
            display: inline-flex;
            width: auto;
            padding: 0 22px;
        }

        &Select {
            align-items: center;

            &Support {
                margin-right: 6px;
                color: $colorDark2;
                font-weight: 500;
                font-size: 14px;
            }

            &Field {
                width: 136px;
                height: 24px;
            }
        }
    }

    &__file {
        width: 112px;
        height: 112px;

        & + & {
            margin-left: 10px;
        }
    }

    &__field {
        margin-bottom: 10px;
    }

    &._executorMain &__field {
        $marginExecutorMain: calc(calc(100% - 226px - 200px - 108px) / 2);

        &._type,
        &._inn,
        &._town {
            width: 226px;
            margin-right: $marginExecutorMain;
        }

        &._phone,
        &._payProjectId,
        &._telegram {
            width: 200px;
            margin-right: $marginExecutorMain;
        }

        &._organization,
        &._rate,
        &._inactive {
            width: 108px;
        }
    }

    &._executorPassport &__field {
        $marginExecutorPassport: calc(calc(100% - 136px - 198px - 460px) / 2);

        &._country,
        &._view,
        &._series,
        &._number {
            width: 136px;
            margin-right: $marginExecutorPassport;
        }

        &._dateGet,
        &._dateAction,
        &._code,
        &._birthday {
            width: 198px;
            margin-right: $marginExecutorPassport;
        }

        &._nameGet,
        &._addressReg,
        &._addressFact {
            width: 460px;
        }
    }

    &._executorDriverPassport &__field {
        $marginExecutorPassport: calc(100% - 136px - 198px - 2px);

        &._country,
        &._view,
        &._series,
        &._number {
            width: 136px;
            margin-right: $marginExecutorPassport;
        }

        &._dateGet,
        &._dateAction,
        &._dateExp,
        &._categories {
            width: 198px;
        }
    }

    &._carMain &__field {
        $marginExecutorMain: calc(calc(100% - 140px - 190px - 190px) / 2);

        &._type,
        &._status,
        &._sts {
            width: 140px;
            margin-right: $marginExecutorMain;
        }

        &._vin,
        &._color,
        &._year {
            width: 190px;
            margin-right: $marginExecutorMain;
        }

        &._perm-mass,
        &._full-mass,
        &._diff-mass {
            width: 190px;
        }
    }

    &._carParams &__field {
        $marginExecutorMain: calc(calc(100% - 128px - 184px - 140px) / 2);

        &._long,
        &._width,
        &._height {
            width: 128px;
            margin-right: $marginExecutorMain;
        }

        &._heightWithFloor,
        &._heightCargo,
        &._loadings {
            width: 184px;
            margin-right: $marginExecutorMain;
        }

        &._bodywork,
        &._passengers {
            width: 140px;
        }
    }

    &._tariffParams._car &__field {
        $marginTariffMain: calc(calc(100% - 128px - 160px - 140px - 184px) / 3);

        &._long,
        &._width,
        &._height {
            width: 128px;
            margin-right: $marginTariffMain;
        }

        &._minPrice,
        &._minTime,
        &._freeWaiting {
            width: 160px;
            margin-right: $marginTariffMain;
        }

        &._overThanMin,
        &._priceOfMinute,
        &._passengers {
            width: 140px;
            margin-right: $marginTariffMain;
        }

        &._distance1OfMkad,
        &._distance2OfMkad,
        &._priceOfMkad {
            width: 184px;
        }
    }

    &._tariffParams._worker &__field,
    &._tariffParams._courier &__field {
        $marginTariffWorker: calc(calc(100% - 204px - 180px - 160px - 140px) / 3);

        &._long,
        &._width,
        &._height {
            width: 204px;
            margin-right: $marginTariffWorker;
        }

        &._priceTo20,
        &._priceTo50,
        &._priceTo100 {
            width: 180px;
            margin-right: $marginTariffWorker;
        }

        &._minPrice,
        &._minTime,
        &._freeWaiting {
            width: 160px;
            margin-right: $marginTariffWorker;
        }

        &._overThanMin,
        &._priceOfMinute {
            width: 140px;
        }
    }

    &._organizationMain._physical &__field {
        &._secondName,
        &._type,
        &._viewType {
            width: 220px;
            margin-right: 23px;
        }

        &._firstName,
        &._email {
            width: 200px;
            margin-right: 23px;
        }

        &._thirdName {
            width: 240px;
            margin-right: calc(100% - 220px - 200px - 240px - 23px * 2);
        }

        &._phone {
            width: 190px;
            margin-right: 23px;
        }

        &._inn {
            width: 220px;
        }
    }

    &._organizationMain._juristic &__field {
        $marginOrganizationMainJur: calc(calc(100% - 200px - 170px - 150px * 2 - 72px) / 4);

        &._shortName {
            width: 200px;
            margin-right: $marginOrganizationMainJur;
        }

        &._website {
            width: 170px;
            margin-right: $marginOrganizationMainJur;
        }

        &._inn,
        &._kpp {
            width: 150px;
            margin-right: $marginOrganizationMainJur;
        }

        &._nds {
            width: 72px;
        }

        &._type {
            width: 200px;
        }

        &._address {
            width: calc(100% - 200px - 39px);
            margin-left: auto;
        }
    }

    &._organizationMain._juristic._preview &__field._address {
        width: 100%;
    }

    &._organizationPay:not(._executor) &__field {
        $marginOrganizationPay: calc(calc(100% - 200px - 228px - 228px - 130px) / 3);

        &._bic {
            width: 130px;
            margin-right: $marginOrganizationPay;
        }

        &._bankName {
            width: 200px;
            margin-right: $marginOrganizationPay;
        }

        &._corrAccount {
            width: 228px;
            margin-right: $marginOrganizationPay;
        }

        &._bankAccount {
            width: 228px;
        }
    }

    &._organizationAddressMail &__field {
        width: 100%;
    }

    &._organizationInfo &__field {
        $marginOrganizationMainInfo: calc(calc(100% - 300px - 200px) / 1);

        &._bic {
            width: 200px;
            margin-right: $marginOrganizationMainInfo;
        }

        &._bankAccount {
            width: 300px;
        }

        &._addressatName {
            width: 100%;
        }

        &._bankName {
            width: 100%;
        }
    }

    &._organizationInfo._preview &__field {
        &._bic {
            width: 200px;
            margin-right: 32px;
        }

        &._bankAccount {
            width: calc(100% - 200px - 32px);
        }

        &._addressatName {
            width: 100%;
        }

        &._bankName {
            width: 100%;
        }
    }

    &._organizationPay._executor &__field {
        $marginOrganizationPayExecutor: calc(calc(100% - 460px - 230px - 170px) / 2);

        &._bankName {
            width: 460px;
            margin-right: $marginOrganizationPayExecutor;
        }

        &._bankAccount {
            width: 230px;
            margin-right: $marginOrganizationPayExecutor;
        }

        &._bic {
            width: 170px;
        }
    }

    &._infoMain &__field {
        &._section {
            width: 240px;
            margin-right: 23px;
        }

        &._viewType {
            width: 220px;
        }

        &._title {
            width: 100%;
        }
    }

    &._blogMain &__field {
        &._title {
            width: 100%;
        }

        &._metaTitle {
            width: 240px;
            margin-right: 23px;
        }

        &._metaDescription {
            width: calc(100% - 240px - 23px);
        }

        &._place {
            width: 190px;
            margin-right: 23px;
        }

        &._section {
            width: 215px;
            margin-right: 23px;
        }

        &._viewType {
            width: calc(100% - 190px - 215px - 23px * 2);
        }

        &._smiLink {
            width: 100%;
        }
    }

    &._blogMain._legal &__field {
        &._title {
            width: 100%;
        }

        &._viewType {
            width: 220px;
        }
    }

    &._profile &__field {
        &._secondName {
            width: 220px;
            margin-right: 23px;
        }

        &._firstName {
            width: 200px;
            margin-right: 23px;
        }

        &._thirdName {
            width: 240px;
            margin-right: calc(100% - 220px - 200px - 240px - 23px * 2);
        }

        &._email {
            width: 220px;
            margin-right: 23px;
        }

        &._phone {
            width: 200px;
            margin-right: 23px;
        }

        &._password {
            width: 200px;
        }
    }

    &._tagMain &__field {
        $marginTagMain: calc(calc(100% - 220px - 200px - 240px - 170px) / 3);

        &._name {
            width: 220px;
            margin-right: $marginTagMain;
        }

        &._idOfGroup {
            width: 200px;
            margin-right: $marginTagMain;
        }

        &._newGroup {
            width: 240px;
            margin-right: $marginTagMain;
        }

        &._color {
            width: 170px;
        }
    }

    &._modelOfCarMain &__field {
        &._name {
            width: 220px;
            margin-right: 32px;
        }

        &._idOfMark {
            width: 200px;
            margin-right: 32px;
        }

        &._newMark {
            width: 240px;
        }
    }

    &._tariff &__field {
        &._category {
            width: 200px;
            margin-right: 20px;
        }

        &._typeOfHolder {
            width: 230px;
            margin-right: 20px;
        }

        &._name {
            width: calc(100% - 230px - 200px - 40px);
        }

        &._type {
            width: 180px;
            margin-right: 20px;
        }

        &._nameOfHolder {
            width: 350px;
            margin-right: 20px;
        }

        &._nds {
            width: 74px;
        }
    }

    &._joinDocsMain &__field {
        &._name {
            width: 220px;
            margin-right: 16px;
        }

        &._filesCounter {
            width: 200px;
        }
    }

    &__tags {
        width: 100%;

        &._static {
            @extend %row;

            flex-wrap: wrap;
            width: calc(100% + 4px);
        }
    }

    &__tag {
        position: absolute;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__tags._static &__tag {
        position: relative;
        margin: 0 4px 4px 0;

        &:not(._show) {
            display: none;
        }
    }

    &__contentInfo {
        width: 100%;

        &Block {
            align-items: stretch;
            height: 58px;

            &:not(._new) {
                width: calc(100% - 360px);
                padding-right: 20px;
            }

            &._new {
                width: 360px;
                height: 74px;
                margin: -8px 0;
                padding: 12px;
                background: $colorBlueBack;
                border-radius: 16px;
                transition: $trans;

                &._edit {
                    background: #f7f9ff;
                }

                &._error {
                    background: rgba($colorAlert, 0.075);
                }
            }
        }

        &File {
            width: 100px;

            // height: 100%;
        }

        &Fields {
            flex-wrap: wrap;
            justify-content: space-between;
            width: calc(100% - 100px);
            padding-left: 20px;
        }

        &Field {
            width: 100%;

            &._section {
                width: 180px;
                margin-bottom: 6px;
            }

            &._viewType {
                width: 180px;
                margin-bottom: 6px;
            }
        }
    }
}
