.scrollDrag {
    padding: 3px;
    background: #d5d5d5;
    border-radius: 12px;
    transition: $trans;

    &._hide {
        opacity: 0;
        pointer-events: none;
    }

    &__inner {
        position: relative;
    }

    &__btn {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 50px;
        height: 100%;
        background: rgba(#000, 0.24);
        border-radius: 10px;
    }

    &__items {
        padding: 4px;
    }

    &__item {
        width: 15px;
        height: 22px;
        background: rgba(#fff, 0.56);
        border-radius: 5px;

        & + & {
            margin-left: 4px;
        }
    }
}
