.auth {
    @extend %section;

    position: relative;
    z-index: 1;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;

    &::before {
        position: absolute;
        top: 50%;
        right: -13rem;
        z-index: -1;
        width: 137rem;
        height: calc(100% + 43rem);
        margin-top: 0;
        transform: translate(0, -50%);
        content: "";

        @include bg("../img/crm/auth/back.png", auto 100%, center left);
    }

    &__success {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        transition: $trans;

        @include blur(2.5rem);

        &:not(._show) {
            opacity: 0;
        }

        &Box {
            position: relative;
            z-index: 1;
            width: 45rem;
            height: 45rem;
            margin: auto;
            overflow: hidden;
            background: #fff;
            border-radius: 3rem;
            box-shadow: 0 1.5rem 7rem rgb(28 31 38 / 8%);

            ._design-newYear {
                background: none;
            }

            ._design-newYear &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                content: "";

                @include bg("/img/crm/newYear/auth-back.svg", cover, center);
            }
        }
    }

    &__box {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }

    &__inner {
        position: relative;
        width: 100%;
        min-height: 100%;
        padding: 8rem 12rem;
    }

    &__block {
        min-height: calc(100vh - 16rem);

        &._info {
            width: calc(100% - 50rem);
            padding-right: 13rem;
        }

        &._content {
            position: relative;
            z-index: 1;
            width: 50rem;
            margin-left: auto;
        }
    }

    &__logo {
        width: 11rem;
        margin-bottom: 3.5rem;

        ._design-newYear & {
            width: 26rem;
            margin-bottom: 2.5rem;
        }
    }

    &__mockups {
        position: relative;
        width: 100%;
        margin: auto 0;

        &Items {
            position: relative;
            width: 100%;
            perspective: 100rem;
            transition: $trans;
        }

        &Nav {
            position: absolute;
            right: 2.5rem;
            bottom: 54rem;
            z-index: 2;
            align-items: center;
            padding: 1.5rem;
            background: rgba(#fff, 0.4);
            border-radius: 1.5rem;

            @include blur(4rem);

            &Btn {
                width: 2.5rem;
                height: 2.5rem;
                background: #fff;
                border-radius: 0.8rem;
                transition: $trans;

                @include button(0.95);

                &Icon {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: auto;

                    @include icon($colorMain);
                }
            }

            &Items {
                align-items: center;
                margin: 0 1rem;
            }

            &Item {
                width: 0.7rem;
                height: 0.7rem;
                margin: 0 0.4rem;
                background: $colorMain;
                border-radius: 0.3rem;
                transition: $trans;

                &:not(._current) {
                    opacity: 0.32;
                }
            }
        }
    }

    &__mockup {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-25rem, 0) scale(0.95) rotateY(-15deg);
            }

            &._next {
                transform: translate(25rem, 0) scale(0.95) rotateY(15deg);
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 1rem;
            color: $colorDark2;
            font-weight: 700;
            font-size: 3.35rem;
            line-height: 1.35;
        }

        &Description {
            max-width: 100%;
            margin-bottom: 2.5rem;
            color: $colorDark2;
            font-size: 2.25rem;
            line-height: 1.3;
        }

        &Box {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 52rem;
            overflow: hidden;
            background: $colorBlueBack;
            border: 0.2rem solid #fff;
            border-radius: 2.6rem;
            box-shadow: 0 1.5rem 6rem rgb(116 129 148 / 20%);
        }

        &Image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__mockupsItems._static &__mockup {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__nav {
        align-items: center;
        width: 100%;
        margin-top: 6rem;

        &Item {
            & + & {
                margin-left: 5rem;
            }
        }

        &Link {
            color: $colorDark2;
            font-size: 1.7rem;
            transition: $trans;

            &:hover {
                color: $colorMain;
            }
        }
    }

    &__forms {
        position: relative;
        width: 44rem;
        margin: auto;
    }

    &__form {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, -50%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            pointer-events: none;
        }

        &._prev:not(._show) {
            transform: translate(-64px, -50%);
        }

        &._next:not(._show) {
            transform: translate(64px, -50%);
        }
    }

    &__copy {
        position: absolute;
        right: 3rem;
        bottom: 0;
        z-index: 2;
        color: #3e454c;
        font-weight: 300;
        font-size: 1.4rem;
    }
}
