.executorOrg {
    width: 100%;
    height: 100%;
    padding: 3px 4px;
    border-radius: 5px;
    text-transform: uppercase;
    color: #fff;
    font-size: 11px;
    font-weight: 600;

    &._FL {
        background: #AE27FF;
    }

    &._SMZ {
        background: #00CBF3;
    }

    &._IP {
        background: #f2b538;
    }

    &._min{
        padding: 1px 3px;
    }

    &._grey{
        background: #EEF1F6!important;
        font-weight: 500;
        color: $colorDark2;
    }
}