.manualCardListBodyworks {
    @extend %shadow;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 14px;
    position: relative;
    z-index: 1;

    &__inner {
        @extend %noscroll;
        width: 100%;
        height: 240px;
        overflow-y: auto;
        padding: 12px;
    }

    &__items {
        flex-wrap: wrap;
        margin-bottom: -6px;
        width: calc(100% + 6px);
        margin-left: -3px;
        position: relative;
    }

    &__item {
        width: calc(100% / 3);
        padding: 0 3px;
        transition: $trans;
        margin-bottom: 6px;

        &Inner {
            width: 100%;
            height: 50px;
            border-radius: 12px;
            background: $colorBlueBack;
            justify-content: center;
            align-items: center;
            transition: $trans;
            @include button(.95);
            position: relative;
            z-index: 1;
            overflow: hidden;
        }

        &Done {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            justify-content: center;
            align-items: center;
            z-index: 2;
            background: rgba($colorMain, .5);
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            &Icon {
                width: 24px;
                height: 24px;
                @include icon(#fff);
            }
        }

        &Icon {
            width: 44px;
            height: 44px;
            object-fit: contain;
        }
    }
}