.dealLog {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 10px 14px;
    background: rgba(#fff, 0.56);
    border: 1px solid transparent;
    border-radius: 18px;
    transition: $trans;

    &._alert {
        border-color: rgba($colorAlert, 0.56);
    }

    &._new {
        background: #fff;
    }

    &__inner {
        position: relative;
        width: 100%;
        padding-left: 42px;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background: #fff;
        border-radius: 10px;
        transition: $trans;

        @include icon($colorDark2);
    }

    &._alert &__icon {
        @include icon($colorAlert);
    }

    &._new &__icon {
        background: rgba($colorMain, 0.1);

        @include icon($colorMain);
    }

    &__logo {
        position: absolute;
        top: -1px;
        right: -1px;
        z-index: 2;
        width: 12px;
        height: 12px;
        object-fit: contain;
    }

    &__block {
        padding-top: 5px;

        &._actions {
            align-items: flex-end;
            margin-left: auto;
            padding-right: 4px;
        }
    }

    &._task &__block {
        width: calc(100% - 100px);

        &._actions {
            width: 100px;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
        min-height: 21px;
    }

    &__title {
        margin-right: 6px;
        color: $colorDark2;
        font-size: 15px;

        & b {
            font-weight: 600;
            transition: $trans;
        }

        &._big {
            font-size: 22px;
        }
    }

    &._alert &__title b {
        color: $colorAlert;
    }

    &._alert &__title._link {
        border-bottom: 1px dashed $colorAlert;
    }

    &:not(._alert) &__title._link {
        color: $colorMain;
        border-bottom: 1px dashed $colorMain;
    }

    &__status {
        padding: 4px 6px;
        color: #fff;
        font-weight: 600;
        font-size: 13px;
        background: $colorGreyDark;
        border-radius: 6px;
    }

    &._cuperCandi &__status {
        &._partner {
            background: #f2ba38;
        }

        &._deduplicate {
            background: #393c43;
        }

        &._create {
            background: $colorSuccess;
        }

        &._candidate {
            background: #98d400;
        }

        &._error {
            background: $colorAlert;
        }
    }

    &._sms &__status {
        &._completed {
            background: $colorSuccess;
        }

        &._error {
            background: $colorAlert;
        }
    }

    &__head &__status {
        margin-right: 10px;
    }

    &__detail {
        padding: 2px 7px;
        color: $colorDark2;
        font-size: 13px;
        line-height: 1.2;
        background: #edf2f8;
        border-radius: 6px;

        & + & {
            margin-left: 6px;
        }

        & b {
            font-weight: 600;
        }
    }

    &__description {
        margin-top: 4px;
        color: $colorDark2;
        font-size: 14px;
        line-height: 1.3;

        & span {
            color: $colorGreyDark;

            &._completed {
                color: $colorSuccess;
                font-weight: 600;
            }

            &._reject {
                color: $colorAlert;
                font-weight: 600;
            }
        }

        & b {
            padding: 0 4px;
            font-weight: 500;
            background: #edf2f8;
            border-radius: 4px;
        }
    }

    &__date {
        padding-top: 4px;
        color: $colorGreyDark;
        font-size: 13px;

        &._abs {
            position: absolute;
            top: 0;
            right: 0;
            padding: 8px 5px 0 0;
        }
    }

    &__entityStatus {
        padding: 4px 7px;
        color: $colorMain;
        font-weight: 700;
        font-size: 11px;
        white-space: nowrap;
        text-transform: uppercase;
        background: rgba($colorMain, 0.1);
        border-radius: 6px;
        transition: $trans;

        &._alert {
            color: $colorAlert;
            background: rgba($colorAlert, 0.14);
        }

        &._completed {
            color: $colorSuccess;
            background: rgba($colorSuccess, 0.14);
        }

        &._process {
            color: #ff942d;
            background: rgba(#ff942d, 0.14);
        }

        &._join-sms,
        &._join-status {
            color: #f29138;
            background: rgba(#f29138, 0.14);
        }

        &._join-invite {
            color: #c2c2c2;
            background: rgba(#c2c2c2, 0.14);
        }

        &._join-anket,
        &._join-docs {
            color: #ffb400;
            background: rgba(#ffb400, 0.14);
        }

        &._join-card,
        &._join-check {
            color: #98d400;
            background: rgba(#98d400, 0.14);
        }

        &._join-reject {
            color: #fb927d;
            background: rgba(#fb927d, 0.14);
        }

        &._join-complete {
            color: $colorSuccess;
            background: rgba($colorSuccess, 0.14);
        }

        &._join-inactive {
            color: $colorAlert;
            background: rgba($colorAlert, 0.14);
        }

        &Wrapper {
            height: 19px;
            margin-top: 7px;
        }
    }

    &__callActions {
        align-items: center;
        margin-top: 12px;
    }

    &__callFile {
        & .chatFile {
            padding: 0;
            background: none !important;
        }

        & .chatFile__audio {
            padding: 4px 0 4px 18px;
        }

        & .chatFile__audioButton {
            width: 14px;
            height: 14px;
        }

        & .chatFile__audioButtonIcon {
            width: 5px;
            height: 5px;

            &._play {
                margin-left: 0.5px;
            }
        }

        & .chatFile__audioProgressDuration {
            display: none;
        }

        & .chatFile__audioProgress {
            width: 60px;
            min-width: auto;
            padding-right: 0;

            // display: none;
        }

        & .chatFile__audioProgressLine {
            background: #edf2f8;
        }

        & .chatFile__audioProgressLineActiveDot {
            width: 5px;
            height: 5px;
        }
    }

    &__callDownload {
        width: 14px;
        height: 14px;
        margin-left: 7px;
    }

    &__buttons {
        height: auto;

        &:not(._empty) {
            margin-top: 8px;
        }

        & .list__itemBox{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}
