.contentInfoPublic {
    width: 100%;

    &__inner {
        align-items: center;
        padding: 26px 0 0 0;
    }

    &__head {
        align-items: center;
        max-width: 60%;
        margin-bottom: 26px;
    }

    &__logo {
        width: 126px;
        margin-bottom: 15px;
    }

    &__title {
        max-width: 100%;
        font-size: 25px;
        color: $colorDark2;
        font-weight: 600;
        margin-bottom: 14px;
    }

    &__description {
        max-width: 55%;
        font-size: 16.5px;
        font-weight: 300;
        line-height: 1.5;
        color: $colorDark2;
        text-align: center;
    }

    &__content {
        width: 100%;
    }

    &__cards {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__cards._static &__card {
        position: relative;

        &:not(._show) {
            display: none;
        }

        &:not(._last) {
            margin-bottom: 12px;
        }
    }

    &__card {
        width: 100%;
        background: #fff;
        border-radius: 24px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
        padding: 42px 74px;
        min-height: 290px;
        z-index: 1;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;
        will-change: transform;

        &._empty {
            padding: 0;
            box-shadow: none;
        }

        &:not(._show) {
            opacity: 0;
        }

        &Image {
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right bottom;

            &._parent {
                width: 50%;
                position: absolute;
                bottom: 0;
                right: 50px;
                height: calc(100% + 30px);
            }
        }

        &._odd &Image._parent {
            right: auto;
            left: 50px;
        }

        &._odd &Image {
            object-position: left bottom;
        }

        &Inner {
            width: 45%;
            position: relative;
            z-index: 3;
        }

        &Counter {
            position: absolute;
            top: -5px;
            left: -40px;
            z-index: 2;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background: $colorMain;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            color: #fff;
            font-weight: 600;
        }

        &._odd &Inner {
            margin-left: auto;
        }

        &Title {
            max-width: 100%;
            font-size: 16.5px;
            font-weight: 600;
            color: $colorDark2;
            margin-bottom: 22px;

            & i {
                font-weight: 600;
            }

            & span {
                color: $colorMain;
                font-weight: 900;
                border-bottom: 1px solid $colorMain;
            }
        }

        &List {
            width: 100%;
            position: relative;

            &._static &Item {
                position: relative;

                &:not(._show) {
                    display: none;
                }

                &:not(._last) {
                    margin-bottom: 14px;
                }
            }

            &Item {
                max-width: 100%;
                padding-left: 32px;
                position: absolute;
                top: 0;
                left: 0;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }

                &Icon {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    top: 1px;
                    left: 0;
                }

                &Link {
                    font-size: 16px;
                    line-height: 1.3;
                    font-weight: 300;
                    color: $colorDark2;
                    border-bottom: 1px solid rgba(#9DA8B4, .32);

                    & i {
                        font-weight: 300;
                    }

                    & span {
                        color: $colorMain;
                        font-weight: 600;
                        border-bottom: 1px solid $colorMain;
                    }
                }

                &Arrow {
                    position: absolute;
                    left: 100%;
                    bottom: 2px;
                    margin-left: 7px;
                    width: 16px;
                    height: 16px;
                    @include icon($colorMain);
                }
            }
        }
    }

    &__article {
        width: 100%;
        padding: 24px 24px 46px 24px;

        &Section {
            color: $colorMain;
            font-size: 13px;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 14px;
        }

        &Title {
            max-width: 50%;
            font-size: 38px;
            line-height: 1.3;
            font-weight: 600;
            color: $colorDark2;
            margin-bottom: 16px;
        }

        &Inner {
            @extend %editorContent;
            width: 100%;

            &,
            & * {
                font-size: 17px;
                line-height: 1.5;
            }

            & img {
                border-radius: 16px;
                border: 1px solid $colorBlue;
                position: relative;
                z-index: 1;
                overflow: hidden;
            }

            & br+br {
                content: "";
                display: block;
                margin-top: 12px;
            }
        }
    }
}