.v2widgetJoinScript {
    width: 100%;
    height: 100%;
    padding: 24px;

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__block {
        position: relative;
        z-index: 1;
        height: 100%;

        &Inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: #fff;
            border-radius: 18px;
            box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);
        }

        &._main {
            width: calc(100% - 310px - 16px);
        }

        &._docs {
            width: 310px;
            margin-left: auto;
        }

        &._docs &Inner {
            height: calc(100% - 100px);
            transition: $trans;
        }

        &Head {
            width: 100%;
            height: 80px;
            padding: 20px 22px;
            border-bottom: 1px solid $colorBlue;

            &Title {
                max-width: 100%;
                margin-bottom: 5px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.2;
            }

            &Description {
                width: 100%;
                color: $colorGreyDark;
                font-size: 14px;
                line-height: 1.3;
                white-space: nowrap;
                transition: $trans;

                &._active {
                    color: $colorDark2;
                }

                &._child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &Content {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            overflow: hidden;
            overflow-y: auto;

            &Inner {
                width: 100%;
                padding: 20px 22px;
            }
        }
    }

    &__docs {
        width: 100%;

        &Item {
            z-index: 1;
            width: 100%;
            padding: 0 20px;
            overflow: hidden;
            transition: $trans;

            &._current {
                background: #f3f7ff;
            }
        }

        &._static &Item:not(._last) {
            margin-bottom: 2px;
        }
    }

    &__docsItem:not(._last, ._current) &__doc {
        border-color: $colorBlue;
    }

    &__doc {
        position: relative;
        width: 100%;
        padding: 18px 0;
        border-bottom: 1px solid transparent;
        transition: $trans;

        &Title {
            max-width: 100%;
            margin-bottom: 6px;
            padding-right: 38px;
            color: $colorDark2;
            font-size: 14px;
            line-height: 1.3;
        }

        &Info {
            position: relative;
            align-items: center;
        }

        &Files {
            color: $colorGreyDark;
            font-size: 14px;
        }

        &Required {
            align-items: center;
            height: 12px;
            padding-left: 10px;
        }

        &Btn {
            top: 16px;
            right: 0;
        }

        &Options {
            width: 100%;

            &._parent:not(._empty) {
                margin-top: 11px;
            }

            &Inner {
                width: 100%;
                padding-top: 8px;
                color: $colorDark2;
                font-size: 14px;
                border-top: 1px solid $colorBlue;

                & span {
                    color: $colorGreyDark;
                    font-size: 14px;
                }
            }
        }
    }

    &__foot {
        width: 100%;
        margin-top: auto;

        &Error {
            width: 100%;

            &._parent:not(._empty) {
                margin-bottom: 7px;
            }

            &Text {
                color: $colorAlert;
                font-weight: 600;
                font-size: 15px;
                line-height: 1.3;
            }
        }

        &Button {
            width: 100%;
        }
    }
}
