.contentBlogInnerCard {
    width: 100%;

    &__inner {
        position: relative;
        width: 100%;
        border-radius: 12px;
        transition: $trans;
    }

    .contentBlogInner__card[data-notTrans] &__inner {
        background: #fbfcfd;
        box-shadow: 0 0 32px 0 rgba(216, 220, 228, .32);
    }

    &__head {
        align-items: center;
        width: 100%;
        height: 34px;
        margin-bottom: 1px;
        padding: 6px 18px;
        background: #fbfcfd;
        border-radius: 12px 12px 0 0;
    }

    &__image &__head,
    &__images &__head {
        border-radius: 0;
    }

    &__images &Content {
        box-shadow: none;
    }

    &__title {
        position: relative;
        margin-right: 6px;
        color: $colorDark;
        font-size: 14px;
        font-weight: 600;
    }

    &._empty &__counter {
        background: #dde3eb;
    }

    &__counter {
        position: relative;
        align-items: center;
        height: 18px;
        padding: 4px 4px 4px 6px;
        border-radius: 360px;
        background: #f25130;
        transition: $trans;

        &._success {
            background: #66ca49;
        }

        &Inner {
            color: #fff;
            font-size: 12.5px;
            font-weight: 500;
        }

        &Info {
            width: 12px;
            height: 12px;
            margin-left: 3px;
        }

        &Icon {
            width: 100%;
            height: 100%;
            @include icon(rgba(#fff, .5));
        }
    }

    &__actions {
        align-items: center;
        margin-left: auto;
        position: relative;
    }

    &__action {
        width: 16px;
        height: 16px;
        transition: $trans;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 0;

        &:not(._show) {
            opacity: 0;
        }

        &+& {
            margin-left: 6px;
        }

        &._delete {
            &:hover {
                @include icon($colorAlert);
            }
        }
    }

    &__actions._withDrag &__action._delete {
        transform: translate(-22px, -50%);
    }

    &__content {
        width: 100%;
        // height: 100px;
        padding: 12px 18px;
        background: #fff;
        border-radius: 0 0 12px 12px;
        box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
        transition: $trans;

        &Inner {
            position: relative;
            width: 100%;
        }

        &Placeholder {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            color: #9da8b4;
            font-size: 15.5px;
            font-weight: 500;
            line-height: 1.4;
            cursor: text;
            transition: $trans;
            pointer-events: none;

            &._hide {
                display: none;
            }
        }
    }

    &._ulList &__contentPlaceholder,
    &._olList &__contentPlaceholder {
        left: 20px;
    }

    &._empty &__content {
        background: #fbfcfd;
    }

    &._image &__content:not(._inner) {
        padding: 0;
        overflow: hidden;
    }

    &._error &__content {
        background: rgba($colorAlert, .05);
        box-shadow: 0 0 2px 0 rgba($colorAlert, 0.2);

        &Placeholder {
            color: $colorAlert;
        }
    }

    &._empty &__text {
        color: #9da8b4;
    }

    &._error &__text {
        color: $colorAlert;
    }

    &__text {
        width: 100%;
        color: $colorDark;
        font-size: 15.5px;
        font-weight: 500;
        line-height: 1.4;

        & b {
            font-weight: 600;
        }

        & a {
            color: $colorMain;
        }

        & ul {
            padding-left: 20px;
        }

        & ol {
            padding-left: 20px;
            counter-reset: counterOl;
        }

        & ol li {
            list-style-type: decimal;
        }

        & ul li {
            list-style-type: disc;
        }

        & li {
            font-size: 15.5px;
            font-weight: 500;
            line-height: 1.4;

            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }
    }

    &__input {
        width: 100%;
        color: $colorDark;
        font-size: 15.5px;
        font-weight: 500;
        line-height: 1.4;
        transition: $trans;

        &::-webkit-input-placeholder {
            color: #9da8b4;
            transition: $trans;
        }

        &._area {
            min-height: 60px;
            resize: none;
        }
    }

    &._error &__input {
        color: $colorAlert;

        &::-webkit-input-placeholder {
            color: $colorAlert;
        }
    }

    &__images {
        justify-content: space-between;
        width: 100%;

        &Item {
            position: relative;
            width: 49.5%;
            overflow: hidden;
            background: $colorGrey;
            border-radius: 12px;
            box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);
            transition: $trans;

            &._error {
                box-shadow: 0 0 2px 0 rgba($colorAlert, 0.2);
            }
        }
    }

    &__images &__image._empty {
        min-height: 260px;
    }

    &__images &__image {
        height: 250px;

        &Preview {
            // object-fit: cover;
        }
    }

    &__imagesItem._empty &Counter {
        background: #dde3eb;
    }

    &__imagesItem._error &Image {
        background: rgba($colorAlert, .05);

        &Icon {
            @include icon($colorAlert);
        }

        &Title,
        &Description {
            color: $colorAlert;
        }
    }

    &__image {
        width: 100%;
        height: 300px;
        border-bottom: 1px solid rgba($colorBlue, .3);
    }

    &__description {
        width: 100%;
    }

    &__description._error &__content._inner {
        background: rgba($colorAlert, .05);
    }

    &__description._error &__input {
        color: $colorAlert;

        &::-webkit-input-placeholder {
            color: $colorAlert;
        }
    }
}