.crmPopup {
    width: 100%;
    height: 100%;

    &__inner {
        width: 530px;
        border-radius: 24px;
        background: #fff;
        margin: auto;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    &._wallets &__inner {
        width: 400px;
    }

    &._mailing &__inner,
    &._chatTemplate &__inner {
        width: 700px;
    }

    &__head {
        width: 100%;
        padding: 24px 64px 24px 36px;
        position: relative;
        border-bottom: 1px solid #E0E4E9;

        &._notBorder {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__title {
        max-width: 100%;
        font-size: 22px;
        line-height: 1.3;
        font-weight: 600;
        color: $colorDark2;

        &._dynamic {
            max-width: none;
            white-space: nowrap;
        }
    }

    &__description {
        max-width: 100%;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 300;
        color: $colorDark2;
        margin-top: 12px;
    }

    &__close {
        position: absolute;
        top: 28px;
        right: 26px;
        width: 22px;
        height: 22px;
        z-index: 2;
        @include icon(#DFE4EC);
        transition: $trans;

        &:hover {
            @include icon($colorMain);
        }
    }

    &__content {
        width: 100%;
        position: relative;

        &._hidden {
            z-index: 1;
            overflow: hidden;
        }

        &._padding {
            padding: 30px 36px;
        }

        &Scroll {
            @extend %noscroll;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
        }

        &:not(._ready) &Scroll {
            opacity: 0;
        }
    }

    &._mailing &__content,
    &._chatTemplate &__content {
        // max-height: calc(100vh - 100px - 76px * 2);

        &Scroll {
            padding: 30px 36px;
            max-height: calc(100vh - 100px - 76px * 2);
        }
    }

    &__field {
        width: 100%;
        position: relative;

        &+& {
            margin-top: 14px;
        }

        &:not(._notBorder)+&:not(._notBorder) {
            border-top: 1px solid #E0E4E9;
            margin-top: 25px;
            padding-top: 25px;
        }

        &Support {
            max-width: 100%;
            font-size: 16px;
            line-height: 1.3;
            font-weight: 600;
            color: $colorDark2;
            margin-bottom: 12px;

            &._upper {
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 6px;
            }
        }

        &._row &Support {
            padding-top: 4px;
            padding-right: 12px;
            margin-bottom: 0;
            white-space: nowrap;
        }

        &Box {
            position: relative;

            &._withAbsoluteBtn {
                min-height: 28px;
                padding-right: 130px;
            }

            &._padding {
                padding-top: 5px;
            }
        }

        &._height &Box {
            height: 54px;
        }

        &._row &Box {
            width: 100%;
        }

        &._col &Box {
            width: 100%;
        }

        &Button {
            height: 30px;
            font-size: 14px;
            border-radius: 8px;
            padding: 7px 14px;
            background: #EDF2FF;
            color: $colorMain;
            transition: $trans;
            @include button(.97);
            white-space: nowrap;
            align-items: center;

            &._absolute {
                position: absolute;
                top: 0;
                left: 0;

                &._active {
                    left: 100%;
                    transform: translate(-100%, 0);
                }
            }

            &Inner {
                font-size: 14px;
            }
        }
    }

    &__checkboxes {
        &._row {
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: -12px;
        }
    }

    &__checkboxes._row &__checkbox {
        margin: 0 24px 12px 0;
    }

    &__checkboxes._col &__checkbox+&__checkbox {
        margin-top: 12px;
    }

    &__checkbox {
        &._full {
            width: 100%;
        }

        &Other {
            width: 100%;
            transition: $trans;
            padding-left: 24px;

            &:not(._empty)._parent {
                margin-top: 6px;
            }

            &Inner {
                width: 100%;
                position: relative;
            }
        }
    }

    &__tags {
        width: 100%;
        position: relative;
        transition: $trans;

        &:not(._empty) {
            margin: 5px 0 -4px 0;
        }

        &._static {
            display: inline-flex;
            flex-wrap: wrap;
        }
    }

    &__tag {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__tags._static &__tag {
        position: relative;
        margin: 0 4px 4px 0;

        &:not(._show) {
            display: none;
        }
    }

    &__switch {
        width: 34px;
        height: 20px;
    }

    &__user {
        &Inner {
            height: 30px;
            align-items: center;
            padding: 5px 9px 5px 32px;
            border-radius: 8px;
            background: $colorBlueBack;
            position: relative;
            font-size: 14px;
            color: $colorDark2;
            white-space: nowrap;
        }

        &Avatar {
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
        }
    }

    &__field._height &__input {
        height: 100%;
    }

    &__input {
        width: 100%;
        position: relative;

        &._area {
            // height: 170px;
        }

        &Emodzi {
            position: absolute;
            top: 12px;
            right: 12px;
            width: 16px;
            height: 16px;
            z-index: 2;

            &Btn {
                transition: $trans;
                @include button(.96);
                @include icon(#9DA8B4);
            }

            &List {
                position: absolute;
                top: 100%;
                right: -21px;
                margin-top: 14px;
                transition: $trans;
                width: 270px;

                &:not(._show) {
                    opacity: 0;
                    transform: translate(0, 8px);
                }
            }
        }

        & .field {
            border-radius: 14px !important;
        }

        & .field__value {
            font-size: 16px !important;
            padding: 12px 36px 12px 16px !important;
        }

        :not(._parentCalc) & .field__input {
            overflow: hidden;
        }
    }

    &__actions {
        align-items: center;

        &:not(._dynamic) {
            margin-top: 10px;
        }

        &._dynamic {
            display: flex;
            align-items: center;
            position: relative;
            transition: $trans;

            &:not(._empty) {
                margin-top: 10px;
            }
        }
    }

    &__action {
        height: 32px;

        &Inner {
            align-items: center;
            @include button(.97);
            height: 100%;
            padding: 5px 12px 5px 10px;
            background: $colorBlueBack;
            color: $colorGreyDark;
            border-radius: 10px;
            font-size: 14px;
            transition: $trans;
        }

        &Icon {
            width: 18px;
            height: 18px;
            margin-right: 7px;
            @include icon($colorGreyDark);
        }

        &._file &Icon {
            transform: scale(1.1);
        }

        & input {
            display: none;
        }
    }

    &__actions:not(._dynamic) &__action {
        margin-right: 10px;
    }

    &__actions._dynamic &__action {
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__actions._dynamic._static &__action {
        position: relative;
        margin-right: 10px;

        &:not(._show) {
            display: none;
        }
    }

    &__files {
        width: 100%;
        position: relative;
        transition: $trans;

        &:not(._empty) {
            margin: 12px 0 -12px 0;
        }

        &._static {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__file {
        width: 150px;
        position: absolute;
        top: 0;
        left: 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__files._static &__file {
        position: relative;
        margin: 0 12px 12px 0;

        &:not(._show) {
            display: none;
        }
    }

    &__foot {
        width: 100%;
        align-items: center;
        padding: 24px 36px;
        border-top: 1px solid #E0E4E9;

        &Description {
            font-size: 16px;
            color: $colorDark2;
            position: relative;
            white-space: nowrap;

            & b {
                font-weight: 600;
            }

            &Count {
                font-size: 16px;
                line-height: 0.7;
                display: inline-block;
            }

            &Loader {
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translate(0, -50%);
                margin-left: 6px;
                width: 20px;
                height: 20px;
                z-index: 2;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }
        }

        &Buttons {
            margin-left: auto;
            transition: $trans;
        }

        &Button {
            position: relative;

            &+& {
                margin-left: 8px;
            }

            &Window {
                position: absolute;
                bottom: 100%;
                right: 0px;
                margin-bottom: 16px;
                transition: $trans;
                z-index: 3;

                &:not(._show) {
                    transform: translate(0, -8px);
                    opacity: 0;
                }
            }

            & .button__icon {
                width: 16px;
                height: 16px;
                margin-left: 6px;
                margin-right: -6px;
            }
        }
    }

    &:not(._ready) &__footButtons {
        opacity: 0;
    }

    &__tabs {
        width: calc(100% + 72px);
        margin-left: -36px;
        padding: 0 36px;
        margin-bottom: 20px;
        border-bottom: 1px solid #E0E4E9;
    }

    &__tab {
        display: block;

        &+& {
            margin-left: 26px;
        }

        &Input {
            display: none;
        }

        &View {
            font-size: 16px;
            color: #75808B;
            padding-bottom: 20px;
            transition: $trans;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                height: 1px;
                border-radius: 10px 10px 0 0;
                background: $colorMain;
                z-index: 2;
                opacity: 0;
                transition: $trans;
            }
        }

        &Input:checked~&View {
            color: $colorMain;
            font-weight: 600;

            &::after {
                opacity: 1;
            }
        }
    }

    &__blocks {
        width: 100%;
        transition: $trans;
        position: relative;
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-40px, 0);
            }

            &._next {
                transform: translate(40px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__foot {
        width: 100%;
        padding: 30px 36px;
        border-top: 1px solid #E0E4E9;

        &Button {
            height: 46px;

            &._full {
                width: 100%;
            }
        }
    }
}