.tag {
    width: 100%;
    height: 100%;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 10px;
    line-height: 1.3;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid transparent;
    background: #fff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &._full {
        font-weight: 900;
    }

    &._edit {
        color: $colorMain;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        border-bottom: 1px dashed $colorMain;
        transition: $trans;
        background: none;
        @include button(.97);
    }
}