.chatPreview {
    width: 100%;
    height: 100%;
    padding: 9px 12px;
    background: #fbfcfd;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: $trans;

    &._mode-popup {
        background: $colorBlueBack;
    }

    &:hover {
        transform: translate(4px, 0);
    }

    @include forType("crm") {
        position: relative;
        align-items: center;
        padding-left: 12px;
        border-radius: 13px;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 2px;
            height: calc(100% - 24px);
            border-radius: 0 6px 6px 0;
            transform: translate(0, -50%);
            transition: $trans;
            content: "";
        }

        &._important::before {
            background: $colorAlert;
        }

        &._proccess::before {
            background: #ffc216;
        }

        &._complete::before {
            background: #5cc962;
        }
    }

    &._isClose:not(._current) {
        opacity: 0.64;

        @include forType("crm") {
            opacity: 1;
        }
    }

    &._current {
        background: #fff;
        border-color: #e1e8fc;
    }

    &._support {
        background: #eaf1ff;
        border-color: #fff;
        border-width: 2px;
    }

    &__preview {
        position: relative;
        z-index: 1;
        width: 58px;
        height: 58px;
        background: $colorBlueBack;
        border-radius: 50%;
        transition: $trans;

        & .v2avatar__text {
            color: #d0d6de;
            font-weight: 600;
            font-size: 24px;
        }
    }

    &._current &__preview {
        background: $colorBlueActive;

        & .v2avatar__text {
            color: $colorMain;
        }
    }

    &._support .v2avatar {
        background: #fff;
    }

    &._mode-popup .v2avatar {
        background: #fff;
    }

    &._support &__responsible {
        border-color: $colorBlueBack;
    }

    &__responsible {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: $colorBlueBack;
        border: 2px solid #fff;
        border-radius: 50%;
        transition: $trans;

        &._me {
            background: $colorMain;
        }

        &Wrapper {
            position: absolute !important;
            z-index: 2;

            @include forType("crm") {
                right: -4px;
                bottom: -4px;
                width: 24px;
                height: 24px;
            }
        }

        &Item {
            height: 100%;
        }

        & .v2avatar__text {
            font-size: 9px;
        }

        &Me {
            display: block;
            width: 10px;
            height: 10px;
            margin: auto;

            @include icon(#fff);
        }
    }

    &__content {
        position: relative;
        width: calc(100% - 58px);
        height: 100%;
        height: auto;
        padding-left: 14px;
    }

    &._support &__content {
        // padding-right: 0 !important;
    }

    &__title {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        padding-right: 38px;
        overflow: hidden;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.1;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: $trans;

        @include theme("light") {
            color: $colorDark;
        }

        @include theme("dark") {
            color: #fff;
        }
    }

    &._current &__title {
        font-weight: 900;

        @include theme("light") {
            color: $colorMain;
        }

        @include theme("dark") {
            color: $colorMainTheme;
        }
    }

    &__message {
        position: relative;
        width: 100%;
        height: 17px;

        &Inner {
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: $colorDark2;
            font-weight: 500;
            font-size: 14px;
            line-height: 1.3;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: $trans;
        }
    }

    &__type {
        height: 13px;
        margin-top: 6px;
        color: $colorGreyDark;
        font-weight: 500;
        font-size: 12.5px;
        white-space: nowrap;

        &Icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: -4px 4px -3px 0;
        }
    }

    &__time {
        position: absolute;
        right: 0;
        bottom: 1px;
        color: $colorGreyDark;
        font-weight: 500;
        font-size: 11px;
        transition: $trans;
    }

    &__counter {
        position: absolute;
        top: 8px;
        right: 24px;
        z-index: 3;
        transition: $trans;
    }

    &._support &__counter {
        right: 12px !important;
    }

    &__more {
        position: absolute;
        top: 8px;
        right: 4px;
        z-index: 10;
        width: 20px;
        height: 20px;
        transition: $trans;

        @include icon($colorGreyDark);

        &Icon {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
