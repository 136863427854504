.pep {
    width: 100%;
    height: 100%;
    background: $colorBlueBack;
    align-items: center;
    position: relative;

    &__inner {
        height: 100%;
        padding: 56px 0;
        align-items: center;
    }

    &__back {
        position: absolute;
        top: 52px;
        left: 64px;
        padding: 14px 18px;
        border-radius: 12px;
        background: rgba(#fff, .72);
        align-items: center;
        font-size: 14px;
        color: $colorDark2;
        z-index: 2;
        transition: $trans;
        @include button();

        &Icon {
            width: 14px;
            height: 14px;
            margin-right: 7px;
            @include icon($colorMain);
        }
    }

    &__logo {
        width: 74px;

        &Icon {
            width: 100%;
        }
    }

    &__content {
        width: 632px;
        margin: auto 0;
        padding: 40px 0;
        align-items: center;
    }

    &__title {
        max-width: 100%;
        font-size: 31px;
        font-weight: 900;
        text-align: center;
        color: $colorDark2;
        margin-bottom: 14px;
        margin-top: -32px;
    }

    &__description {
        max-width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 1.3;
        color: $colorDark2;
        margin-bottom: 22px;
    }

    &__area {
        width: 100%;
        background: #fff;
        border-radius: 20px;
        padding: 26px;
        box-shadow: 0 0 2px rgba(#D8DCE4, .72);
        margin-bottom: 16px;
        align-items: center;

        &Field {
            width: calc(100% - 160px);
            height: 46px;
            padding-right: 8px;
        }

        &._error &Field .field__input {
            color: $colorError;
            font-weight: 600;
        }

        &Button {
            width: 160px;
            height: 46px;

            & .button__content {
                font-size: 14.5px;
            }
        }
    }

    &__blocks {
        width: 100%;
        position: relative;
        transition: $trans;
    }

    &__block {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        transition: $trans;
        align-items: center;

        &:not(._show) {
            opacity: 0;
            transform: translate(40px, 0);

            &._hide {
                transform: translate(-40px, 0);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__info {
        font-size: 13.5px;
        color: $colorMain;
        align-items: center;
        margin-top: 10px;

        &Icon {
            width: 13px;
            height: 13px;
            margin-right: 5px;
            margin-top: 1px;
            @include icon(#AFC3FF);
        }
    }

    &__result {
        width: 100%;
        border-radius: 20px;
        border: 2px solid #fff;
        background: #fff;
        box-shadow: 0 0 2px rgba(#D8DCE4, .72);
        position: relative;
        z-index: 1;
        overflow: hidden;

        &Head {
            width: 100%;
            align-items: center;
            padding: 14px 26px;
            font-size: 16px;
            font-weight: 600;
            color: $colorSuccess;
            background: #E7FFE8;

            &Icon {
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }

        &._error &Head {
            background: #FFEDE9;
            color: #F25130;
        }

        &Content {
            width: 100%;
            padding: 26px;
        }

        &Block {
            width: 100%;
            position: relative;
            padding-left: 28px;

            &+& {
                margin-top: 16px;
            }

            &Icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 16px;
                height: 16px;
            }

            &Content {
                width: 100%;
            }

            &Title {
                max-width: 100%;
                font-size: 14.5px;
                font-weight: 600;
                color: $colorDark2;
                margin-bottom: 8px;
            }

            &Text {
                max-width: 100%;
                font-size: 14.5px;
                line-height: 1.3;
                color: #75808B;
            }
        }
    }

    &__copy {
        color: $colorGreyDark;
        font-size: 13.5px;
    }
}