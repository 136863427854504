.publicExecutorsHeader {
    @extend %section;

    position: relative;
    z-index: 1;
    width: 100%;
    padding-bottom: 10rem;

    @include media(0, $mediaM) {
        z-index: 1;
        padding: 10rem 0 4.5rem;
        overflow: hidden;
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -2;
        width: 85%;
        height: calc(100% + 13.5rem);
        content: "";
        pointer-events: none;

        @include bg("/img/crm/public/executors-header-image.png", 100% auto, right -13.5rem);

        @include mob {
            top: 6.5rem;
            right: 0;
            z-index: 2;
            width: 100%;

            @include bg("/img/crm/public/executors-header-image-mobile.png", 100% auto, right top);
        }
    }

    ._design-newYear &::after {
        @include mob {
            top: 11rem;
        }
    }

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 65rem;
        background: linear-gradient(to top, #fcfdff, #fcfdff 40%, rgba(#fcfdff, 0));
        content: "";

        @include media(0, $mediaM) {
            top: 0;
            bottom: auto;
            height: 100%;
            background: none;

            @include bg("/img/crm/public/executors-header-back-mobile.png", 100% auto, right top);
        }
    }

    ._design-newYear &::before {
        @include mob {
            top: 4rem;
        }
    }

    &__inner {
        @extend %publicInner;

        align-items: center;
    }

    &__content {
        position: relative;
        justify-content: center;
        width: 100%;
        height: 78rem;
        margin-bottom: 9rem;
        padding-bottom: 4rem;

        @include media(0, $mediaM) {
            height: auto;
            margin-bottom: 7rem;
            padding-top: 51rem;
            padding-bottom: 0;
        }

        &Info {
            width: 47rem;

            @include media(0, $mediaM) {
                align-items: center;
                width: 100%;
            }
        }

        &More {
            position: absolute;
            bottom: 0;
            left: 0;
            padding-left: 3.6rem;
            color: $colorDark2;
            font-weight: 300;
            font-size: 1.35rem;
            line-height: 1.25;

            @include media(0, $mediaM) {
                display: none;
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: -0.5rem;
                width: 3.5rem;
                height: 3.5rem;
                object-fit: contain;
                object-position: left center;
                transform: translate(0, -50%);
            }
        }

        &Bubble {
            position: absolute;
            z-index: 3;
            padding: 3rem 3.3rem 2.5rem;
            border-radius: 2rem;
            transition: 0.3s cubic-bezier(0.35, 1.57, 0.5, 1.59);
            will-change: transform;

            @include media(0, $mediaM) {
                padding: 1.6rem 2.3rem;
                border-radius: 1.5rem;
            }

            &:hover {
                transform: scale(1.04);
            }

            &._1 {
                top: 16.5rem;
                right: 52rem;

                @include media(0, $mediaM) {
                    top: 38.5rem;
                    right: 0;
                }
            }

            &._2 {
                top: 24.5rem;
                right: 5rem;

                @include media(0, $mediaM) {
                    top: 23rem;
                    right: auto;
                    left: 0;
                }
            }

            &._3 {
                top: 52rem;
                right: 44rem;

                @include media(0, $mediaM) {
                    top: 6rem;
                    right: -0.5rem;
                    z-index: 1;
                }
            }

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background: #fff;
                border-radius: 2rem;
                content: "";
                mask: radial-gradient(circle 1.1rem at center right, #0000 99%, #000) center right;
                mask-size: 100% 100%;
                mask-repeat: no-repeat;

                @include media(0, $mediaM) {
                    border-radius: 1.5rem;
                }
            }

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;
                width: calc(100% - 2.2rem);
                height: 100%;
                border-radius: 2rem;
                box-shadow: 0 2rem 7rem 0 rgb(0 22 88 / 12%);
                content: "";

                @include media(0, $mediaM) {
                    border-radius: 1.5rem;
                }
            }

            &Title {
                max-width: 100%;
                margin-bottom: 0.7rem;
                color: $colorDark2;
                font-weight: 600;
                font-size: 2rem;

                @include media(0, $mediaM) {
                    margin-bottom: 0.5rem;
                    font-size: 2rem;
                }

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    z-index: 1;
                    width: 2px;
                    height: calc(100% - 6rem);
                    background: $colorMain;
                    border-radius: 0 360px 360px 0;
                    transform: translate(0, -50%);
                    content: "";

                    @include media(0, $mediaM) {
                        height: calc(100% - 3.5rem);
                    }
                }
            }

            &Description {
                max-width: 100%;
                color: $colorDark2;
                font-size: 1.45rem;
                line-height: 1.4;

                @include media(0, $mediaM) {
                    font-size: 1.5rem;
                }
            }
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 2rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 5.2rem;
        line-height: 1.2;

        @include media(0, $mediaM) {
            margin-bottom: 1.2rem;
            font-size: 4.7rem;
            text-align: center;
        }
    }

    &__description {
        max-width: 100%;
        margin-bottom: 2.5rem;
        color: $colorDark2;
        font-size: 1.85rem;
        line-height: 1.55;

        @include media(0, $mediaM) {
            max-width: 90%;
            font-size: 2.1rem;
            text-align: center;
        }
    }

    &__button {
        @include media(0, $mediaM) {
            width: 78vw;
        }
    }

    &__companies {
        position: relative;
        z-index: 1;
        align-items: center;
        width: calc(#{$widthPublicInner} + 10rem);
        margin-bottom: 29rem;
        padding: 8rem 6rem;

        @include media(0, $mediaM) {
            width: calc(100% + 3rem);
            margin-bottom: 11rem;
            padding: 6.2rem 3rem 8rem;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: #fff;
            border-radius: 4rem;
            content: "";
            mask:
                radial-gradient(circle 1.5rem at top center, #0000 99%, #000) top center,
                radial-gradient(circle 1.5rem at bottom center, #0000 99%, #000) bottom center;
            mask-size: 100% 50%;
            mask-repeat: no-repeat;

            @include media(0, $mediaM) {
                border-radius: 5.5rem;
                mask:
                    radial-gradient(circle 2.5rem at top center, #0000 99%, #000) top center,
                    radial-gradient(circle 2.5rem at bottom center, #0000 99%, #000) bottom center;
                mask-size: 100% 50%;
                mask-repeat: no-repeat;
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 4rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 3rem;

            @include media(0, $mediaM) {
                font-size: 3.5rem;
                line-height: 1.3;
                text-align: center;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;
                width: calc(50% - 1.5rem);
                height: 100%;
                border-radius: 4rem 0 0 4rem;
                box-shadow: 0 1.6rem 7.2rem 0 rgb(0 0 0 / 4%);
                content: "";

                @include media(0, $mediaM) {
                    width: calc(50% - 2.5rem);
                }
            }

            &::after {
                right: 0;
                left: auto;
                border-radius: 0 4rem 4rem 0;
            }

            & span {
                font-weight: 600;
                border-bottom: 1px solid rgba($colorMain, 0.5);
            }
        }

        &Items {
            align-items: center;
            justify-content: center;
            width: 100%;

            @include media(0, $mediaM) {
                flex-direction: column;
            }
        }

        &Item {
            height: 10rem;
            margin: 0 0.75rem;
            background: #f5f7fa;
            border-radius: 1.6rem;
            transition: 0.3s cubic-bezier(0.35, 1.57, 0.5, 1.59);
            will-change: transform;

            @include media(0, $mediaM) {
                width: 100%;
                height: 14.4rem;
                margin: 0;
                border-radius: 2.5rem;

                & + & {
                    margin-top: 1.3rem;
                }
            }

            &:hover {
                transform: scale(1.04);
            }

            &Logo {
                height: 100%;
                object-fit: contain;

                @include media(0, $mediaM) {
                    width: 100%;
                    transform: scale(1);
                }
            }
        }
    }

    &__instruction {
        width: calc(#{$widthPublicInner} + 10rem);

        @include media(0, $mediaM) {
            flex-direction: column-reverse;
            align-items: center;
            width: calc(100% + 3rem);
        }

        &Items {
            align-items: stretch;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 4.5rem;
            counter-reset: itemCounter;

            &Wrapper {
                @extend %noscroll;

                width: calc(100% - 25rem);

                @include media(0, $mediaM) {
                    position: relative;
                    width: calc(100% + 8vw - 3rem);
                    overflow: hidden;
                    overflow-x: auto;
                }
            }

            @include media(0, $mediaM) {
                display: inline-flex;
                width: auto;
                padding: 0 7.5vw 6rem;
            }
        }

        &Item {
            width: calc(calc(100% / 5) - 2.4rem);
            counter-increment: itemCounter;

            &._info {
                width: 22rem;
                margin-left: auto;
                padding: 1.5rem 0 0 1.5rem;

                @include media(0, $mediaM) {
                    width: 40rem;
                    margin: 0 auto 5rem;
                    padding: 0;
                }
            }

            &:not(._info) {
                transition: 0.3s cubic-bezier(0.35, 1.57, 0.5, 1.59);
                will-change: transform;

                @include media(0, $mediaM) {
                    width: 75vw;

                    & + & {
                        margin-left: 3rem;
                    }
                }

                &:hover {
                    transform: scale(1.04);

                    @include media(0, $mediaM) {
                        transform: none;
                    }
                }
            }
        }

        &Item:nth-child(2n) &Step {
            transform: translate(0, 4.5rem);

            @include media(0, $mediaM) {
                transform: translate(0, 0);
            }
        }

        &Item:not(:first-child, ._info) {
            position: relative;

            &::before {
                position: absolute;
                top: 7.7rem;
                right: 100%;
                z-index: 2;
                width: 2.8rem;
                height: 1px;
                background: $colorMain;
                content: "";

                @include media(0, $mediaM) {
                    display: none;
                }
            }

            &::after {
                position: absolute;
                top: 7.4rem;
                right: 100%;
                z-index: 3;
                width: 0.8rem;
                height: 0.8rem;
                margin-right: 2.6rem;
                background: radial-gradient(circle, $colorMain, $colorMain 50%, transparent 50%, transparent);
                content: "";

                @include media(0, $mediaM) {
                    display: none;
                }
            }
        }

        &Step {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;

            // overflow: hidden;
            padding: 6.5rem 3.5rem 4rem;
            background: #fff;
            border-radius: 3.6rem;
            box-shadow: 0 1rem 3.2rem 0 rgb(0 0 0 / 6%);

            @include media(0, $mediaM) {
                padding: 9rem 4.5rem 4.5rem;
                border-radius: 5rem;
            }

            &::before {
                @extend %row;

                position: absolute;
                top: 0;
                left: 0;
                justify-content: center;
                padding: 1rem 3rem 0.9rem 3.5rem;
                color: #fff;
                font-weight: 700;
                font-size: 1.45rem;
                text-transform: uppercase;
                background: #3e454c;
                border-radius: 3.6rem 0 1.5rem;
                content: "Шаг " counter(itemCounter);

                @include media(0, $mediaM) {
                    padding: 1.5rem 4.5rem 1.4rem;
                    font-size: 2.1rem;
                    border-radius: 5rem 0 2rem;
                }
            }

            &Title {
                position: relative;
                max-width: 100%;
                margin-bottom: 0.5rem;
                color: $colorDark2;
                font-weight: 600;
                font-size: 1.75rem;
                line-height: 1.4;

                @include media(0, $mediaM) {
                    margin-bottom: 1rem;
                    font-size: 3rem;
                    line-height: 1.35;
                }
            }

            &Description {
                max-width: 100%;
                margin-bottom: 1.5rem;
                color: $colorDark2;
                font-size: 1.5rem;
                line-height: 1.4;

                @include media(0, $mediaM) {
                    margin-bottom: 4rem;
                    font-size: 2.3rem;
                }
            }

            &Link {
                position: relative;
                margin-top: auto;
                padding-right: 2.5rem;
                color: $colorMain;
                font-size: 1.6rem;

                @include media(0, $mediaM) {
                    padding-right: 4.5rem;
                    font-size: 2.3rem;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 2rem;
                    height: 2rem;
                    margin-top: 0.1rem;
                    transform: translate(0, -50%);

                    @include media(0, $mediaM) {
                        width: 3rem;
                        height: 3rem;
                        margin-top: 0.2rem;
                    }
                }
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 2rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 3.8rem;
            line-height: 1.3;

            @include media(0, $mediaM) {
                margin-bottom: 1.5rem;
                font-size: 4rem;
                text-align: center;
            }
        }

        &Description {
            max-width: 100%;
            color: $colorDark2;
            font-size: 1.9rem;
            line-height: 1.3;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
                line-height: 1.4;
                text-align: center;
            }
        }
    }
}
