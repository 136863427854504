.body {
    width: 100%;

    &__sideBar {
        position: fixed;
        top: 16px;
        left: 16px;
        z-index: 3;
        width: 110px;
        height: calc(100% - 32px);
    }

    &__notificationsBar {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1;
        width: $widthNotificationsBar;
        height: 100%;
        padding: $paddingContent $paddingContent $paddingContent 0;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &._hide {
            z-index: 9;
            width: 64px;
            height: 64px;
            padding: 0;
        }

        &._showHideState {
            z-index: 8;
            padding: 0;

            &::before {
                position: absolute;
                top: 0;
                right: 0;

                // @include blur(15px);
                z-index: -1;
                width: 100vw;
                height: 100vh;
                background: rgba(#000, 0.2);
                transition: $trans;
                content: "";
            }

            &:not(._show)::before {
                opacity: 0;
            }
        }
    }

    &__page {
        transform-origin: center;
        transition: $trans;

        &._waitCorporation:not(._popup, ._auth, ._join, ._public) {
            filter: blur(15px);
            pointer-events: none;
        }

        &:not(._popup) {
            z-index: 2;
        }

        &._crm:not(._popup, ._auth, ._join, ._pdf, ._public) {
            // top: $paddingContent;
            top: 0;
            left: $widthSideBar + $paddingContent;
            width: calc(100% - #{$widthSideBar} - #{$widthNotificationsBar} - #{$paddingContent} * 2);
            min-height: calc(100% - #{$paddingContent} * 2);
            padding: $paddingContent 0;

            &._hideNotificationsBar {
                left: $widthSideBar + $paddingContent;
                width: calc(100% - #{$widthSideBar} - #{$paddingContent} * 2);
            }
        }

        &:not(._show)._popup {
            transform: scale(1.05);
        }

        &:not(._show) {
            transform: scale(1.05);
        }

        &._prev:not(._popup, ._auth, ._join) {
            transform: scale(0.95);
        }

        &._crm._popup,
        &._crm._auth {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            height: 100%;
        }

        &._crm._auth {
            background: #fff;
        }

        &._crm._users-knowledge,
        &._crm._clients-knowledge {
            background: $colorBlueBack;
        }

        &Inner {
            width: 100%;
            max-width: 988px;
            height: 100%;
            margin: 0 auto;
        }

        &._crm._popup &Inner,
        &._crm._auth &Inner,
        // &._crm._reports &Inner,
        &._crm._join &Inner {
            max-width: none;
            height: 100%;
        }

        &._mailing,
        &._chat-template {
            background: rgba(#000, 0.32);
        }
    }

    & [class*="body__"][class*="Popup"] {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10002;
        width: 100%;
        height: 100%;

        // @include blur(15px);
        background: rgba(#000, 0.32);
        transition: $trans;

        &._blur {
            background: rgba(#3e454c, 0.1);

            @include blur(20px);
        }

        &:not(._show) {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__serverNotAvailable {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10000;
        width: 100%;
        transition: $trans;

        &:not(._show) {
            transform: translate(0, 100%);
        }
    }

    &__waitBack {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__supportChatBtn {
        position: fixed;
        right: 12px;
        bottom: 12px;
        z-index: 12;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        background: $colorMain;
        border-radius: 20px;
        box-shadow: 0 -8px 24px rgba($colorMain, 0.48);
        transition: $trans;

        @include button(0.97);

        &Icon {
            width: 18px;
            height: 18px;
            margin-bottom: 3px;

            @include icon(#fff);
        }

        &Text {
            color: #fff;
            font-weight: 600;
            font-size: 14.5px;
            text-align: center;
        }
    }

    &__listInner {
        width: 100%;
        height: 100%;
    }

    &__innerPages {
        width: 100%;
        height: 100%;
    }

    &__innerPage {
        width: 100%;
        height: 100%;

        &:not(._show) {
            &._prev {
                transform: translate(-20px, 0);
            }

            &._next {
                transform: translate(20px, 0);
            }
        }

        &._popup {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1000;
            width: 100%;
            height: 100%;
        }

        &Wrapper {
            width: 100%;
            height: 100%;
            padding: 24px;
        }

        &Box {
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Loader {
            @include loader($colorMain);
        }
    }
}

._design-newYear,
._design-newYear *,
._design-newYear *::before,
._design-newYear *::after {
    cursor: none !important;
}
