.v2widget {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;
    background: $colorBlueBack;
    border-radius: 24px;

    &._notBorder {
        border-radius: 0;
    }

    &._white {
        background: #fff;
    }

    &._inWidget {
        border-radius: 20px;
        box-shadow: 0 0 2px 0 #d8dce4;
    }

    &._cursorLists {
        // border-radius: 16px;
        // box-shadow: 0 0 2px 0 #d8dce4;

        background: none;
        border-radius: 0;
    }

    &._cursorLists._inFilter {
        background: $colorBlueBack;
        border-radius: 12px;
        box-shadow: none;
    }

    &._cursorLists &__head {
        height: 64px;
        padding: 16px;

        @include forType("app") {
            height: 68px;
        }

        & .v2input__support,
        & .v2input__field {
            @include forType("app") {
                font-size: 15px;
            }
        }
    }

    &:not(._init) &__headSearchInner {
        opacity: 0;
    }

    &._cursorLists._inFilter &__head {
        height: 42px;
        padding: 0;

        & .v2search {
            padding: 0;
            background: none;
        }

        & .v2search__icon {
            display: none;
        }

        & .v2search__field {
            padding: 0 16px;
        }

        & .v2input__support,
        & .v2input__field {
            font-size: 15px;
        }

        &Actions {
            height: 100%;

            &Elem {
                width: 100%;
                height: 100%;
            }
        }

        &Search {
            height: 100%;

            &Fake {
                top: 50%;
                left: 50%;
                width: calc(100% - 32px);
                height: calc(100% - 24px);
                transform: translate(-50%, -50%);
            }
        }
    }

    &._hideContent &__head {
        border-bottom-color: transparent !important;
    }

    &._cursorLists._inFilter &__headActionsFake {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }

    &._cursorLists &__headActions {
        width: 100%;
    }

    &:not(._inWidget) > &__head &__headName {
        position: relative;
        transition: $trans;

        // &::before {
        //     position: absolute;
        //     top: 50%;
        //     left: 0;
        //     z-index: 2;
        //     width: 24px;
        //     height: 24px;
        //     transform: translate(0, -50%);
        //     opacity: 0;
        //     transition: $trans;
        //     content: "";

        //     @include bg("/img/crm/newYear/decor.svg", contain, left center);
        // }
    }

    ._design-newYear &:not(._inWidget) > &__head &__headName {
        padding-left: 32px;

        &::before {
            opacity: 1;
        }
    }

    &__head {
        position: relative;
        z-index: 11;
        align-items: center;
        width: 100%;
        height: 62px;
        padding: 16px 24px;
        border-bottom: 1px solid $colorBlue;

        &._withBack {
            padding-left: 28px;
        }

        &Back {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            width: 32px;
            height: 32px;
            transform: translate(-50%, -50%);
            transition: $trans;
            pointer-events: visible;

            &Inner {
                @include button(0.95);

                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: $colorBlueBack;
                border: 2px solid #fff;
                border-radius: 50%;
                transition: $trans;
            }

            &Icon {
                width: 18px;
                height: 18px;

                @include icon($colorDark2);
            }
        }

        &Info {
            align-items: center;
            transition: $trans;

            &._withBack {
                transform: translate(10px, 0);
            }
        }

        &Name {
            align-items: center;
            height: 18px;
            margin-right: 14px;
            color: $colorDark2;
            font-weight: 700;
            font-size: 18px;
            line-height: 1.1;
            transition: $trans;
        }

        &Description {
            margin-left: 3px;
            font-weight: 300;
            font-size: 18px;
            white-space: nowrap;

            &Inner {
                display: inline-block;
                font-weight: 300;
                font-size: 18px;
                line-height: 0.65;

                &._inWidget {
                    font-size: 16px;
                }
            }
        }

        &Actions {
            position: relative;
            width: auto;
            height: 32px;
            margin-left: auto;

            &Elem._search {
                flex: 1 auto;
            }

            &Fake {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%);
            }
        }

        &ActionsElem {
            right: 0;
            left: auto;
            height: 100%;

            &._full {
                width: 100%;
            }

            &Inner {
                width: 100%;
                height: 100%;
                transition: $trans;
            }

            &:not(._show) &Inner {
                opacity: 0;
            }
        }

        &Actions._static &ActionsElem:not(._last) {
            margin-right: 6px;
        }

        &Actions._static &ActionsElem:not(._last)._lastInGroup {
            margin-right: 12px;
        }

        &ActionsElem._current &ActionInner {
            background: #fff;

            @include icon($colorMain);
        }

        &Close {
            width: 22px;
            height: 22px;
            margin: auto 0;

            &:hover {
                @include hover {
                    @include icon($colorMain);
                }
            }

            &Icon {
                width: 100%;
                height: 100%;
            }
        }

        &Action {
            position: relative;
            width: 32px;
            height: 32px;

            &Inner {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: $colorDark2;
                font-size: 14px;
                background: #fbfcfd;
                border-radius: 10px;
                transition: $trans;

                @include button(0.97);
            }

            &Icon {
                width: 16px;
                height: 16px;

                @include icon($colorDark2);
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 16px;
                    height: 16px;
                }
            }

            &Counter {
                position: absolute;
                top: -4px;
                right: -4px;
                z-index: 2;
                min-width: 18px;
                height: 18px;

                &._child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    padding: 2px 4px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 10px;
                    text-align: center;
                    background: $colorMain;
                    border: 2px solid #fff;
                    border-radius: 360px;
                }
            }
        }

        &Link {
            position: relative;
            height: 30px;
            transition: $trans;

            &Inner {
                @extend %row;

                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 4px 13px 5px;
                color: $colorDark2;
                font-weight: 500;
                font-size: 14px;
                white-space: nowrap;
                background: #fbfcfd;
                border: 1px solid transparent;
                border-radius: 9px;
                transition: $trans;

                @include button;
            }

            &._current &Inner {
                color: $colorMain;
                background: #fff;
            }

            &._more &Inner {
                padding-right: 10px;
                padding-left: 10px;
            }

            &._white:not(._disabled) &Inner {
                background: #fff;
            }

            &._white._shadow:not(._disabled) &Inner {
                box-shadow: 0 0 2px 0 #d8dce4;
            }

            &._main:not(._disabled) &Inner {
                color: #fff;
                font-weight: 600;
                background: $colorMain;
                box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);
            }

            &._alert:not(._disabled, ._current) &Inner {
                color: #fff;
                font-weight: 600;
                background: none;
                background: $colorAlert;
                box-shadow: 0 2px 4px 0 rgb($colorAlert, 0.56);
            }

            &._alertEmpty:not(._disabled) &Inner {
                color: $colorAlert;
                font-weight: 600;
                border-color: $colorAlert;
            }

            &._disabled &Inner {
                color: $colorGreyDark;
                font-weight: 600;
                background: $colorBlueBack;
            }

            &Icon {
                width: 14px;
                height: 14px;
            }

            &._more &Icon {
                width: 16px;
                height: 16px;
                margin-right: -4px;
                transform: translate(0, 1px);
            }

            &._current._more &Icon {
                @include icon($colorMain);
            }

            &Loader {
                background: inherit;
                border-radius: inherit;

                @include loader($colorMain);

                & ._LOADERITEM {
                    width: 16px;
                    height: 16px;
                }
            }

            &._alert,
            &._main {
                @include loader(#fff);
            }

            &._alertEmpty {
                @include loader($colorAlert);
            }

            &Counter {
                position: absolute;
                top: -4px;
                right: -4px;
                z-index: 3;
            }

            &List {
                position: absolute;
                top: 100%;
                right: -8px;
                z-index: 2;
                margin-top: 12px;
                padding: 12px 14px;
                background: #fff;
                border-radius: 12px;
                box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);

                &:not(._show) {
                    transform: translate(0, 4px);
                }

                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    right: 12px;
                    z-index: -1;
                    width: 32px;
                    height: 10px;
                    background: #fff;
                    content: "";
                }

                &::before {
                    right: 18px;
                    z-index: -2;
                    width: 10px;
                    box-shadow: 0 0 8px 0 rgb(0 0 0 / 10%);
                    transform: translate(0, -50%) rotate(45deg);
                }

                &Item {
                    color: $colorDark2;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 1.3;
                    white-space: nowrap;

                    &._current {
                        color: $colorMain;
                    }

                    @include button(0.96);

                    & + & {
                        margin-top: 10px;
                    }
                }
            }
        }

        &Search {
            position: relative;
            width: 100%;
            height: 32px;

            @include forType("app") {
                height: 36px;
            }

            &Inner {
                width: 100%;
                height: 100%;
                transition: $trans;
            }

            &:not(._init)::before {
                opacity: 0;
            }
        }

        &Data {
            position: relative;
            height: 40px;

            &Loader {
                &Title {
                    width: 150px;
                    height: 20px;
                    margin-bottom: 6px;
                }

                &Description {
                    width: 250px;
                    height: 18px;
                }
            }

            &Inner {
                white-space: nowrap;
                transition: $trans;
            }

            &:not(._ready) &Inner {
                opacity: 0;
            }

            &Title {
                margin-bottom: 4px;
                color: $colorDark;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.3;

                & b {
                    font-weight: 600;
                }

                &Item {
                    display: inline-block;
                    font-size: 16px;
                    line-height: 0.7;

                    &._parent {
                        margin-left: 4px;
                    }
                }
            }

            &Description {
                align-items: center;

                &Item {
                    font-weight: 600;
                    font-size: 14px;

                    & + & {
                        margin-left: 6px;
                    }

                    &._completed {
                        color: $colorSuccess;
                    }

                    &._error {
                        color: $colorAlert;
                    }
                }
            }

            &Current {
                align-items: center;
                margin-right: 12px;
                color: $colorGreyDark;
                font-size: 14px;
                line-height: 1.3;
                transition: $trans;

                &._active {
                    color: $colorMain;
                }

                &Item {
                    color: $colorGreyDark;
                    font-size: 14px;
                    line-height: 1.3;

                    &._parent {
                        margin-left: 2px;
                    }
                }

                &._active &Item {
                    color: $colorMain;
                }
            }
        }

        &._withDrop &Search::before {
            position: absolute;
            top: 50%;
            right: 16px;
            z-index: 2;
            margin-top: 2px;
            border: 2px solid transparent;
            border-right-color: $colorDark2;
            border-bottom-color: $colorDark2;
            transform: translate(0, -50%) rotate(-135deg);
            transition: $trans;
            content: "";
            pointer-events: none;
        }
    }

    ._parentCalc._cursorLists &__headActions._static &__headActionsElem:not(._last) {
        margin-right: 4px;
    }

    ._parentCalc._cursorLists &__headActions._static &__headActionsElem:not(._last)._lastInGroup {
        margin-right: 8px;
    }

    &._hideContent &__head._withDrop &__headSearch::before {
        margin-top: 0;
        transform: translate(0, -50%) rotate(45deg);
    }

    &._inWidget > &__head {
        height: auto;
        border-bottom: 0;
    }

    &._inWidget &__head {
        &Name {
            font-size: 16px;
        }

        &Description {
            font-size: 16px;
        }
    }

    &._white &__headActionCounter._child {
        border-color: $colorBlueBack;
    }

    &._cursorLists &__content {
        // height: calc(100% - 64px);
        height: 190px;
        border-radius: 0 0 20px 20px;
    }

    &._cursorLists._withoutHead &__content {
        border-radius: 20px;
    }

    &._notBorder &__content {
        border-radius: 0;
    }

    &._cursorLists._inFilter &__content {
        height: 220px;
    }

    &._hideContent &__content {
        height: 0 !important;
    }

    &._cursorLists._choice .v2table__content {
        padding-bottom: 64px;
    }

    &__content {
        position: relative;
        z-index: 10;
        width: 100%;
        height: calc(100% - 62px);
        overflow: hidden;
        border-radius: 0 0 24px 24px;
        transition: $trans;

        // &Loader{
        //     @include lo
        // }

        &Wait {
            width: 100%;
            height: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &._inWidget > &__content {
        // height: calc(100% - 70px);
        // height: auto;
        border-radius: 0 0 20px 20px;
    }

    &._white &__headActionInner {
        background: $colorBlueBack;
    }

    &._innerPadding > &__content {
        padding: 24px;
    }

    &__tabs {
        position: relative;
        z-index: 1;
        width: calc(100% + 20px);
        height: 35px;
        margin-right: -10px;
        margin-left: -10px;
        border-radius: 10px;
        transition: $trans;

        &._show {
            background: #f9fbfd;
        }

        &Fake {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            pointer-events: none;
        }

        &Inner {
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 4px;
            overflow: hidden;
            overflow-x: auto;
            transition: $trans;
        }

        &:not(._show) &Inner {
            opacity: 0;
        }
    }

    &__tab {
        padding: 7px 8px;
        white-space: nowrap;
        border-radius: 8px;

        @include button(0.97);

        &._current {
            background: #fff;
            box-shadow: 0 0 2px 0 rgb(62 69 76 / 24%);
        }

        &._current &Content,
        &._current &Counter._child {
            color: $colorMain;
        }

        &Content {
            display: inline-block;
            color: $colorDark2;
            font-weight: 500;
            font-size: 13px;
            transition: $trans;
        }

        &Counter {
            display: inline-block;
            font-weight: 300;
            font-size: 13px;
            line-height: 0.7;

            &._child {
                transition: $trans;
            }

            &._parent {
                margin-left: 1px;
            }
        }

        & + & {
            margin-left: 3px;
        }
    }
}
