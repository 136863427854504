.manualCardAvatar {
    width: 100%;
    height: 100%;
    align-items: stretch;

    &__block {
        &._result {
            width: 100px;
        }

        &._list {
            width: calc(100% - 116px);
            padding-left: 16px;
            margin-left: 16px;
            border-left: 1px solid #E2E8EE;
            justify-content: center;
        }
    }

    &__result {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: $colorBlueBack;
        position: relative;
        transition: $trans;
        overflow: hidden;
        z-index: 1;

        & .avatar__item {
            font-size: 26px;
            font-weight: 500;
            color: $colorDark2;
        }

        & .avatar__icon {
            width: 60px;
            height: 60px;
            object-fit: contain;
        }
    }

    &._edit &__result {
        background: $colorBlueActive;

        & .avatar__item {
            color: $colorMain;
        }
    }

    &__items {
        width: 100%;
        flex-wrap: wrap;
    }

    &:not(._edit) &__item {
        &:not(._current) {
            opacity: 0.32;
        }
    }

    &__item {
        width: calc(100% / 18);
        align-items: center;
        margin: 4px 0;
        transition: $trans;

        &Inner {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            z-index: 1;
            background: $colorBlueBack;
            transition: $trans;
            @include button(.97);
            border: 1px solid transparent;

            & .avatar__icon {
                width: 100%;
                height: 100%;
            }
        }

        &._current &Inner {
            border-color: $colorMain;
            background: $colorBlueActive;

            & .avatar__item {
                color: $colorMain;
            }
        }
    }
}