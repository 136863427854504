.knowledge {
    width: 100%;
    height: 100%;
    background: $colorBlueBack;

    &__head {
        position: relative;
        align-items: center;
        width: 100%;
        height: 66px;
        padding: 0 22px;
        border-bottom: 1px solid #dde2e9;

        &Back {
            position: relative;
            padding-left: 38px;
            color: $colorDark2;
            font-size: 16px;

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 28px;
                height: 28px;
                padding: 4px;
                background: #fff;
                border-radius: 50%;
                transform: translate(0, -50%);

                @include icon($colorDark2);
            }
        }

        &Logo {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 128px;
            transform: translate(-50%, -50%);
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__content {
        width: 100%;
        height: calc(100% - 66px);

        &Inner {
            width: 100%;
            height: 100%;
            transition: $trans;
        }

        & ._INFOBLOCKWRAPPER {
            z-index: 5;
            background: #fbfcfd;
            border-radius: 24px;
        }

        &List {
            position: relative;
            width: 324px;
            height: 100%;
            padding: 38px 38px 0;

            & ._INFOBLOCKWRAPPER {
                top: 50%;
                height: calc(100% - 40px);
                transform: translate(0, -50%);
            }
        }

        &Articles {
            position: relative;
            width: calc(100% - 324px);
            height: 100%;

            & ._INFOBLOCKWRAPPER {
                top: auto;
                bottom: 20px;
                width: calc(100% - 38px);
                max-width: 900px;
                height: calc(100% - 58px);
            }
        }
    }

    &._loading &__listBlocks {
        opacity: 0;
    }

    &__list {
        width: 100%;
        height: 100%;

        &Search {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 42px;

            &::after {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 28px;
                background: linear-gradient(to bottom, $colorBlueBack, rgba($colorBlueBack, 0));
                content: "";
                pointer-events: none;
            }
        }

        &Blocks {
            position: relative;
            z-index: 1;
            width: 100%;
            height: calc(100% - 42px);
            padding: 28px 0;
            overflow: hidden;
            overflow-y: auto;
            transition: $trans;
        }

        &Block {
            width: 100%;

            & + & {
                margin-top: 32px;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 14px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.3;
            }

            &Items {
                width: 100%;
            }

            &Item {
                position: relative;
                z-index: 1;
                width: 100%;
                padding: 16px;
                background: linear-gradient(-45deg, #ff4b5d, #e24ea2, #b862e1, #8568f8, #4070ff);
                border-radius: 16px;
                transition: $trans;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                    background: #fbfcfd;
                    border-radius: 15px;
                    transition: $trans;
                    content: "";
                    pointer-events: none;
                }

                &::before {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    z-index: 2;
                    width: 4px;
                    height: 4px;
                    background: $colorAlert;
                    border-radius: 50%;
                    opacity: 0;
                    transition: $trans;
                    content: "";
                }

                @include button(0.97);

                &._current {
                    &::after {
                        width: calc(100% - 2px);
                        height: calc(100% - 2px);
                        background: #fff;
                        transform: translate(1px, 1px);
                    }

                    &::before {
                        opacity: 1;
                    }
                }

                & + & {
                    margin-top: 6px;
                }

                &Inner {
                    position: relative;
                    width: 100%;
                }

                &._withIcon &Inner {
                    padding-left: 30px;
                }

                &Icon {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 22px;
                    height: 22px;
                    transition: $trans;
                }

                &:not(._current) &Icon {
                    opacity: 0.4;
                    filter: grayscale(1);
                }

                &Title {
                    color: $colorDark2;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.3;
                    transition: $trans;
                }

                &._current &Title {
                    font-weight: 500;
                }
            }
        }
    }

    &._loading &__articles {
        opacity: 0;
    }

    &__articles {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 36px;
        padding-right: 36px;
        padding-bottom: 80px;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;

        &Item {
            width: 100%;
            max-width: 900px;

            & + & {
                margin-top: 80px;
            }
        }
    }

    &__article {
        width: 100%;
        background: #fff;
        border-radius: 32px;

        &Head {
            width: 100%;
            padding: 54px 72px 46px;
            border-bottom: 1px solid $colorBlue;
        }

        &Section {
            margin-bottom: 5px;
            color: $colorGreyDark;
            font-weight: 600;
            font-size: 13px;
            line-height: 1.3;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        &Title {
            color: $colorDark2;
            font-weight: 600;
            font-size: 28px;
            line-height: 1.3;
        }

        &Content {
            width: 100%;
            padding: 50px 72px;

            &Inner {
                @extend %editorContent;

                color: $colorDark2;
                font-size: 16px;
                line-height: 1.3;

                & ._ancor {
                    color: $colorMain;

                    & * {
                        color: $colorMain;
                    }
                }

                & h2 {
                    font-weight: 600;
                    font-size: 20px;

                    & * {
                        font-weight: 600;
                    }
                }

                & h3 {
                    font-weight: 600;
                    font-size: 18px;

                    & * {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
