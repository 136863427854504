.widget {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;

    // width: 988px;
    min-height: 100%;

    &._parent {
        padding-top: $heightWidgetHead;
    }

    &._parent &__head {
        position: absolute;
        height: 100%;
    }

    &._windowFull &__head {
        &Inner {
            align-items: flex-end;
            height: calc(#{$heightWidgetHead} + #{$paddingContent});
            border-radius: 0;
        }
    }

    &__head {
        position: relative;
        top: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        pointer-events: none;

        &Back {
            position: absolute;
            top: calc(#{$heightWidgetHead} / 2);
            left: 0;
            z-index: 2;
            width: 32px;
            height: 32px;
            transform: translate(-50%, -50%);
            transition: $trans;
            pointer-events: visible;

            &:not(._show) {
                opacity: 0;
            }

            &Inner {
                @include button(0.95);

                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                background: $colorBlueBack;
                border: 2px solid #fff;
                border-radius: 50%;
                transition: $trans;
            }

            &Icon {
                width: 18px;
                height: 18px;

                @include icon($colorDark2);
            }
        }

        &Content {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &Inner {
            position: relative;
            align-items: center;
            width: 100%;
            height: $heightWidgetHead;
            padding: 16px var(--paddingWidget);
            background: $colorBlueBack;
            border-bottom: 1px solid $colorBlue;
            border-radius: 24px 24px 0 0;
            pointer-events: visible;
        }

        &Info {
            align-items: center;
            transition: $trans;

            &._withBack {
                transform: translate(10px, 0);
            }
        }

        &Info._forceShow &NameItem {
            opacity: 1;
        }

        ._design-newYear &Name {
            position: relative;
            padding-left: 32px;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                width: 24px;
                height: 24px;
                transform: translate(0, -50%);
                content: "";

                @include bg("/img/crm/newYear/decor.svg", contain, left center);
            }
        }

        &Name {
            align-items: center;
            height: 18px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 18px;
            transition: $trans;

            &._hide:not(._show) {
                opacity: 0;
            }

            &Item {
                position: relative;
                height: 100%;
                margin-left: 4px;
                font-weight: 300;
                opacity: 0;
                transition: $trans;

                &Inner {
                    @extend %row;

                    position: absolute;
                    top: 0;
                    left: 0;
                    align-items: center;
                    height: 100%;
                    font-weight: 300;
                    white-space: nowrap;
                    transition: $trans;

                    &:not(._show)._page {
                        opacity: 0;

                        &._next {
                            transform: translate(0, -10px);
                        }

                        &._prev {
                            transform: translate(0, 10px);
                        }
                    }
                }

                &Content {
                    @extend %row;

                    align-items: center;
                    height: 100%;
                    font-weight: 300;
                    white-space: nowrap;

                    &._fontBold {
                        font-weight: 900;
                    }

                    &Inner {
                        font-weight: 300;
                        font-size: 18px;

                        &._parent:not(._notMargin) {
                            margin-left: 4px;
                        }

                        &._child {
                            max-width: 320px;
                            overflow: hidden;
                            line-height: 1.3;
                            text-overflow: ellipsis;
                        }
                    }

                    &._fontBold &Inner {
                        font-weight: 900;
                    }
                }
            }

            &Edit {
                // position: relative;
                align-items: center;
                height: 23px;

                &._parent {
                    margin-left: 4px;
                }

                &Input {
                    max-width: 250px;
                    color: $colorDark2;
                    font-weight: 300;
                    font-size: 18px;
                    border-radius: 7px;
                    transition: $trans;

                    &._edit {
                        padding: 0 8px;
                        color: $colorMain;
                        font-weight: 500;
                        background: #fff;
                    }
                }

                &Action {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    z-index: 2;
                    width: 24px;
                    height: 24px;
                    margin-left: 6px;

                    & .edit {
                        background: rgba(#fff, 0.8) !important;
                        border-radius: 7px;
                        box-shadow: none !important;
                    }
                }
            }
        }

        &Description {
            margin-left: 3px;
            font-weight: 300;
            font-size: 18px;
            white-space: nowrap;

            &Inner {
                display: inline-block;
                font-weight: 300;
                font-size: 18px;
                line-height: 0.7;
            }
        }

        &Actions {
            position: relative;
            height: 30px;
            margin-left: auto;

            &Groups {
                align-items: center;
                height: 100%;
            }

            &Group {
                @extend %row;

                align-items: center;
                height: 100%;

                &._marginLeft {
                    margin-left: 12px;
                }

                &._dynamic {
                    position: relative;
                    height: 30px;
                    transition: $trans;
                }

                &:not(._empty) + &:not(._empty) {
                    margin-left: 12px;
                }
            }

            &Inner {
                &._page {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transition: $trans;

                    &:not(._show) {
                        opacity: 0;
                    }

                    &._prev:not(._show) {
                        transform: translate(-64px, 0);
                    }

                    &._next:not(._show) {
                        transform: translate(64px, 0);
                    }
                }
            }
        }

        &Action {
            position: relative;
            width: 32px;
            height: 32px;

            &._mediumHeight {
                height: 30px;
            }

            &._withChildren {
                width: auto;
            }

            &Inner {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: $colorDark2;
                font-size: 14px;
                background: #fbfcfd;
                border-radius: 10px;
                transition: $trans;

                @include button(0.97);
            }

            &._main &Inner {
                color: #fff;
                background: $colorMain;
                box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);
            }

            &._withChildren &Inner {
                @extend %row;

                align-items: center;
                padding: 0 12px;
            }

            &._grey &Inner {
                background: $colorBlueBack;
            }

            &Counter {
                position: absolute;
                right: 100%;
                bottom: 100%;
                z-index: 12;
                width: 15px;
                height: 15px;
                margin: 0 -10px -10px 0;
                background: $colorMain;
                border-radius: 50%;
                transform: scale(1.2);
                opacity: 0;
                transition: $trans;
                will-change: transform;

                @include theme("light") {
                    border-color: #fff;
                }

                @include theme("dark") {
                    border-color: $colorDarkTheme2;
                }

                &._show {
                    transform: scale(1);
                    opacity: 1;
                }

                &Inner {
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-weight: 600;
                    font-size: 11px;
                }
            }

            &Icon {
                width: 16px;
                height: 16px;

                @include icon($colorDark2);
            }

            &._withChildren &Icon {
                margin-left: 6px;
            }

            &._copy &Icon {
                width: 15px;
                height: 15px;
            }

            &._main &Icon {
                @include icon(#fff);
            }

            &._more &Icon {
                width: 20px;
                height: 20px;
            }

            &Filter {
                position: absolute;
                top: 100%;
                right: -24px;
                z-index: 2;
                width: $widthFilter;
                margin-top: 20px;
                transition: $trans;

                &:not(._show) {
                    transform: translate(0, 6px);
                    opacity: 0;
                }
            }
        }

        &ActionsInner._static &ActionsElem:not(._last) {
            margin-right: 6px;
        }

        &ActionsInner._static &ActionsElem:not(._last)._lastInGroup {
            margin-right: 12px;
        }

        &ActionsGroup._dynamic &Link {
            position: absolute;
            top: 0;
            right: 0;

            &:not(._show) {
                opacity: 0;
            }
        }

        &ActionsGroups._left &ActionsGroup._dynamic &Link {
            right: auto;
            left: 0;
        }

        &ActionsGroup._dynamic._static &Link {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &ActionsGroup._dynamic._static &ActionsElem:not(._last),
        &ActionsGroup._dynamic._static &Link:not(._last) {
            margin-right: 6px;
        }

        &ActionsGroup:not(._dynamic) &Link + &Link,
        &ActionsGroup:not(._dynamic) &ActionsElem + &ActionsElem {
            margin-left: 6px;
        }

        &Link {
            position: relative;
            height: 30px;
            transition: $trans;

            &Loader {
                background: inherit;
                border-radius: 9px;
                cursor: pointer;

                &Item {
                    width: 20px;
                    height: 20px;
                }
            }

            &._delete &Loader {
                background: $colorAlert;
            }

            &._main &Loader,
            &._mainEmpty &Loader {
                background: $colorMain;
            }

            &._withList &Inner {
                padding: 0;
                border: 0;
            }

            &Inner {
                @extend %row;

                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 4px 13px 5px;
                color: $colorDark2;
                font-weight: 500;
                font-size: 14px;
                white-space: nowrap;
                background: #fbfcfd;
                border: 1px solid transparent;
                border-radius: 9px;
                transition: $trans;

                @include button;

                &::before {
                    position: absolute;
                    top: -3px;
                    right: -3px;
                    z-index: 2;
                    width: 7px;
                    height: 7px;
                    background: $colorAlert;
                    border: 1px solid #fff;
                    border-radius: 50%;
                    opacity: 0;
                    transition: $trans;
                    content: "";
                }
            }

            &._delete &Inner {
                color: $colorAlert;
                background: none;
                border-color: $colorAlert;
            }

            &._active:not([data-current], ._complete) &Inner {
                color: #fff;
                font-weight: 600;
                background: $colorAlert;
                box-shadow: 0 2px 5px 1px rgba($colorAlert, 0.5);

                &::before {
                    opacity: 1;
                }
            }

            &[data-current] &Inner {
                color: $colorMain;
                background: #fff;
            }

            &._main &Inner {
                color: #fff;
                font-weight: 600;
                background: $colorMain;
                box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);
            }

            &._mainEmpty &Inner {
                color: $colorMain;
                font-weight: 600;
                background: none;
                border-color: $colorMain;
            }

            &._main:hover &Inner {
                background: $colorMainDark;
            }

            &._more &Inner {
                background: #fff;
                box-shadow: 0 0 2px 0 #d8dce4;
            }

            &._disabled &Inner {
                color: $colorGreyDark;
                border-color: $colorGreyDark;
            }

            &Info {
                width: 14px;
                height: 14px;
                margin-left: 8px;
                transform: translate(0, 1px);

                @include icon(rgba(#fff, 0.32));
            }

            &Counter {
                position: absolute;
                top: -8px;
                right: -4px;
                z-index: 2;
                padding: 2px 4px;
                border-radius: 4px;

                &._balance {
                    background: $colorSuccess;

                    &._minus {
                        background: $colorAlert;
                    }
                }

                &Inner {
                    color: #fff;
                    font-weight: 900;
                    font-size: 9.5px;
                    white-space: nowrap;
                    text-transform: uppercase;
                }
            }

            &More {
                display: block;
                width: 18px;
                height: 18px;
                margin: 1px -5px 0 -1px;

                @include icon($colorDark);
            }

            &[data-current] &More {
                @include icon($colorMain);
            }

            &List {
                @extend %shadow;

                position: absolute;
                top: 100%;
                right: -12px;
                z-index: 2;
                min-width: 80px;
                margin: 14px 0 0;
                padding: 10px 14px;
                background: #fff;
                border-radius: 10px;
                transition: $trans;

                &._actions {
                    padding: 8px 10px;
                }

                &:not(._show) {
                    transform: translate(0, 4px);
                    opacity: 0;
                }

                &::before,
                &::after {
                    position: absolute;
                    top: 0;
                    right: 25px;
                    width: 10px;
                    height: 10px;
                    background: #fff;
                    content: "";
                    pointer-events: none;
                }

                &::before {
                    z-index: 1;
                    transform: translate(0, -50%) rotate(45deg);

                    @extend %shadow;
                }

                &::after {
                    right: 21px;
                    z-index: 2;
                    width: 30px;
                }

                &Inner {
                    width: 100%;
                    padding: 4px 0;
                }

                &Link {
                    color: $colorDark2;
                    font-weight: 500;
                    font-size: 14px;
                    white-space: nowrap;
                    transition: $trans;

                    @include button;

                    &._actions {
                        padding: 8px 10px 9px;
                        color: #fff;
                        font-weight: 600;
                        background: $colorMain;
                        border-radius: 8px;
                        box-shadow: 0 2px 4px 0 rgba($colorMain, 0.56);
                    }

                    &[data-current] {
                        color: $colorMain;
                    }

                    & + & {
                        margin-top: 12px;
                    }
                }
            }

            &:not(:hover) &Alert {
                transform: translate(0, 6px);
                opacity: 0;
                pointer-events: none;
            }

            &Alert {
                position: absolute;
                top: 100%;
                right: -24px;
                z-index: 10;
                width: 216px;
                margin-top: 16px;
                transition: $trans;
            }

            &Content {
            }

            &._main &Content {
                font-weight: 600;
            }

            &._withList &Content {
                padding: 4px 13px 5px;
            }

            &:hover &Icon::after {
                margin-top: 1px;
                transform: translate(-50%, -50%) rotate(-135deg);
            }

            &Icon {
                position: relative;
                width: 32px;
                height: 100%;
                border-left: 1px solid rgba(#fff, 0.5);

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    width: 14px;
                    height: 14px;
                    background: rgba(#fff, 0.08);
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    content: "";
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 2;
                    margin-top: -1px;
                    border: 2px solid transparent;
                    border-right-color: #fff;
                    border-bottom-color: #fff;
                    transform: translate(-50%, -50%) rotate(45deg);
                    transition: $trans;
                    content: "";
                }
            }

            &:not(:hover) &Actions {
                pointer-events: none;

                &Inner {
                    opacity: 0;
                }
            }

            &Actions {
                position: absolute;
                top: 100%;
                right: 0;
                z-index: 10;
                padding-top: 6px;

                &._active {
                    pointer-events: visible !important;
                }

                &._active &Inner {
                    opacity: 1 !important;
                }

                &Inner {
                    @extend %shadow;

                    z-index: 1;
                    overflow: hidden;
                    background: rgba(#fff, 0.72);
                    border-radius: 12px;
                    transition: $trans;

                    @include blur(10px);
                }

                &Item {
                    position: relative;
                    max-width: 100%;

                    &Loader {
                        background: #fff;

                        &Item {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    & + & {
                        border-top: 1px solid $colorBlueBack;
                    }

                    &Inner {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 12px 16px 12px 36px;
                        color: $colorDark;
                        font-size: 15px;
                        white-space: nowrap;
                        transition: $trans;

                        @include button;
                    }

                    &Icon {
                        position: absolute;
                        top: 50%;
                        left: 14px;
                        width: 13px;
                        height: 13px;
                        transform: translate(0, -50%);

                        &._file {
                            left: 11px;
                            width: 18px;
                            height: 18px;
                        }

                        &Inner {
                            display: block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }

        &Search {
            width: 200px;

            &._article {
                width: 340px;
            }

            &._articleInner {
                width: 200px;
            }
        }

        &Counter {
            position: absolute;
            top: -5px;
            right: -5px;
            z-index: 2;
            min-width: 16px;
            height: 16px;
        }
    }

    &._white &__headActionInner {
        background: $colorBlueBack;
    }

    &._white &__headInner,
    &._white &__content {
        background: #fff;
    }

    &._ready &__headNameItem {
        opacity: 1;
    }

    &__content._full &__loader {
        height: 100%;
    }

    &._windowFull &__content {
        height: calc(100vh - #{$heightWidgetHead} - #{$paddingContent});
        border-radius: 0;

        &Inner {
            height: 100%;
            max-height: 100% !important;
        }
    }

    &__content {
        position: relative;
        z-index: 10;
        width: 100%;
        overflow: hidden;
        background: $colorBlueBack;
        border-radius: 0 0 24px 24px;
        transition: $trans;

        &._windowFull {
            border-radius: 0;
        }

        &._inner {
            overflow: visible;
            border-radius: 0;
        }

        &._notHidden {
            overflow: visible;
        }

        &._full {
            height: calc(100vh - #{$heightWidgetHead} - #{$paddingContent} * 2);
        }

        &Inner {
            position: relative;
            width: 100%;
            height: 100%;

            &._parentForce._main {
                max-height: calc(100vh - #{$paddingContent} * 2 - #{$heightWidgetHead});
            }
        }

        &._scroll &Inner {
            position: relative;
            max-height: calc(100vh - #{$paddingContent} * 2 - #{$heightWidgetHead});
            overflow-y: auto;

            @extend %noscroll;
        }

        &Box {
            width: 100%;
            transition: $trans;

            &._padding {
                padding: var(--paddingWidget);
            }
        }

        &._full &Box {
            height: 100%;
        }
    }

    &:not(._ready) &__content {
        &Box {
            opacity: 0;
        }
    }

    &__page {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        transition: $trans;
        will-change: transform;

        &._full {
            height: 100%;
        }

        &:not(._show) {
            opacity: 0;
        }

        &._prev:not(._show) {
            transform: translate(-64px, 0);
        }

        &._next:not(._show) {
            transform: translate(64px, 0);
        }

        &._wide {
            width: calc(100% + 128px);
            margin-left: -64px;
        }

        &Inner {
            width: 100%;
            padding: var(--paddingWidget);

            &._notPadding {
                padding: 0;
            }
        }

        &._full &Inner {
            height: 100%;
        }

        &Box {
            width: 100%;

            &._scroll {
                @extend %noscroll;

                position: relative;
                max-height: calc(100vh - #{$paddingContent} * 2 - #{$heightWidgetHead});
                overflow-y: auto;
            }
        }

        &._full &Box {
            height: 100%;
        }
    }

    &__blocks {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }

        &Inner {
            width: 100%;
            height: 100%;
        }
    }

    &__filterCounter {
        position: absolute;
        top: -4px;
        right: -4px;
        z-index: 2;
        min-width: 18px;
        height: 18px;

        &._child {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 2px 4px;
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            text-align: center;
            background: $colorMain;
            border: 2px solid #fff;
            border-radius: 360px;
        }
    }

    &._white &__filterCounter._child {
        border-color: $colorBlueBack;
    }
}
