.publicMobileMenu {
    @extend %noscroll;

    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background: #fff;

    &__inner {
        align-items: center;
        width: 100%;
        min-height: 100%;
        padding: 2.5rem 3rem 3rem;
    }

    &__head {
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 5rem;
    }

    &__logo {
        height: 5rem;
        object-fit: contain;
    }

    ._design-newYear &__logo {
        @include mob {
            height: 5.5rem;
        }
    }

    &__close {
        width: 3.5rem;
        height: 3.5rem;
    }

    &__content {
        width: 95%;
        margin: auto 0;
        padding-bottom: 3rem;
    }

    &__nav {
        width: 100%;

        &Item {
            width: 100%;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(#75808b, 0.24);
            }

            & + & {
                margin-top: 3rem;
            }
        }

        &Link {
            position: relative;
            display: block;
            margin: 0 auto;
            padding-bottom: 3rem;
            color: $colorDark2;
            font-size: 2.5rem;
            line-height: 1.3;
            text-align: center;

            &::after {
                position: absolute;
                bottom: -1px;
                left: 50%;
                width: 100%;
                height: 3px;
                background: $colorMain;
                border-radius: 360px 360px 0 0;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: $trans;
                content: "";
            }
        }

        &Item._current &Link {
            font-weight: 600;

            &::after {
                opacity: 1;
            }
        }
    }

    &__call {
        align-items: center;
        margin-top: 5rem;
        padding: 2rem 3rem;
        color: $colorMain;
        font-weight: 600;
        font-size: 2.3rem;
        background: #e9efff;
        border-radius: 36rem;

        @include button(0.96);

        &Icon {
            width: 2.3rem;
            height: 2.3rem;
            margin-right: 1.5rem;
        }
    }

    &__copy {
        margin: 0 auto 2rem;
        color: #75808b;
        font-weight: 500;
        font-size: 2rem;
    }
}
