.publicBusinessHeader {
    position: relative;
    width: 100%;
    height: calc(100vh - 13.5rem);
    padding: 12rem 0;

    @include mob {
        z-index: 1;
        height: auto;
        overflow: hidden;
    }

    &__inner {
        position: relative;
        justify-content: center;
        height: 100%;
        padding-bottom: 18rem;

        @include mob {
            padding-bottom: 42rem;
        }
    }

    &__title {
        position: relative;
        z-index: 4;
        width: 50rem;
        color: $colorDark2;
        font-weight: 300;
        font-size: 5.2rem;
        line-height: 1.2;

        @include mob {
            width: 95%;
            margin: 0 auto;
            font-size: 4.4rem;
            line-height: 1.3;
            text-align: center;
        }

        & b {
            color: $colorDark2;
            font-weight: 600;

            @include mob {
                font-size: 4.4rem;
            }
        }
    }

    &__button {
        position: relative;
        z-index: 3;
        margin-top: 3rem;

        @include mob {
            margin: 3rem auto 0;
        }
    }

    &__more {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-left: 3.6rem;
        color: $colorDark2;
        font-weight: 300;
        font-size: 1.35rem;
        line-height: 1.25;

        @include media(0, $mediaM) {
            display: none;
        }

        &Icon {
            position: absolute;
            top: 50%;
            left: -0.5rem;
            width: 3.5rem;
            height: 3.5rem;
            object-fit: contain;
            object-position: left center;
            transform: translate(0, -50%);
        }
    }

    &__board {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        width: 105rem;
        height: 100%;
        padding-top: 5rem;
        pointer-events: none;

        @include mob {
            inset: auto auto 0 0;
            width: 100%;
            height: 60rem;
        }

        &::before {
            position: absolute;
            top: -10rem;
            right: 2rem;
            z-index: -1;
            width: 100%;
            height: 26rem;
            content: "";

            @include bg("../../../../img/crm/public/business/header-top-circle.svg", contain, right top);

            @include mob {
                top: -25rem;
                right: 0;
                width: 80rem;
                height: 36rem;
            }
        }

        &::after {
            position: absolute;
            right: 16rem;
            bottom: 0;
            z-index: -1;
            width: 93rem;
            height: 93rem;
            transform: translate(0, 50%);
            content: "";

            @include bg("../../../../img/crm/public/business/header-bottom-circle.svg", contain, right bottom);

            @include mob {
                bottom: 5rem;
                width: 75rem;
                height: 75rem;
            }
        }

        &Image {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right center;
        }

        &Back {
            position: absolute;
            top: -9rem;
            right: 0;
            z-index: -2;
            width: 126rem;
            height: calc(100% + 13.5rem);
            object-fit: contain;
            object-position: right top;

            @include mob {
                top: -35rem;
                width: 100%;
                height: 130rem;
                object-position: center top;
            }
        }

        &Card {
            position: absolute;
            top: 50%;
            z-index: 3;
            padding: 2rem 3rem 2rem 6.5rem;
            background: #fff;
            border-radius: 14rem 36rem 36rem 14rem;
            box-shadow: 0 1.6rem 4rem rgba(#748194, 0.2);
            transform: translate(0, -50%);
            pointer-events: visible;

            &._1 {
                left: 7rem;
                margin-top: 8rem;

                @include mob {
                    left: 2rem;
                    margin-top: 1rem;
                }
            }

            &._2 {
                left: 35rem;
                margin-top: -30rem;

                @include mob {
                    right: 2rem;
                    left: auto;
                    margin-top: -13rem;
                }
            }

            &._3 {
                right: 19rem;
                margin-top: 20rem;

                @include mob {
                    right: 4rem;
                    margin-top: 10rem;
                }
            }

            &Icon {
                position: absolute;
                top: 2rem;
                left: 2.3rem;
                width: 2.8rem;
                height: 2.8rem;
                object-fit: contain;
            }

            &Title {
                color: $colorDark2;
                font-weight: 500;
                font-size: 2.2rem;
                line-height: 1.3;

                & b {
                    font-weight: 600;
                }
            }
        }
    }
}
