.chatWindowHead {
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 6px 14px;
    border-bottom: 1px solid #ebebeb;

    &__info {
        position: relative;
        height: 100%;

        &TypeIcon {
            position: absolute;
            bottom: -1px;
            left: -5px;
            z-index: 2;
            width: 17px;
            height: 17px;
            background: #fff;
            border: 1px solid #fff;
            border-radius: 50%;

            &Img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &Item {
            height: 100%;
        }

        &Inner {
            align-items: center;
            height: 100%;
        }

        &Preview {
            position: relative;
            z-index: 1;
            width: 36px;
            height: 36px;
            overflow: hidden;
            background: $colorBlueBack;
            border-radius: 50%;

            &._link {
                transition: $trans;

                @include button(0.95);
            }

            & .avatar__item {
                color: #d0d6de;
                font-weight: 600;
                font-size: 16px;
            }
        }

        &Content {
            width: calc(100% - 36px);
            padding-left: 10px;
        }

        &Name {
            position: relative;
            align-items: center;
            margin-bottom: 3px;

            &Inner {
                position: relative;
                display: inline-block;
                max-width: 230px;
                overflow: hidden;
                color: $colorMain;
                font-weight: 900;
                font-size: 14px;
                line-height: 1.3;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &Icon {
                display: inline-block;
                width: 15px;
                height: 15px;
                margin-left: 6px;

                @include icon($colorGreyDark);

                &._joins {
                    transform: translate(0, 1px);
                }
            }
        }

        &Edit {
            position: absolute;
            top: 50%;
            left: 100%;
            z-index: 2;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            margin-left: 6px;
            background: $colorBlueBack;
            border-radius: 7px;
            transform: translate(0, -50%);
            transition: $trans;

            &:active {
                transform: translate(0, -50%) scale(0.95);
            }

            &Icon {
                width: 10px;
                height: 10px;

                @include icon($colorGreyDark);
            }
        }

        &Description {
            max-width: 100%;
            color: $colorDark;
            font-weight: 500;
            font-size: 13px;
            white-space: nowrap;

            & span {
                color: $colorGreyDark;
            }
        }

        &Corporation {
            position: relative;
            align-items: center;
            height: 14px;
            margin-left: 16px;
            transition: $trans;

            @include button(0.97);

            &::before {
                position: absolute;
                top: 50%;
                left: -10px;
                color: $colorGreyDark;
                font-size: 13px;
                transform: translate(0, -50%);
                content: "•";
            }
        }
    }

    &__actions {
        position: relative;
        width: 0;
        height: 32px;
        margin-left: auto;
        transition: $trans;

        &._static {
            display: inline-flex;
            flex-direction: row;
            width: auto;
        }

        &Item {
            right: 0;
            left: auto;
            width: 32px !important;
            height: 32px !important;
        }
    }

    &__actions._static &__actionsItem:not(._last) {
        margin-right: 6px;
    }

    &__action {
        width: 100%;
        height: 100%;
        transition: $trans;

        &Inner {
            position: relative;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background: $colorBlueBack;
            border-radius: 10px;
            transition: $trans;

            @include button(0.95);
        }

        &._close &Inner {
            background: $colorMain;
            box-shadow: 0 2px 4px 0 rgb(62 106 237 / 56%);
        }

        &._setResponsibleSelf &Inner {
            background: #eff3ff;
        }

        &Icon {
            width: 18px;
            height: 18px;

            @include icon($colorDark);
        }

        &._setResponsibleSelf &Icon {
            width: 15px;
            height: 15px;

            @include icon($colorMain);
        }

        &Loader {
            background: inherit;
            border-radius: 10px;

            & ._LOADERITEM {
                width: 18px;
                height: 18px;
            }
        }

        &._close {
            @include icon(#fff);
        }

        &._close &Loader .loader__itemInner {
            background: #fff !important;
        }
    }
}
