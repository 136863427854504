.v2tag {
    width: 100%;
    height: 100%;
    padding: 2px 5px;
    overflow: hidden;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.3;
    white-space: nowrap;
    text-transform: uppercase;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    border-radius: 5px;

    &._full {
        font-weight: 900;
    }
}
