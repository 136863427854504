.v2file {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6px;
    background: $colorBlueBack;
    border-radius: 11px;

    &__delete {
        position: absolute;
        top: -5px;
        right: -5px;
        z-index: 3;
        display: flex;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 0 3px 0 rgb(173 183 197 / 56%);
        transition: $trans;

        &::before {
            width: 8px;
            height: 1px;
            margin: auto;
            background: $colorAlert;
            content: "";
        }
    }

    &:not(:hover) &__delete {
        opacity: 0;
    }

    &__inner {
        align-items: center;
    }

    &__preview {
        position: relative;
        z-index: 1;
        width: 28px;
        height: 28px;
        overflow: hidden;
        background: #fff;
        border-radius: 8px;

        & .v2lazyImage__previewIcon {
            width: 16px;
            height: 16px;
        }
    }

    &__info {
        max-width: 100px;
        padding: 0 3px 0 6px;
    }

    &__name {
        max-width: 100%;
        overflow: hidden;
        color: $colorDark2;
        font-size: 13px;
        line-height: 1.4;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__size {
        color: $colorGreyDark;
        font-size: 12px;
    }
}
