.v2notificationCount {
    width: 100%;
    height: 100%;

    &._shake {
        animation: shake 1.2s infinite linear;
    }

    &__inner {
        align-items: center;
        justify-content: center;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        margin: -4px 0 0;
        padding-top: 4px;
        background: #fff;
        border-radius: 0 0 0 20px;
        box-shadow: 0 6px 24px rgb(0 0 0 / 10%);
        transition: $trans;

        @include button(0.98);
    }

    &._shake &__inner {
        background: $colorAlert;
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin: 0 0 4px 4px;

        @include icon(rgba(#3e454c, 0.4));
    }

    &._shake &__icon {
        @include icon(#fff);
    }

    &__counter {
        height: 16px;
        color: rgba(#3e454c, 0.4);
        font-weight: 600;
        font-size: 16px;
        text-align: center;
    }

    &._shake &__counter {
        color: #fff;
    }
}
