.public {
    width: 100%;
    height: 100%;
    background: #fcfdff;

    &__top {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;
    }

    &__mobileMenu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 102;
        width: 100%;
        height: 100%;
        transition: $trans;

        &:not(._show) {
            transform: translate(-100vw, 0);
            pointer-events: none;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__page {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: #fcfdff;
        transition: $trans;

        @include media(0, $mediaM) {
            background: #fff;
        }

        &:not(._show) {
            opacity: 0;
            pointer-events: none;

            & * {
                pointer-events: none;
            }
        }

        &Box {
            width: 100%;
            height: 100%;
            padding-top: 13.5rem;
            overflow-y: auto;

            @extend %noscroll;

            &._full {
                padding-top: 0;
                overflow: visible;
            }

            @include media(0, $mediaM) {
                padding-top: 0;
            }
        }

        &Inner {
            width: 100%;
            min-height: 100%;
            background: #fcfdff;

            &._full {
                height: 100%;
                min-height: auto;
            }
        }
    }

    &__legalNav {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 101;
        width: 100%;
        height: 100%;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.5);
            transition: $trans;
            content: "";
        }

        &:not(._show)::before {
            opacity: 0;
        }

        &Content {
            margin-top: auto;
            transition: $trans;
        }

        &:not(._show) &Content {
            transform: translate(0, 100%);
        }
    }

    &__section {
        width: 100%;

        &._footer {
            position: relative;
            z-index: 2;
            margin-top: auto;
        }

        &._full {
            height: 100%;
        }
    }

    &__innerPages {
        position: relative;
        width: 100%;
        transition: $trans;
    }

    &__section._full &__innerPages {
        height: 100%;
    }

    &__section._full &__innerPage {
        @extend %noscroll;

        height: 100%;
        overflow-y: auto;
    }

    &__innerPage {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 13.5rem;
        transition: $trans;

        @include media(0, $mediaM) {
            padding-top: 8rem;
        }

        &:not(._show) {
            opacity: 0;
        }
    }

    &__innerPages._static &__innerPage {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__callPopup {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        will-change: transform;

        &:not(._show) {
            transform: scale(1.03);
            pointer-events: none;
        }
    }
}
