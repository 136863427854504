.manualWallets {
    width: 100%;

    &__cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        transition: $trans;
        margin-bottom: -8px;
    }

    &__cards._static &__card {
        position: relative;

        &._show {
            margin-bottom: 8px;
        }

        &:not(._show) {
            display: none;
        }
    }

    &__card {
        width: 100%;
        border-radius: 16px;
        background: $colorBlueBack;
        padding: 24px;
        transition: $trans;
        border: 1px solid transparent;
        position: absolute;
        top: 0;
        left: 0;

        &:not(._show) {
            opacity: 0;
        }

        &._current {
            background: $colorBlueActive;
            border-color: rgba($colorMain, .5);
        }

        &._card {
            padding: 18px 24px;
        }

        &Inner {
            width: 100%;
            position: relative;
        }

        &Items {
            width: 100%;
            flex-wrap: wrap;
            align-items: center;
        }

        &Item {
            &._bankName {
                width: 100%;
                margin-top: 26px;
            }

            &:not(._bankName):not(:first-child) {
                margin-left: 24px;
                padding-left: 24px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    width: 1px;
                    height: 24px;
                    background: #C8D1DD;
                }
            }

            &._current {
                transition: $trans;
            }
        }

        &:not(._current) &Item._current {
            opacity: 0;
            pointer-events: none;
        }

        &Support {
            max-width: 100%;
            font-size: 13px;
            color: $colorGreyDark;
            font-weight: 600;
            margin-bottom: 6px;
        }

        &Value {
            max-width: 100%;
            font-size: 15px;
            color: $colorDark2;
        }

        &Item._current &Value {
            font-weight: 600;
            position: relative;
            padding-left: 12px;

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $colorMain;
            }
        }

        &Item._short &Value {
            color: #fff;
            padding: 5px 7px 5px 7px;
            border-radius: 7px;
            font-size: 13px;
            background: #3E454C;
            color: #fff;
        }

        &Actions {
            position: absolute;
            bottom: 2px;
            right: 0;
        }

        &Action {
            width: 22px;
            height: 22px;
            @include icon(#c6cbd4);

            &+& {
                margin-left: 6px;
            }
        }
    }
}