.v2widgetVerificationCard {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    &__icon {
        width: 26px;
        height: 26px;
        margin-bottom: 6px;
    }

    &._wait &__icon {
        @include icon($colorBlue);
    }

    &._reject &__icon {
        @include icon($colorAlert);
    }

    &__title {
        width: 100%;
        color: $colorDark2;
        font-weight: 600;
        font-size: 17px;
        line-height: 1.2;
        white-space: nowrap;
        text-align: center;

        &._parent {
            margin-bottom: 6px;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: $colorGreyDark;
        font-size: 14px;
        line-height: 1.2;
        white-space: nowrap;
        text-align: center;
        transition: $trans;

        &._message {
            display: block;
            color: $colorGreyDark !important;
            font-weight: 500 !important;
            white-space: normal;

            &._parent:not(._empty) {
                margin-top: 6px;
            }

            & span {
                color: $colorDark2;
            }
        }
    }

    &._reject &__description {
        color: $colorAlert;
        font-weight: 600;
    }

    &__links {
        width: 100%;
        transition: $trans;

        &:not(._empty) {
            margin-top: 12px;
        }

        &._static {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__link {
        height: 30px;
    }

    &__links._static &__link._show + &__link._show {
        margin-top: 6px;
    }
}
