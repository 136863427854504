.manual {
    position: relative;
    width: 100%;
    height: 100%;

    &__page {
        @extend %noscroll;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        // width: 990px;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-64px, 0);
            }

            &._next {
                transform: translate(64px, 0);
            }
        }
    }
}
