.publicExecutorsAbout {
    @extend %section;

    width: 100%;
    padding: 15rem 0;

    @include media(0, $mediaM) {
        padding: 5rem 0 4rem;
    }

    &__inner {
        @extend %publicInner;

        width: calc(#{$widthPublicInner} + 10rem);

        @include media(0, $mediaM) {
            width: calc(92vw + 3rem);
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        align-items: center;
        width: 100%;
        padding: 13rem 21rem;

        @include media(0, $mediaM) {
            padding: 8rem 2rem;
        }

        // &::before,
        // &::after {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     z-index: -3;
        //     width: calc(50% - 1.5rem);
        //     height: 100%;
        //     border-radius: 4rem 0 0 4rem;
        //     box-shadow: 0 1rem 3.2rem 0 rgb(0 0 0 / 6%);
        //     content: "";

        //     @include media(0, $mediaM) {
        //         width: calc(50% - 2.5rem);
        //     }
        // }

        // &::after {
        //     right: 0;
        //     left: auto;
        //     border-radius: 0 4rem 4rem 0;
        // }

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: #e9f1ff;
            background-repeat: repeat-y;
            border: 0.4rem solid #fff;
            border-radius: 4rem;
            mask:
                radial-gradient(circle 1.5rem at top center, #0000 99%, #000) top center,
                radial-gradient(circle 1.5rem at bottom center, #0000 99%, #000) bottom center;
            mask-size: 100% 50%;
            mask-repeat: no-repeat;

            @include bg("/img/crm/public/executors-about-back.png", 100% auto, center top);

            @include media(0, $mediaM) {
                background: none;
                background-color: #e9f1ff;
                border: none;
                mask:
                    radial-gradient(circle 2.5rem at top center, #0000 99%, #000) top center,
                    radial-gradient(circle 2.5rem at bottom center, #0000 99%, #000) bottom center;
                mask-size: 100% 50%;
                mask-repeat: no-repeat;
            }

            &::before,
            &::after {
                position: absolute;
                top: -0.4rem;
                left: 50%;
                z-index: 1;
                width: 3.7rem;
                height: 3.7rem;
                background: #fff;
                border-radius: 50%;
                transform: translate(-50%, -50%);
                content: "";

                @include media(0, $mediaM) {
                    display: none;
                }
            }

            &::after {
                top: auto;
                bottom: -0.4rem;
                transform: translate(-50%, 50%);
            }
        }
    }

    &__head {
        align-items: center;
        width: 55rem;
        margin-bottom: 8rem;

        @include media(0, $mediaM) {
            width: 100%;
            margin-bottom: 5rem;
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 1.5rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 3.7rem;
        text-align: center;

        @include media(0, $mediaM) {
            font-size: 4rem;

            // font-weight: 700;
        }
    }

    &__description {
        max-width: 100%;
        margin-bottom: 2.5rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 1.85rem;
        line-height: 1.6;
        text-align: center;

        @include media(0, $mediaM) {
            max-width: 95%;
            font-weight: 500;
            font-size: 2.3rem;
            line-height: 1.4;
        }
    }

    &__button {
        @include media(0, $mediaM) {
            width: 95%;
        }
    }

    &__cards {
        @extend %noscroll;

        width: calc(100% + 2.5rem);
        margin-top: 3rem;

        @include media(0, $mediaM) {
            width: calc(100% + 8vw);
            margin-top: 0;
            overflow: hidden;
            overflow-x: auto;
        }

        &Inner {
            flex-wrap: wrap;
            align-items: stretch;
            width: auto;

            @include media(0, $mediaM) {
                display: inline-flex;
                flex-wrap: nowrap;
                padding: 0 2vw;
            }
        }
    }

    &__card {
        width: calc(100% / 3);
        margin-bottom: 2rem;
        padding: 0 1.25rem;

        @include media(0, $mediaM) {
            width: 88vw;
        }

        &Inner {
            width: 100%;
            height: 100%;
            padding: 6rem 4.8rem;
            background: #fff;
            border-radius: 3.3rem;
            transition: 0.3s cubic-bezier(0.35, 1.57, 0.5, 1.59);
            will-change: transform;

            @include media(0, $mediaM) {
                padding: 6rem 3.5rem 6rem 4.8rem;
                border-radius: 5rem;
            }

            &:hover {
                transform: scale(1.03);

                @include media(0, $mediaM) {
                    transform: none;
                }
            }
        }

        &Icon {
            position: relative;
            z-index: 1;
            width: 3.8rem;
            height: 3.8rem;
            margin-bottom: 2.5rem;

            @include media(0, $mediaM) {
                width: 5.5rem;
                height: 5.5rem;
                margin-bottom: 3.5rem;
            }

            &Item {
                width: 100%;
                height: 100%;

                &._shadow {
                    position: absolute;
                    top: 1rem;
                    left: 1.5rem;
                    z-index: -1;
                    opacity: 0.1;

                    @include media(0, $mediaM) {
                        top: 1.5rem;
                        left: 2.5rem;
                    }
                }
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 1rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.3;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }

        &Description {
            max-width: 100%;
            color: $colorDark2;
            font-size: 1.5rem;
            line-height: 1.4;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }
        }
    }
}
