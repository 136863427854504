.publicTop {
    width: 100%;
    height: 100%;
    background: #fcfdff;

    @extend %section;

    @include mob {
        background: none;
    }

    &:not(._footer) {
        height: 11.5rem;
        transition: $trans;

        @include mob {
            height: 10rem;
        }

        &._scrollActive {
            height: 9rem;

            @include mob {
                background: #fcfdff;
            }
        }
    }

    &._footer {
        height: auto;
        background: #3e454c;
        mask: radial-gradient(circle 1.5rem at top center, #0000 99%, #000) top center;
        mask-size: 100% 100%;
        mask-repeat: no-repeat;

        @include mob {
            mask: radial-gradient(circle 2.5rem at top center, #0000 99%, #000) top center;
            mask-size: 100% 100%;
            mask-repeat: no-repeat;
        }
    }

    &:not(._footer) &__inner {
        @include mob {
            width: 100%;
        }
    }

    &__inner {
        align-items: center;
        justify-content: space-between;
        width: $widthPublicWideInner;
        height: 100%;

        // padding-top: 1.7rem;
        border-bottom: 1px solid rgba(#75808b, 0.2);
        transition: $trans;

        @include mob {
            width: calc(92vw + 3rem);
            padding-top: 0;
            border-bottom-color: transparent;
        }

        &._top {
            height: auto;
            padding: 7rem 0 4.5rem;
            border-bottom: 1px solid rgba(#75808b, 1);

            @include mob {
                padding-bottom: 2rem;
                border-bottom: none;
            }
        }

        &._main {
            @include mob {
                padding-right: 3rem;
                padding-left: 3rem;
            }
        }
    }

    &:not(._footer)._scrollActive &__inner {
        padding-top: 0;
        border-bottom-color: rgba(#75808b, 0.2);
    }

    &._footer &__inner._main {
        padding: 4rem 0;
        border-bottom: 0;

        @include mob {
            padding: 4rem 3rem;
        }
    }

    &__logo {
        width: 15rem;
        height: 5.5rem;

        // margin-top: -1.7rem;
        transform-origin: left center;
        transition: $trans;
        will-change: transform;

        @include mob {
            height: 5rem;
            margin-top: 0;
        }

        &Link {
            height: 100%;
        }

        &Icon {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left center;
        }
    }

    ._design-newYear &:not(._footer) &__logo {
        width: 23rem;

        @include mob {
            width: auto;
            height: 5.5rem;
        }
    }

    &:not(._footer)._scrollActive &__logo {
        margin-top: 0;
        transform: scale(0.75);

        @include mob {
            transform: none;
        }
    }

    &._footer &__logo {
        height: 3.5rem;
        margin-top: 0;

        @include mob {
            height: 5rem;
        }
    }

    ._design-newYear &:not(._footer) &__navItem {
        margin: 0 1.5rem;
    }

    &__nav {
        height: 100%;

        @include mob {
            display: none;
        }

        &Item {
            position: relative;
            justify-content: center;
            height: 100%;
            margin: 0 2.5rem;

            &::after {
                position: absolute;
                bottom: -1px;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 2px;
                background: $colorMain;
                border-radius: 360px 360px 0 0;
                transition: $trans;
                content: "";
            }

            &:not(._current)::after {
                opacity: 0;
            }
        }

        &Link {
            color: $colorDark2;
            font-size: 1.7rem;
            transition: $trans;

            @include button(0.97);

            &:hover {
                color: $colorMain;
            }
        }

        &Item._current &Link {
            font-weight: 600;
        }
    }

    &._footer &__nav {
        &Item {
            &::after {
                display: none;
            }
        }

        &Link {
            color: #fff;
            font-weight: 500 !important;

            &:hover {
                color: #8fabff;
            }
        }
    }

    &__actions {
        align-items: center;
    }

    &__call {
        align-items: center;
        margin-right: 3rem;
        padding: 1.5rem 2.2rem;
        color: $colorMain;
        font-weight: 600;
        font-size: 1.7rem;
        background: #e9efff;
        border-radius: 36rem;

        @include button(0.96);

        &Icon {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 1rem;
        }
    }

    &__copy {
        color: $colorDark2;
        font-weight: 300;
        font-size: 1.5rem;

        @include mob {
            font-size: 2.1rem;
        }
    }

    &:not(._footer) &__actions {
        @include mob {
            display: none;
        }
    }

    &._footer &__copy {
        color: #fff;
    }

    &__executorsFooter {
        align-items: stretch;
        width: 100%;

        @include mob {
            flex-direction: column;
            align-items: center;
        }

        &Block {
            @include mob {
                align-items: center;
                width: 100%;
            }

            &:not(._empty) {
                padding: 3.6rem;
                background: #2c3238;
                border-radius: 2.5rem;

                @include mob {
                    margin-bottom: 1.5rem;
                    padding: 3.2rem;
                }
            }

            &._empty {
                margin: 0 auto 0 3rem;
                padding-top: 1.7rem;

                @include mob {
                    order: 3;
                    margin: 4rem 0 0;
                    padding-top: 0;
                }
            }

            &._partners {
                @include mob {
                    padding-top: 5rem;
                    padding-bottom: 5rem;
                }
            }
        }

        &Support {
            max-width: 100%;
            margin-bottom: 2rem;
            color: #fff;
            font-weight: 600;
            font-size: 2rem;

            @include mob {
                font-size: 2.7rem;
                line-height: 1.4;
                text-align: center;
            }
        }

        &Links {
            flex-wrap: wrap;
            margin-top: 0.5rem;
            margin-bottom: -1.5rem;
            padding-right: 4rem;

            @include mob {
                justify-content: center;

                // align-items: center;
                padding: 0;

                // margin-bottom: 0;
            }
        }

        &Link {
            margin: 0 1.5rem 1.5rem 0;
            color: #fff;
            font-size: 1.5rem;
            line-height: 1.2;
            border-bottom: 1px solid rgba(#8fabff, 0.72);
            transition: $trans;

            @include button(0.98);

            @include mob {
                margin: 0 1.5rem 2.5rem;
                font-size: 2rem;
                line-height: 1.4;
                text-align: center;
            }

            &:hover {
                color: #8fabff;
            }

            & + & {
                // margin-top: 1.3rem;
                @include mob {
                    // margin-top: 3rem;
                }
            }
        }

        &Buttons {
            flex-wrap: wrap;
            align-items: center;
            width: 35rem;
            margin-bottom: -1.5rem;

            @include mob {
                justify-content: center;
                width: calc(100% + 2rem);
                margin-bottom: 0;
                margin-left: -1rem;
            }
        }

        &Button {
            margin: 0 1.5rem 1.5rem 0;

            @include mob {
                margin: 0.5rem;
            }
        }

        &Logos {
            align-items: center;
            width: 100%;
            margin-top: auto;

            @include mob {
                flex-direction: column;
                margin-top: 1rem;
            }
        }

        &Logo {
            height: 4.5rem;

            @include mob {
                height: 7rem;
            }

            & + & {
                margin-left: 2.5rem;

                @include mob {
                    margin-top: 2rem;
                    margin-left: 0;
                }
            }
        }
    }

    &__menu {
        width: 4rem;
        height: 4rem;
        margin-left: auto;
    }
}
