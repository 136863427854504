.publicLegalHeader {
    @extend %section;

    width: 100%;
    height: 100%;
    padding-top: 9rem;

    @include media(0, $mediaM) {
        padding-top: 10rem;
    }

    &__inner {
        position: relative;
        justify-content: space-between;
        width: 152rem;

        @include media(0, $mediaM) {
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            padding: 0 3rem;
        }
    }

    &__block {
        position: relative;

        &._nav {
            position: sticky;
            top: 0;
            width: 35.5rem;

            @include media(0, $mediaM) {
                display: none;
            }
        }

        &._content {
            width: calc(100% - 35.5rem - 26rem);
            padding: 0 4rem 0 7rem;

            @include media(0, $mediaM) {
                width: 100%;
                padding: 0;
            }
        }

        &._info {
            position: sticky;
            top: 0;
            width: 26rem;

            @include media(0, $mediaM) {
                display: none;
            }
        }
    }

    &__button {
        position: absolute;
        top: 1.2rem;
        right: 0;
        align-items: center;
        padding: 1.7rem 2.5rem;
        color: $colorMain;
        font-size: 1.7rem;
        white-space: nowrap;
        background: #eff3ff;
        border-radius: 1.4rem;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            transform: scale(1.1);
            opacity: 0;
        }

        &Icon {
            width: 2rem;
            height: 2rem;
            margin: 0.2rem 0 0 0.5rem;
        }
    }

    &__nav {
        position: absolute;
        top: 13rem;
        left: 0;
        z-index: 2;
        width: 35.5rem;
        transition: $trans;

        @include media(0, $mediaM) {
            position: relative;
            top: 0;
            width: 100%;
        }

        &._small {
            top: 4rem;

            @include media(0, $mediaM) {
                top: 0;
            }
        }
    }

    &__pages {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: $trans;
    }

    &__page {
        @extend %section;
        @extend %noscroll;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        transition: $trans;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-6rem, 0);
            }

            &._next {
                transform: translate(6rem, 0);
            }
        }

        &Wrapper {
            position: relative;
            width: 100%;
        }

        &Inner {
            width: 152rem;
            padding: 15rem 0 0 42rem;

            @include media(0, $mediaM) {
                width: 100%;
                padding: 3rem 3rem 0;
            }
        }

        &Loader {
            width: 100%;
            height: 50rem;

            &Item {
                width: 4rem;
                height: 4rem;
            }
        }

        &Box {
            width: 100%;
            transition: $trans;

            &:not(._ready) {
                opacity: 0;
            }
        }

        &Head {
            width: 100%;
            margin-bottom: 4rem;
        }

        &Title {
            max-width: 70%;
            margin-bottom: 1.3rem;
            color: $colorDark2;
            font-weight: 600;
            font-size: 4rem;
            line-height: 1.3;

            @include media(0, $mediaM) {
                max-width: 100%;
            }
        }

        &Description {
            max-width: 100%;
            color: #75808b;
            font-size: 1.7rem;
            line-height: 1.3;

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }

            & span {
                font-weight: 600;
            }
        }

        &Content {
            @extend %editorContent;

            padding-bottom: 15rem;

            @include media(0, $mediaM) {
                padding-bottom: 7rem;
            }

            &,
            & * {
                font-size: 1.9rem;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                }
            }

            & h2,
            & h2 * {
                font-weight: 600;
                font-size: 3.1rem;

                @include media(0, $mediaM) {
                    font-size: 3.2rem;
                }
            }

            & h3,
            & h3 * {
                font-weight: 600;
                font-size: 2.7rem;
            }

            & ._DOC a {
                padding: 0.6rem 1.4rem;
                border-radius: 1.2rem;
            }
        }
    }

    &__pages._static &__page {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__listButton {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 100;
        align-items: center;
        padding: 2.3rem 3rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 2.3rem;
        background: #e0e8ff;
        border: 2px solid #fff;
        border-right: 0;
        border-bottom: 0;
        border-radius: 3rem 0 0;
        transform-origin: right bottom;

        @include button(0.97);

        &::after {
            margin-left: 1.2rem;
            border: 0.4rem solid transparent;
            border-right-color: $colorDark2;
            border-bottom-color: $colorDark2;
            transform: rotate(45deg);
            content: "";
        }
    }
}
