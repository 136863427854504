.manualCorporationInfo {
    position: relative;
    width: 100%;
    padding-left: 26px;

    &._mediumSize {
        padding-left: 22px;
    }

    &._short {
        padding-left: 0;
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background: $colorBlueBack;
        border-radius: 50%;
        transform: translate(0, -50%);
        will-change: transform;

        & .v2avatar__text {
            font-size: 10px;
        }
    }

    &._mediumSize &__logo {
        width: 18px;
        height: 18px;
    }

    &._short &__logo {
        position: relative;
        top: 0;
        transform: translate(0, 0);
    }

    &__name {
        max-width: 100%;
        overflow: hidden;
        color: $colorDark2;
        font-weight: 500;
        font-size: 13.5px;
        line-height: 1.4;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: $trans;
    }

    &._mediumSize &__name {
        font-size: 13px;
    }
}
