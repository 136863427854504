.manualFile {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    @include button(.97);
    transition: $trans;

    &__input {
        display: none;
    }

    &:not(._isDisabled) &__preview {
        border-color: rgba($colorMain, .64);
    }

    &__preview {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 14px;
        transition: $trans;
        overflow: hidden;
        background: #fff;
        will-change: transform;
        border: 1px solid rgba($colorGreyDark, .64);

        &:not(._show) {
            opacity: 0;
            transform: scale(1.05);
        }

        &Delete {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 26px;
            height: 26px;
            z-index: 2;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &Item {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &Icon {
            width: 32px;
            height: 32px;
            display: block;
            margin: auto;
            @include icon($colorGreyDark);
        }
    }

    &._contain &__preview {
        &Image {
            padding: 8px;

            &Item {
                object-fit: contain;
            }
        }
    }

    &._contain._minSize &__previewImage {
        padding: 4px;
    }

    &__content {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 6px;
        position: relative;
        z-index: 1;
    }

    &__border {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &._isDisabled &__border {
        @include borderDashed($colorGreyDark);
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin-bottom: 3px;
    }

    &._isDisabled &__icon {
        @include icon($colorGreyDark);
    }

    &._minSize &__icon {
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
    }

    &__description {
        max-width: 80%;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        color: $colorMain;
        transition: $trans;

        & span {
            font-weight: 600;
        }
    }

    &._isDisabled &__description {
        color: $colorGreyDark;
    }

    &._minSize &__description {
        font-size: 13px;
    }
}