.v2notificationAnnounce {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &__inner {
        width: 100%;
        padding: 20px;
        transition: $trans;
    }

    &:not(._ready) &__inner {
        opacity: 0;
    }

    &__head {
        width: 100%;

        &Back {
            margin-bottom: 20px;
            color: $colorMain;
            font-size: 14px;

            &Icon {
                width: 12px;
                height: 12px;
                margin: 1px 4px 0 0;

                @include icon($colorMain);
            }
        }

        &Type {
            z-index: 2;
            margin-bottom: 16px;
            padding: 5px 10px;
            color: #fff;
            font-weight: 700;
            font-size: 10px;
            line-height: 1.2;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            background: #5cc962;
            border-radius: 6px;
            transition: $trans;
        }

        &Title {
            max-width: 100%;
            margin-bottom: 10px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 18px;
            line-height: 1.4;
        }

        &Info {
            align-items: center;
            margin-bottom: 16px;
        }

        &Date {
            margin-right: 8px;
            color: $colorGreyDark;
            font-size: 14px;
            line-height: 1.3;
        }

        &View {
            align-items: center;
            color: $colorGreyDark;
            font-size: 14px;

            &Icon {
                width: 17px;
                height: 17px;
                margin-right: 6px;

                @include icon(rgba($colorGreyDark, 0.72));
            }
        }
    }

    &__content {
        width: 100%;
        margin-bottom: 20px;

        &Inner {
            width: 100%;
            color: $colorDark2;

            @extend %editorContent;

            &,
            & * {
                font-size: 15px;
                line-height: 1.5;
            }

            & div._empty {
                height: 12px;
            }
        }
    }

    &__content p + p {
        margin-top: 12px;
    }

    &__foot {
        align-items: center;
        color: #b0bbc7;
        font-weight: 600;
        font-size: 15px;

        &Icon {
            width: 24px;
            height: 24px;
            margin-right: 6px;
            object-fit: contain;
        }
    }
}
