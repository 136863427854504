.manualCardParamsPreview {
    width: 100%;

    &__cards {
        width: 100%;
        justify-content: space-between;
    }

    &__card {
        width: 32%;
        height: 92px;
        border-radius: 12px;
        background: $colorBlueBack;
        justify-content: center;
        align-items: center;
        transition: $trans;

        &._error {
            background: $colorAlertBack;
        }

        &Icon {
            width: 32px;
            height: 32px;
            margin: -2px 0 0 0;
        }

        &Value {
            width: 64px;
            margin: -2px 0 2px 0;
            transition: $trans;
        }

        &Support {
            max-width: 100%;
            text-align: center;
            font-size: 11px;
            font-weight: 500;
            color: $colorDark2;
        }
    }

    &._car &__card {
        width: calc(50% - 3px);
    }

    &._worker &__card,
    &._courier &__card {
        width: 100%;
    }

    &._edit &__card {
        background: $colorBlueActive;

        &._error {
            background: rgba($colorAlert, .075);
        }

        &Value {
            margin: 2px 0 4px 0;
        }
    }
}