.dealLogs {
    position: relative;
    width: 100%;
    height: 100%;

    &__scroll {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: none;
        overscroll-behavior: none;
    }

    &__items {
        width: 100%;
        padding: 21px 0;
    }

    &__item {
        width: 100%;

        & .list__itemBox {
            height: auto;
        }

        &Inner {
            width: 100%;
            padding: 5px 26px;

            &._date {
                padding: 16px 0;
            }
        }

        &Date {
            position: relative;
            z-index: 1;
            align-items: center;
            justify-content: center;
            width: 100%;

            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                z-index: -1;
                width: 100%;
                height: 1px;
                background: rgba($colorGreyDark, 0.32);
                transform: translate(0, -50%);
                content: "";
            }

            &Inner {
                padding: 5px 11px;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                background: $colorGreyDark;
                border-radius: 360px;
            }
        }
    }
}
