.editor {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 18px;
    box-shadow: 0 0 2px 0 rgb(216 220 228 / 72%);

    &__head {
        position: sticky;
        top: -24px;
        z-index: 2;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 10px 26px;
        background: #fff;
        border-bottom: 1px solid $colorBlue;
        border-radius: 18px 18px 0 0;
    }

    &__title {
        position: relative;
        margin-right: 6px;
        color: $colorDark2;
        font-weight: 600;
        font-size: 16px;
    }

    &__actions {
        align-items: center;
        margin-left: auto;
    }

    &__action {
        position: relative;
        width: 28px;
        height: 28px;

        & + & {
            margin-left: 4px;
        }

        &Inner {
            display: flex;
            width: 100%;
            height: 100%;
            background: $colorBlueBack;
            border-radius: 8px;
            transition: $trans;

            @include button(0.95);
        }

        &Icon {
            width: 26px;
            height: 26px;
            margin: auto;

            @include icon($colorDark2);
        }

        &._table &Alert {
            width: 280px;
        }

        &._image &Alert {
            width: 280px;

            // opacity: 1 !important;
        }

        &Alert {
            position: absolute;
            top: 100%;
            right: -18px;
            z-index: 10;
            width: 240px;
            padding-top: 16px;
            transition: $trans;

            &._show {
                opacity: 1 !important;
                pointer-events: visible !important;
            }

            &Inner {
                @extend %shadow;

                z-index: 1;
                width: 100%;
                background: #fff;
                border-radius: 14px;
            }

            &Head {
                width: 100%;
                padding: 16px;
                border-bottom: 1px solid $colorBlue;
            }

            &Title {
                max-width: 100%;
                margin-bottom: 6px;
                color: $colorDark2;
                font-weight: 600;
                font-size: 16px;

                & span {
                    color: $colorGreyDark;
                }
            }

            &Description {
                max-width: 100%;
                color: $colorDark2;
                font-weight: 300;
                font-size: 14px;
                line-height: 1.3;
            }

            &Content {
                width: 100%;
                padding: 16px;

                &Titles {
                    width: 100%;
                    padding-bottom: 4px;
                }

                &Title {
                    position: relative;
                    max-width: 100%;
                    color: $colorDark2;
                    font-weight: 600;
                    font-size: 17px;
                    transition: $trans;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: $colorMain;
                        opacity: 0;
                        transition: $trans;
                        content: "";
                    }

                    &._current {
                        color: $colorMain;

                        &::after {
                            opacity: 1;
                        }
                    }

                    &._sub {
                        font-size: 15px;
                    }

                    & + & {
                        margin-top: 8px;
                    }
                }
            }

            &File {
                display: block;
                width: 100%;
                height: 100%;
                margin-bottom: 10px;

                &Box {
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 24px 18px;
                    transition: $trans;

                    @include button(0.98);
                }

                &Loader {
                    background: #fff;
                    border-radius: 16px;
                }

                &Back {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 11;
                    width: 100%;
                    height: 100%;
                    pointer-events: none;
                }

                &Icon {
                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-bottom: 6px;

                    @include icon($colorMain);
                }

                &Title {
                    max-width: 100%;
                    margin-bottom: 7px;
                    color: $colorMain;
                    font-size: 16px;
                    text-align: center;
                }

                &Description {
                    max-width: 100%;
                    color: $colorMain;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 1.4;
                    text-align: center;
                }
            }

            &Field {
                width: 100%;
                height: 42px;

                & + & {
                    margin-top: 8px;
                }

                & .field__value {
                    padding: 6px 14px !important;
                }
            }

            &Row {
                width: 100%;

                & + & {
                    margin-top: 4px;
                }
            }

            &Col {
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
                background: rgba($colorBlue, 0.56);
                border: 1px solid transparent;
                border-radius: 7px;
                transition: $trans;
                will-change: transform;

                &._active {
                    background: #d3e0ff;

                    &._current {
                        // border-color: $colorMain;
                        // transform: scale(1.1);
                    }
                }

                & + & {
                    margin-left: 4px;
                }

                &Text {
                    color: $colorMain;
                    font-size: 7.5px;
                    white-space: nowrap;
                    transition: $trans;
                    will-change: transform;
                }

                &:not(._current) &Text {
                    opacity: 0;
                }
            }

            &Sizes {
                align-items: center;
                width: 100%;
                margin-top: 10px;

                &Support {
                    max-width: 100%;
                    margin-bottom: 14px;
                    color: $colorDark2;
                    font-size: 16px;
                    text-align: center;
                }

                &Items {
                    justify-content: space-between;
                    width: 100%;
                }
            }

            &Size {
                display: block;
                width: calc(100% / 3 - 4px);
                transition: $trans;

                @include button(0.98);

                &Input {
                    display: none;
                }

                &Inner {
                    align-items: center;
                    width: 100%;
                }

                &Value {
                    max-width: 100%;
                    margin-bottom: 5px;
                    color: $colorGreyDark;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: center;
                    transition: $trans;
                }

                &Input:checked ~ &Inner &Value {
                    color: $colorDark2;
                }

                &Box {
                    position: relative;
                    width: 100%;
                    height: 56px;
                    padding: 6px;
                    background: #e8edf2;
                    border-radius: 12px;
                    transition: $trans;

                    &Item {
                        height: 100%;
                        background: #fff;
                        border-radius: 9px;
                    }
                }

                &Input:checked ~ &Inner &Box {
                    background: #dae3ff;
                }

                &Input:checked ~ &Inner &Current {
                    opacity: 1;
                }

                &Current {
                    position: absolute;
                    right: 6px;
                    bottom: 6px;
                    z-index: 2;
                    width: 14px;
                    height: 14px;
                    background: $colorMain;
                    border-radius: 50%;
                    opacity: 0;
                    transition: $trans;

                    &Icon {
                        display: block;
                        width: 8px;
                        height: 8px;
                        margin: auto;

                        @include icon(#fff);
                    }
                }
            }

            &Checkboxes {
                width: 100%;
                margin: 12px 0 6px;

                &Support {
                    margin-bottom: 9px;
                    color: $colorDark2;
                    font-weight: 300;
                    font-size: 14px;
                }

                &Items {
                    flex-wrap: wrap;
                    width: calc(100% + 10px);
                    margin-bottom: -10px;
                }
            }

            &Checkboxes &Checkbox {
                width: auto;
                margin: 0 10px 10px 0;
                margin-top: 0;
            }

            &Checkbox {
                position: relative;
                display: block;
                width: 100%;
                margin-top: 20px;
                padding-left: 24px;
                color: $colorDark2;
                font-weight: 300;
                font-size: 14px;

                &Box {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &Radios {
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 12px;
            }

            &Radios + &Button {
                margin-top: 4px;
            }

            &Radio {
                display: block;
                width: 26px;
                height: 26px;

                &Input {
                    display: none;
                }

                &View {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    border: 1px solid $colorBlue;
                    border-radius: 50%;
                    transition: $trans;
                }

                &._square &View {
                    align-items: center;
                    justify-content: center;
                    color: $colorDark2;
                    font-weight: 500;
                    font-size: 12px;
                    text-align: center;
                    text-transform: uppercase;
                    border-radius: 6px;
                }

                &._remove &View {
                    z-index: 1;
                    overflow: hidden;

                    &::before {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        width: 2px;
                        height: 100%;
                        background: $colorAlert;
                        transform: translate(-50%, 0) rotate(45deg);
                        transform-origin: center;
                        content: "";
                    }
                }

                &._remove &Input:checked ~ &View {
                    border-color: $colorAlert;
                }

                &._remove &Icon {
                    display: none;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 2;
                    display: block;
                    width: 13px;
                    height: 13px;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                    transition: $trans;

                    @include icon(#fff);
                }

                &Input:checked ~ &View &Icon {
                    opacity: 1;
                }
            }

            &Button {
                width: 100%;
                margin-top: 12px;
            }
        }
    }

    &__action:not(:hover) &__actionAlert {
        opacity: 0;
        pointer-events: none;
    }

    &._withActions &__content {
        padding-top: 0;
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        height: calc(100% - 60px);
        overflow: hidden;
        overflow-y: auto;

        &Head {
            left: 0;
            z-index: 10;
            width: 100%;
            height: 110px;
            padding: 18px;
            background: #fff;
            border-bottom: 1px solid rgba($colorBlue, 0.72);
            transition: $trans;
        }
    }

    &__area {
        position: relative;
        width: 100%;
        height: 100%;
        transition: $trans;

        &Support {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            padding: 20px 26px;
            color: $colorGreyDark;
            font-size: 15.5px;
            line-height: 1.3;
            transition: $trans;
            pointer-events: none;

            &:not(._show) {
                opacity: 0;
            }
        }

        &._error &Support,
        &._error &Inner {
            color: $colorAlert;
        }

        &Inner {
            @extend %editorContent;

            width: 100%;
            min-height: 100%;
            padding: 20px 26px;
            color: $colorDark2;
            font-size: 15.5px;
            line-height: 1.3;

            & ._pattern {
                padding: 0 5px 1px;
                color: $colorMain;
                font-weight: 600;
                background: #f2f5ff;
                border-radius: 4px;
            }

            & ._ancor {
                padding: 3px 6px;
                color: $colorMain;
                background: $colorBlueBack;
                border: 1px solid rgba($colorMain, 0.1);
                border-radius: 5px;
                cursor: text;
            }

            & ._articleLink {
                position: relative;
                padding-right: 5px;
                color: $colorMain;
                border-bottom: 1px solid $colorMain;

                &::before {
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 12px;
                    height: 12px;
                    color: #fff;
                    font-weight: 700;
                    font-size: 9px;
                    font-style: normal;
                    background: $colorMain;
                    border-radius: 50%;
                    content: "С";
                }
            }

            & div {
                transition: $trans;
            }

            & ._editAncor {
                // border-radius: 8px;
                padding: 12px;
                background: $colorBlueBack;
                border: 1px solid rgba($colorMain, 0.1);
                cursor: pointer;
                will-change: transform;

                &:hover {
                    transform: scale(1.01);
                }

                // user-select: none;
            }

            & img {
                position: relative;
                z-index: 1;
                display: block;
                overflow: hidden;
                border: 1px solid rgba($colorGreyDark, 0.56);
                border-radius: 16px;
            }

            & h2 {
                font-weight: 600;
                font-size: 20px;

                & * {
                    font-weight: 600;
                }
            }

            & h3 {
                font-weight: 600;
                font-size: 18px;

                & * {
                    font-weight: 600;
                }
            }
        }
    }

    &__gap {
        width: 100%;
        padding: 10px 0;

        &Inner {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 28px;
            background: $colorBlueBack;
            border-radius: 5px;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                width: calc(100% - 24px);
                height: 1px;
                background: rgba($colorDark2, 0.5);
                transform: translate(-50%, -50%);
                transition: $trans;
                content: "";
            }
        }

        &Content {
            padding: 2px 8px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.5px;
            text-align: center;
            text-transform: uppercase;
            background: $colorBlueBack;
            transition: $trans;
        }

        &Delete {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: $colorAlert;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            background: $colorBlueBack;
            border-radius: 5px;
            cursor: pointer;
            transition: $trans;

            &Icon {
                width: 20px;
                height: 20px;
                margin-left: 6px;

                @include icon($colorAlert);
            }
        }

        &:not(:hover) &Delete {
            opacity: 0;
            pointer-events: none;
        }
    }
}
