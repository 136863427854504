.publicExecutorsFaq {
    @extend %section;

    position: relative;
    z-index: 1;
    width: 100%;
    padding: 21rem 0;
    overflow: hidden;

    @include media(0, $mediaM) {
        padding: 7rem 0;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        width: 180rem;
        height: 180rem;
        transform: translate(0, -50%);
        content: "";

        @include bg("/img/crm/public/executors-faq-back.png", contain, left center);

        @include media(0, $mediaM) {
            left: -5rem;
            width: 190rem;
            height: 190rem;
        }
    }

    &__inner {
        @extend %publicInner;

        align-items: center;
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }

    &__title {
        max-width: 100%;
        margin-bottom: 1.5rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 3.7rem;

        @include media(0, $mediaM) {
            font-size: 4rem;
        }
    }

    &__description {
        max-width: 100%;
        color: $colorDark2;
        font-weight: 600;
        font-size: 1.9rem;
        line-height: 1.5;
        text-align: center;

        @include media(0, $mediaM) {
            max-width: 80%;
            font-weight: 500;
            font-size: 2.3rem;
            line-height: 1.4;
        }
    }

    &__content {
        width: 83%;

        @include media(0, $mediaM) {
            width: 100%;
        }
    }

    &__card {
        width: 100%;
        transition: 0.3s cubic-bezier(0.35, 1.57, 0.5, 1.59);
        will-change: transform;

        &:hover {
            transform: scale(1.02);

            @include media(0, $mediaM) {
                transform: none;
            }
        }

        & + & {
            margin-top: 2rem;
        }

        &Inner {
            width: 100%;
            background: #fff;
            border-radius: 3rem;
            box-shadow: 0 1rem 3.2rem 0 rgb(0 0 0 / 6%);
            transition: $trans;

            @include button(0.98);
        }

        &Head {
            position: relative;
            width: 100%;
            padding: 4rem;
            padding-right: 10rem;

            @include media(0, $mediaM) {
                padding: 3.7rem 4rem;
                padding-right: 10rem;
            }

            & span {
                color: $colorDark2;
                font-weight: 600;
                font-size: 2.1rem;

                @include media(0, $mediaM) {
                    font-size: 2.4rem;
                    line-height: 1.3;
                }
            }

            &Icon {
                position: absolute;
                top: 3.1rem;
                right: 3.7rem;
                width: 4rem;
                height: 4rem;
                background: $colorMain;
                border-radius: 50%;
                transition: $trans;

                @include media(0, $mediaM) {
                    right: 3rem;
                    width: 4.5rem;
                    height: 4.5rem;
                }

                &Item {
                    width: 1.3rem;
                    height: 1.3rem;
                    margin: auto;

                    @include icon(#fff);

                    @include media(0, $mediaM) {
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }
        }

        &._active &HeadIcon {
            transform: rotate(45deg);
            opacity: 0.32;
        }

        &Content {
            position: relative;
            width: 100%;
            height: 0;
            overflow: hidden;
            transition: $trans;

            &Inner {
                width: 100%;
                padding: 4rem;
                padding-top: 0;
                color: $colorDark2;
                font-size: 1.9rem;
                line-height: 1.4;

                @include media(0, $mediaM) {
                    font-size: 2.3rem;
                }
            }
        }
    }
}
