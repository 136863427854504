.publicAppButton {
    width: 100%;
    height: 100%;
    padding: 1.35rem 2rem;
    border-radius: .8rem;
    background: #fff;
    position: relative;
    font-weight: 500;
    font-size: 1.1rem;
    color: $colorMain;
    justify-content: center;
    white-space: nowrap;
    transition: $trans;
    @include button(.97);

    @include media(0, $mediaM) {
        font-size: 1.6rem;
        padding: 1.7rem 2.7rem;
        border-radius: 1.5rem;
        align-items: center;
    }

    &._reverse {
        background: $colorMain;
        color: #fff;
        padding: 1.1rem 2rem;
        border-radius: 1rem;

        @include media(0, $mediaM) {
            padding: 1.7rem 2.7rem;
            border-radius: 1.5rem;
        }
    }

    & span {
        font-size: 1.55rem;
        font-weight: 600;
        margin-top: .2rem;
        display: block;

        @include media(0, $mediaM) {
            font-size: 2.4rem;
            margin-top: 0.3rem;
        }
    }

    &__inner {
        position: relative;
        padding-left: 3.5rem;

        @include media(0, $mediaM) {
            padding-left: 5rem;
        }
    }

    &__icon {
        width: 2.8rem;
        height: 2.8rem;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        @include icon($colorMain);

        @include media(0, $mediaM) {
            width: 4rem;
            height: 4rem;
        }
    }

    &._apple &__icon {
        margin-top: -0.2rem;
    }

    &._reverse &__icon {
        @include icon(#fff);

        & ._whiteElem {
            fill: $colorMain;
        }
    }
}