.contentEditPanel {
    width: 100%;
    position: relative;

    &__box {
        // width: 80px;
        // height: 20px;
        position: relative;
        // justify-content: flex-end;
        // align-items: center;
    }

    &__inner {
        &._child {
            left: 50%;
            transform: translate(-50%, 0);
            top: auto;
            bottom: 0;
        }
    }

    &__buttons {
        border-radius: 8px;
    }

    &__button {
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background-color: rgba(#fff, 1);
        box-shadow: 0 0 2px 2px rgba(#000, .03);
        transition: $trans;

        &:first-child {
            border-radius: 8px 0 0 8px;
        }

        &:last-child {
            border-radius: 0 8px 8px 0;
        }

        &+& {
            margin-left: 1px;
        }

        &Icon {
            width: 16px;
            height: 16px;
            @include icon(#363C42);
            pointer-events: none;
        }

        &._active {
            background: $colorMain;
        }

        &._active &Icon {
            @include icon(#fff);
        }

        &:not(._active):hover &Icon {
            @include icon($colorMain);
        }
    }

    &__settings {
        position: relative;
        padding: 14px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 1px 8px 0 #d8dce4;

        &._link {
            width: 264px;
        }

        &._colors {
            width: 200px;
        }

        &Title {
            margin-bottom: 10px;
            color: $colorDark;
            font-size: 14px;
            font-weight: 600;
        }

        &Colors {
            justify-content: space-between;
            width: 100%;
            margin-bottom: 6px;
        }

        &Color {
            width: 22px;
            height: 22px;

            &Input {
                display: none;
            }

            &View {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }

            &Icon {
                display: none;
                width: 16px;
                height: 16px;
                @include icon(#fff);
            }

            &Input:checked~&View &Icon {
                display: block;
            }
        }

        &Close {
            position: absolute;
            top: 14px;
            right: 14px;
            z-index: 2;
            width: 14px;
            height: 14px;
        }

        &Input {
            width: 100%;
            height: 30px;
            margin-bottom: 6px;
            padding: 6px 10px 7px 10px;
            color: $colorDark;
            font-size: 14px;
            font-weight: 300;
            background: $colorGrey;
            border-radius: 8px;

            &::-webkit-input-placeholder {
                color: $colorGreyDark;
            }
        }

        &Actions {
            align-items: center;
            width: 100%;
            height: 30px;
        }

        &Tabs {
            height: 100%;
        }

        &Tab {
            display: block;
            width: 32px;
            height: 100%;

            &+& {
                margin-left: 4px;
            }

            &Input {
                display: none;
            }

            &View {
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 14px;
                font-weight: 300;
                background: #f8fafc;
                border-radius: 8px;
            }

            &._under &View {
                text-decoration: underline;
            }

            &Input:checked~&View {
                color: $colorMain;
                background: #f1f5ff;
            }
        }

        &._colors &Button {
            width: 100%;
        }

        &Button {
            align-items: center;
            justify-content: center;
            width: 126px;
            height: 100%;
            margin-left: auto;
            padding: 6px 12px 8px 12px;
            color: #fff;
            font-size: 14px;
            font-weight: 300;
            background: $colorMain;
            border-radius: 8px;
        }
    }
}