.v2widgetCardInfo {
    width: 100%;
    height: 100%;

    &__balance {
        position: absolute;
        top: 14px;
        right: 14px;
        z-index: 2;
        width: 28px;
        height: 28px;
        background: #f3f6ff;
        border-radius: 9px;
        transition: $trans;

        @include button(0.96);

        &Icon {
            display: block;
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }

    &__info {
        position: absolute;
        top: 22px;
        right: 22px;
        z-index: 2;
        width: 16px;
        height: 16px;

        @include icon($colorBlue);
    }

    &__content {
        position: relative;
        z-index: 1;
        width: calc(100% + 26px * 2);
        margin: 20px 0 0 -26px;
        padding: 6px 26px;
        overflow: hidden;
        border-top: 1px solid $colorBlue;
    }

    &__foot {
        width: calc(100% + 26px * 2);
        margin-top: auto;
        margin-left: -26px;
        padding: 10px 26px 0;
        border-top: 1px solid $colorBlue;
    }

    &__support {
        max-width: 100%;
        height: 18px;
        margin: -3px 0 4px;
        color: $colorDark2;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;

        & span {
            color: rgba($colorGreyDark, 0.64);
            font-weight: 600;
        }

        &Tab {
            display: inline-block;
            transition: $trans;

            @include button(0.97);

            &Input {
                display: none;
            }

            &Content {
                position: relative;
                padding-bottom: 2px;
                color: $colorGreyDark;
                font-weight: 600;
                line-height: 1.3;
                border-radius: 6px;
                transition: $trans;
            }

            &Input:checked ~ &Content {
                padding-right: 6px;
                padding-left: 6px;
                color: $colorMain;
                background: $colorBlueBack;

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &__title {
        max-width: 100%;
        margin-bottom: 4px;
        color: $colorDark2;
        font-weight: 600;
        font-size: 28px;
        line-height: 1.1;
    }

    &__description {
        max-width: 100%;
        color: $colorGreyDark;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.2;
    }

    &__button {
        height: 30px;
        margin-top: 12px;
    }

    &__options {
        position: relative;
        width: 100%;
        transition: $trans;

        &Block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: $trans;

            &:not(._show) {
                opacity: 0;

                &._prev {
                    transform: translate(-100%, 0);
                }

                &._next {
                    transform: translate(100%, 0);
                }
            }
        }

        &._static &Block {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }
    }

    &__option {
        width: 100%;
        padding: 16px 0;

        & + & {
            border-top: 1px solid $colorBlue;
        }

        &Text {
            color: $colorDark2;
            font-weight: 600;
            font-size: 14.5px;
            line-height: 1.3;

            &._support {
                margin-right: auto;
                padding-right: 20px;
                font-weight: 500;
            }
        }
    }

    ._parentCalc._marginCard &__option,
    &._marginCard &__option {
        padding: 18px 0;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
