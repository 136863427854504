.dealCustomField {
    width: 100%;

    &__head {
        justify-content: space-between;
        width: 100%;
        height: 32px;

        &Field {
            height: 100%;

            &._type {
                width: 100px;
            }

            &._name {
                width: calc(100% - 100px - 32px - 12px);
            }
        }

        &Delete {
            width: 32px;
            height: 100%;
            background: $colorAlertBack;
            border-radius: 8px;

            &Icon {
                @include icon($colorAlert);

                width: 17px;
                height: 17px;
                margin: auto;
            }
        }
    }

    &__content {
        width: 100%;
        transition: $trans;
    }

    &__list {
        width: 100%;
        padding-top: 12px;

        &Items {
            width: 100%;

            &:not(._empty) {
                margin-bottom: 12px;
            }
        }

        &Item {
            width: 100%;

            &Field {
                position: relative;
                width: 100%;
                height: 32px;

                &Delete {
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    z-index: 2;
                    width: 16px;
                    height: 16px;
                    transform: translate(0, -50%);

                    @include icon($colorGreyDark);
                }

                & .v2input__field {
                    padding-right: 28px!important;
                }
            }
        }

        &Items._static &Item:not(._last) {
            margin-bottom: 8px;
        }

        &Btn {
            color: $colorMain;
            font-size: 14px;
        }
    }
}
