.publicBusinessCases {
    width: 100%;
    padding: 14rem 0;
    mask:
        radial-gradient(circle 2rem at top center, #0000 99%, #000) top center,
        radial-gradient(circle 2rem at bottom center, #0000 99%, #000) bottom center;
    mask-size: 100% 51%;
    mask-repeat: no-repeat;
    background: linear-gradient(to bottom, #72bcf7, #5592f2 40rem, #3e6aed);

    @include mob {
        padding: 8rem 0;
        background: linear-gradient(to bottom, #72bcf7, #5592f2, #3e6aed);
    }

    &__inner {
        align-items: center;
    }

    &__head {
        align-items: center;
        width: 42rem;
        margin-bottom: 4rem;

        @include mob {
            width: 100%;
            margin-bottom: 7rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__subTitle {
        text-align: center;
    }

    &__content {
        width: calc(100% + 6rem);
        margin-left: -3rem;

        @include mob {
            width: 100%;
            margin-left: 0;
            padding: 0 2rem;
        }
    }

    &__block {
        align-items: center;
        width: 100%;

        & + & {
            margin-top: 15rem;

            @include mob {
                margin-top: 8rem;
            }
        }

        &Decor {
            position: relative;
            width: calc(100% - 39rem - 9rem);

            @include mob {
                display: none;
            }

            &Item {
                position: relative;
                z-index: 1;
                width: calc(100% - 9rem);
                height: 48rem;
                overflow: hidden;
                background: #fff;
                border-radius: 3.2rem;
                box-shadow: 0 1.6rem 6.4rem rgba(#363c42, 0.12);

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border: 0.4rem solid #fff;
                    border-radius: 3.2rem;
                    content: "";
                }
            }

            &Image {
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &._1 &DecorItem {
            &._1 {
                z-index: 2;
                margin-bottom: -12rem;
                margin-left: auto;
            }

            &._2 {
                margin-left: 0;
            }
        }

        &._2 &DecorItem {
            &._1 {
                z-index: 2;
                margin-bottom: -12rem;
                margin-left: auto;
            }

            &._2 {
                margin-left: 0;
            }
        }

        &._3 &DecorItem {
            &._1 {
                z-index: 2;
                margin-bottom: -12rem;
                margin-left: auto;
            }

            &._2 {
                margin-left: 0;
            }
        }

        &._4 &DecorItem {
            &._1 {
                margin-bottom: 32rem;
                margin-left: auto;
            }

            &._2,
            &._3 {
                position: absolute;
                bottom: 0;
                z-index: 2;
                width: 22rem;
                height: 46rem;
            }

            &._2 {
                left: 16rem;
            }

            &._3 {
                bottom: 6rem;
                left: 41.5rem;
            }
        }

        &Content {
            width: 39rem;
            margin-left: auto;

            @include mob {
                width: 100%;
            }
        }

        &:nth-child(2n) {
            flex-direction: row-reverse;

            @include mob {
                flex-direction: row;
            }
        }

        &:nth-child(2n) &Content {
            margin-right: auto;
            margin-left: 0;
        }

        &Category {
            margin-bottom: 2rem;
            color: #fff;
            font-weight: 600;
            font-size: 1.8rem;
            text-transform: uppercase;

            @include mob {
                font-size: 2rem;
            }
        }

        &Title {
            max-width: 100%;
            margin-bottom: 2rem;
            color: #fff;
            font-weight: 600;
            font-size: 3rem;
            line-height: 1.35;

            @include mob {
                max-width: 90%;
                font-size: 3.3rem;
            }
        }

        &Text {
            color: #fff;
            font-weight: 500;
            font-size: 1.9rem;
            line-height: 1.6;

            @include mob {
                font-size: 2rem;
            }
        }
    }
}
