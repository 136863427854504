.sortButton {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 10px;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 5px;
        height: 5px;
        background: $colorMain;
        border-radius: 50%;
        opacity: 0;
        transition: $trans;
        content: "";
    }

    @include button(0.95);

    &._grey {
        background: $colorBlueBack;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin: auto;

        @include icon($colorDark2);
    }

    &._active {
        background: #fff;

        &::after {
            opacity: 1;
        }
    }

    &._active &__icon {
        @include icon($colorMain);
    }
}
