.dealGroupStep {
    width: 100%;
    height: 100%;
    background: #ebf0f7;

    &:not(._default) &__head:hover &__headContent,
    &__head._colorsProcess &__headContent {
        transform: translate(0, -4px);
    }

    .dealGroup__contentStep[data-first] &__headAdd {
        transform: translate(6px, 50%);
    }

    &__head {
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 76px;
        padding: 14px;
        background: #fff;
        border-right: 1px solid $colorBlueBack;
        border-left: 1px solid $colorBlueBack;
        transition: $trans;

        &Add {
            position: absolute;
            bottom: 0;
            left: -1px;
            z-index: 3;
            width: 28px;
            height: 28px;
            background: #fff;
            border: 2px solid #ebf0f7;
            border-radius: 50%;
            transform: translate(-50%, 50%);
            transition: $trans;

            &Icon {
                width: 12px;
                height: 12px;
                margin: auto;

                @include icon($colorMain);
            }
        }

        &Content {
            align-items: center;
            width: 100%;
            transition: $trans;
            will-change: transform;
        }

        &Title {
            width: 100%;
            margin-bottom: 4px;

            & .v2input__field {
                color: $colorDark2;
                font-weight: 600;
            }

            & .v2input__field,
            & .v2input__support {
                justify-content: center;
                font-size: 16px;
                text-align: center;
            }
        }

        &Support {
            &Inner {
                position: relative;
                color: $colorGreyDark;
                font-size: 13px;
                line-height: 1.2;
                white-space: nowrap;

                @include button(0.97);

                &._active {
                    padding-left: 16px;
                }
            }

            &Icon {
                position: absolute;
                top: 50%;
                left: 0;
                width: 11px;
                height: 11px;
                transform: translate(0, -50%);

                @include icon($colorGreyDark);
            }
        }

        &Drag {
            position: absolute;
            top: 12px;
            right: 16px;
            z-index: 2;
            width: 16px;
            height: 16px;
            transition: $trans;

            @include icon($colorDark2);
        }

        &:not(:hover, ._colorsProcess) &Delete {
            opacity: 0;
        }

        &Delete {
            position: absolute;
            right: 16px;
            bottom: 12px;
            width: 16px;
            height: 16px;
            transition: $trans;

            @include icon($colorAlert);
        }

        &Colors {
            position: absolute;
            bottom: 0;
            left: 50%;
            z-index: 2;
            padding: 4px 9px 5px;
            color: #fff;
            font-weight: 600;
            font-size: 13px;
            background: #75808b;
            border-radius: 360px;
            transform: translate(-50%, 50%);
            transition: $trans;

            &Icon {
                width: 10px;
                height: 10px;
                margin-right: 3px;
                transform: translate(0, 2px);

                @include icon(#fff);
            }
        }

        &:not(:hover, ._colorsProcess) &Colors {
            opacity: 0;
        }

        &Color {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 2px;
            transition: $trans;
            pointer-events: none;
        }
    }

    &__content {
        width: 100%;
        height: calc(100% - 76px);
        padding: 28px;
        overflow: hidden;
        overflow-y: auto;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
    }

    &__rejectReasons {
        align-items: center;
        width: 100%;
        margin-bottom: 12px;
        padding: 24px;
        background: rgba(#fff, 0.48);
        border-radius: 15px;

        &Title {
            max-width: 100%;
            margin-bottom: 7px;
            color: $colorGreyDark;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            transition: $trans;
        }

        &._active &Title {
            color: $colorDark2;
        }

        &Description {
            max-width: 90%;
            margin-bottom: 7px;
            color: $colorGreyDark;
            font-weight: 500;
            font-size: 13px;
            line-height: 1.4;
            text-align: center;
            transition: $trans;
        }

        &._active &Description {
            color: $colorDark2;
        }

        &Items {
            width: 100%;

            &._static {
                display: flex;
                flex-direction: column;
            }

            &:not(._empty) {
                margin: 6px 0 12px;
            }
        }

        &Item {
            width: 100%;
            height: 38px;
            padding: 10px 14px;
            background: rgba(#fff, 0.88);
            border-radius: 9px;

            &Field {
                width: 100%;
                height: 100%;
                padding-right: 26px;

                & .v2input__support,
                & .v2input__field {
                    // font-size: 14px;
                }
            }

            &Delete {
                position: absolute;
                top: 50%;
                right: 11px;
                z-index: 2;
                width: 17px;
                height: 17px;
                transform: translate(0, -50%);
            }
        }

        &Items._static &Item:not(._last) {
            margin-bottom: 8px;
        }

        &Btn {
            color: $colorMain;
            font-size: 14px;
            line-height: 1.2;
            border-bottom: 1px solid rgba($colorMain, 0.72);

            @include button(0.97);
        }
    }

    &__triggers {
        width: 100%;

        &:not(._empty) {
            margin-bottom: 12px;
        }
    }

    &__trigger {
        width: 100%;
    }

    &__triggers._static &__trigger:not(._last) {
        margin-bottom: 10px;
    }

    &__addTrigger {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 106px;
        padding: 24px;
        background: rgba(#fff, 0.48);
        border-radius: 15px;

        @include button(0.98);

        &Icon {
            width: 14px;
            height: 14px;
            margin-bottom: 8px;
        }

        &Title {
            max-width: 100%;
            color: $colorGreyDark;
            font-size: 14px;
            text-align: center;
        }
    }
}
