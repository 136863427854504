.content {
    width: 100%;
    position: relative;
    &__page {
        @extend %noscroll;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // width: 990px;
        transition: $trans;
        &:not(._show) {
            opacity: 0;
        }
        &._prev:not(._show) {
            transform: translate(-64px, 0);
        }
        &._next:not(._show) {
            transform: translate(64px, 0);
        }
    }
}