.publicCallPopup {
    align-items: center;
    width: 100%;
    padding: 3.5rem 4.5rem 4.5rem;

    @include mob {
        justify-content: flex-start;
        padding: 5rem 0;
    }

    &__close {
        position: absolute;
        top: 2.5rem;
        right: 2.5rem;
        z-index: 2;
        width: 2.5rem;
        height: 2.5rem;

        @include mob {
            width: 3rem;
            height: 3rem;
        }

        @include button(0.96);
    }

    &__head {
        align-items: center;
        margin-bottom: 2.5rem;
    }

    &__icon {
        width: 8rem;
        height: 8rem;
        margin: 0 0 0 -4rem;
        object-fit: contain;
    }

    &__title {
        margin-bottom: 0.7rem;
        color: $colorDark;
        font-weight: 600;
        font-size: 3rem;
        text-align: center;

        @include mob {
            font-size: 3.3rem;
        }
    }

    &__description {
        color: $colorDark;
        font-weight: 300;
        font-size: 1.9rem;
        line-height: 1.5;
        text-align: center;

        @include mob {
            font-size: 2.1rem;
        }
    }

    &__content {
        width: 100%;
    }

    &__form {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 1rem;

        @include mob {
            flex-flow: column nowrap;
        }

        &Field {
            width: calc(calc(100% / 3) - calc(2rem / 3));
            margin-right: 1rem;
            margin-bottom: 1rem;

            @include mob {
                width: 100%;
                margin-right: 0;
            }

            &._email {
                margin-right: 0;
            }

            &._comments {
                width: 100%;
                margin-right: 0;
            }

            &Input {
                width: 100%;
                height: 6rem;
                background: #f2f7fd;
                border-radius: 1.5rem;

                @include mob {
                    height: 7.5rem;
                    border-radius: 2rem;
                }

                & .v2input__support,
                & .v2input__field {
                    padding: 1.7rem 2rem;
                    color: $colorDark;
                    font-size: 1.9rem;
                    line-height: 1.3;

                    @include mob {
                        padding: 2.3rem 2.5rem;
                        font-size: 2rem;
                    }
                }

                & .v2input__support {
                    color: $colorGreyDark;
                }
            }

            &._comments &Input {
                height: 12rem;

                @include mob {
                    height: 20rem;
                }
            }
        }
    }

    &__foot {
        align-items: center;
        width: 100%;

        &Inner {
            align-items: center;
            width: 100%;

            @include mob {
                flex-direction: column;
            }
        }

        &Button {
            width: 37rem;
            height: 6rem;

            @include mob {
                width: 100%;
                height: 7.5rem;
                margin-bottom: 1.5rem;
            }

            & .button {
                padding: 1.5rem 2rem;
                border-radius: 1.5rem;

                @include mob {
                    padding: 2rem;
                    border-radius: 2rem;
                }
            }

            & .button__content {
                font-size: 1.9rem;

                @include mob {
                    font-size: 2.1rem;
                }
            }
        }

        &Info {
            width: calc(100% - 37rem);
            padding-left: 2rem;
            color: $colorDark2;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 1.4;

            @include mob {
                width: 100%;
                padding-left: 0;
                font-size: 1.7rem;
                text-align: center;
            }

            & a {
                color: $colorDark2;
                font-weight: 500;
                border-bottom: 1px solid rgba($colorMain, 0.5);
            }
        }

        &Alert {
            transition: $trans;

            &._parent:not(._empty) {
                margin-top: 2rem;
            }

            &Item {
                padding: 0.8rem 1.2rem 0.9rem;
                color: #fff;
                font-weight: 500;
                font-size: 1.6rem;
                white-space: nowrap;
                background: $colorAlert;
                border-radius: 1rem;

                @include mob {
                    font-size: 2rem;
                }

                &._success {
                    background: $colorSuccess;
                }
            }
        }
    }
}
