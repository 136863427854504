.authForm {
    position: relative;
    z-index: 2;
    width: 100%;
    background: #fff;
    border-radius: 3rem;
    box-shadow: 0 1.3rem 3rem rgb(0 0 0 / 4%);

    &._login {
        margin-top: 6rem;
    }

    &__back {
        z-index: 1;
        align-items: center;
        width: calc(100% - 4rem);
        margin: -17rem auto 0;
        padding: 20rem 4.5rem 4rem;
        background: rgba(#fff, 0.8);
        border: 1px solid #fff;
        border-radius: 3rem;

        @include blur(10rem);

        &Button {
            width: 100%;
            height: 6rem;
            margin-bottom: 1rem;

            & .button {
                border: 0.2rem solid #fff;
                border-radius: 1.5rem;
            }

            & .button__content {
                font-size: 1.8rem !important;
            }
        }

        &Description {
            max-width: 100%;
            color: $colorDark2;
            font-size: 1.5rem;
            line-height: 1.35;
            text-align: center;
        }
    }

    &__inner {
        width: 100%;
    }

    ._design-newYear &__headBox {
        &::before {
            position: absolute;
            top: -2rem;
            right: -2rem;
            z-index: 0;
            width: 10rem;
            height: 10rem;
            transform: rotate(-135deg);
            content: "";

            @include bg("/img/crm/newYear/decor.svg", contain, center);
        }
    }

    &__head {
        position: relative;
        width: 100%;
        padding: 3.5rem 5rem;
        border-bottom: 1px solid #e0e4e9;

        &Back {
            position: absolute;
            top: 3.5rem;
            left: 0;
            z-index: 2;
            width: 4rem;
            height: 4rem;
            transform: translate(-50%, 0);
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            &Inner {
                width: 100%;
                height: 100%;
                background: #fff;
                border: 0.2rem solid #f0f3f7;
                border-radius: 50%;
                transition: $trans;

                @include button(0.93);
            }

            &Icon {
                width: 2.6rem;
                height: 2.6rem;
                margin: auto;

                @include icon($colorMain);
            }
        }

        &Box {
            position: relative;
            z-index: 1;
            width: calc(100% + 10rem);
            margin: -3.5rem -5rem;
            padding: 3.5rem 5rem;
            overflow: hidden;
            border-radius: 3rem 3rem 0 0;
        }

        &Inner {
            position: relative;
            width: 100%;
        }

        &Title {
            width: 100%;
            transition: $trans;

            &._dynamic {
                position: relative;
            }

            &Inner {
                color: $colorDark2;
                font-weight: 600;
                font-size: 3rem;
                line-height: 1.15;
            }

            &._dynamic &Inner {
                position: absolute;
                top: 0;
                left: 0;
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                    pointer-events: none;

                    &._prev {
                        transform: translate(-7rem, 0);
                    }

                    &._next {
                        transform: translate(7rem, 0);
                    }
                }
            }

            &._dynamic._static &Inner {
                position: relative;

                &:not(._show) {
                    display: none;
                }
            }

            &Icon {
                display: inline-block;
                width: 2.7rem;
                height: 2.7rem;
                margin-left: 1rem;
                transform: translate(0, 0.6rem);
            }
        }

        &Pagination {
            position: absolute;
            top: 0;
            right: -2rem;
            z-index: 3;
            align-items: center;
            padding: 0.8rem 1rem;
            background: #fff;
            border: 1px solid rgba($colorMain, 0.1);
            border-radius: 36rem;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            &Item {
                width: 0.9rem;
                height: 0.9rem;
                background: $colorMain;
                border-radius: 50%;
                transition: $trans;

                @include button(0.9);

                &:not(._current) {
                    opacity: 0.32;
                }

                & + & {
                    margin-left: 0.6rem;
                }
            }
        }

        &Progress {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
            color: $colorGreyDark;
            font-size: 2.4rem;
            line-height: 1.2;
            transition: $trans;

            &._low {
                color: #ffab00;
            }

            &._medium {
                color: #8dca49;
            }

            &._high {
                color: #66ca49;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
        overflow: hidden;
        transition: $trans;

        &Block {
            width: 100%;
            padding: 4.5rem 5rem;
        }

        &._dynamic &Block {
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                pointer-events: none;

                &._prev {
                    transform: translate(-7rem, 0);
                }

                &._next {
                    transform: translate(7rem, 0);
                }
            }
        }

        &._dynamic._static &Block {
            position: relative;

            &:not(._show) {
                display: none;
            }
        }

        &Groups {
            width: 100%;
            margin-bottom: 2rem;
        }

        &Group {
            width: 100%;

            & + & {
                margin-top: 2.5rem;
            }

            &Support {
                max-width: 100%;
                margin-bottom: 1.3rem;
                color: $colorDark2;
                font-size: 1.4rem;
                letter-spacing: 0.05rem;
                text-transform: uppercase;

                &Icon {
                    display: inline-block;
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-left: 0.5rem;
                    transform: translate(0, 0.4rem);

                    @include icon($colorBlue);
                }
            }

            &Fields {
                width: 100%;
            }

            &Field {
                width: 100%;

                & + & {
                    margin-top: 1rem;
                }

                &Box {
                    width: 100%;
                    height: 6rem;

                    & .field {
                        border-radius: 1.5rem;
                    }

                    & .field__value {
                        padding: 0.8rem 2.3rem !important;
                        font-size: 1.7rem !important;
                    }

                    & .field__icon {
                        right: 2.2rem;
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }

                &Error {
                    width: 100%;
                    transition: $trans;

                    &:not(._show) {
                        opacity: 0;
                    }

                    &Wrapper {
                        width: 100%;
                        padding: 0.6rem 2.3rem;
                    }

                    &Inner {
                        max-width: 100%;
                        color: $colorAlert;
                        font-size: 1.6rem;
                        line-height: 1.3;
                    }
                }
            }
        }

        &Icon {
            width: 5rem;
            height: 5rem;
            margin: 0 auto 2rem;
        }

        &Description {
            max-width: 100%;
            margin: -0.5rem 0 2rem;
            color: $colorDark2;
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 1.5;

            &._notMargin {
                margin-bottom: 0;
            }

            &._center {
                text-align: center;
            }

            & b {
                font-weight: 600;
            }
        }

        &Code {
            width: 100%;

            // margin-bottom: 12px;

            & .codeBox__input {
                height: 5.5rem !important;
                font-size: 2.4rem !important;
                border-radius: 1rem;
            }
        }

        &Button {
            width: 100%;
            height: 6rem;

            & .button {
                border-radius: 1.5rem;
                box-shadow: 0 0.3rem 0.5rem 0 rgb(62 106 237 / 56%);
            }

            & .button__content {
                font-size: 1.8rem !important;
            }

            & .button__icon {
                width: 2rem;
                height: 2rem;
                margin-right: -1rem !important;
                margin-left: 1rem !important;
            }
        }

        &Info {
            max-width: 100%;
            margin: 1.4rem 0 5rem;
            color: $colorDark2;
            font-size: 1.4rem;
            line-height: 1.4;
            text-align: center;

            & span {
                color: $colorMain;
                line-height: 1;
                border-bottom: 1px solid rgba($colorMain, 0.64);
            }
        }

        &Links {
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 2rem;
        }

        &Link {
            position: relative;
            max-width: 100%;
            margin: 0 auto;
            color: $colorMain;
            font-size: 1.7rem;
            text-align: center;
            transition: $trans;

            @include button(0.95);

            &._loading {
                padding-right: 2.4rem;
            }

            &Loader {
                position: absolute;
                top: 50%;
                left: 100%;
                z-index: 2;
                width: 2.5rem;
                height: 2.5rem;
                margin-left: -1.5rem;
                transform: translate(0, -50%);
                transition: $trans;

                &:not(._show) {
                    opacity: 0;
                }
            }

            &Icon {
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin: 0 0.7rem 0 0;
                transform: translate(0, 3px);

                @include icon($colorMain);
            }
        }
    }
}
