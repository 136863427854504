.manualField {
    width: 100%;
    height: 100%;
    align-items: center;

    &._area {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    &._vertical {
        flex-direction: column;
        align-items: flex-start
    }

    &__support {
        font-size: 14px;
        font-weight: 500;
        color: $colorDark2;
        padding-right: 6px;
        white-space: nowrap;
    }

    &._area &__support {
        padding-right: 0;
        padding-bottom: 6px;
    }

    &._heightSize &__support {
        padding-right: 12px;
        font-size: 15px;
    }

    &__box {
        height: 24px;
        flex: 1 1 auto;
        margin-left: auto;
        position: relative;

        &Inner {
            width: 100%;
            height: 100%;
        }
    }

    &._heightSize &__box {
        height: 30px;
    }

    &._heightSize .field {
        border-radius: 8px;
    }

    &._heightSize .field__value {
        padding: 4px 12px;
        font-size: 15px;
    }

    &._heightSize .field._area .field__value {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &._heightSize .select__view {
        padding-right: 28px;

        &::after {
            right: 14px;
        }
    }

    &._heightSize .select__view,
    &._heightSize .select__dropItem {
        padding-left: 12px;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &._heightSize .select__viewInner,
    &._heightSize .select__dropItem {
        font-size: 15px;
    }

    &._heightSize .select__dropItemCheck {
        width: 14px;
        height: 14px;
        top: 6px;
        right: 9px;
    }

    &._withAction &__boxInner {
        padding-right: 28px;
    }

    &._withAction &__boxInner {
        padding-right: 25px;

        & .field {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }

    &._vertical &__box {
        width: 100%;
        flex: none;
        margin-top: 12px;
    }

    &._area &__box {
        width: 100%;
        height: 64px;
        margin-left: 0;
    }

    &__action {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: $colorBlueBack;
        z-index: 2;
        transition: $trans;
        @include button(.94);
        overflow: hidden;
        background: #ECF1FF;
        border-radius: 2px 6px 6px 2px;

        &Loader {
            background: inherit;
            z-index: 3;

            &Item {
                width: 16px;
                height: 16px;
            }
        }

        &Icon {
            @include icon($colorDark2);
            width: 16px;
            height: 16px;
            margin: auto;
            @include icon($colorMain);
        }

        &._checkSmzInn &Icon {
            // transform: translate(0, -1px);
        }

        &._appChat &Icon,
        &._telegramChat &Icon {
            width: 13px;
            height: 13px;
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
        width: 100%;
        margin-top: 8px;
        border-radius: 6px;
        background: $colorBlueActive;
        border: 1px solid $colorBlue;
        overflow-y: auto;
        max-height: 100px;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        &Item {
            width: 100%;
            padding: 4px 6px;
            font-size: 14px;
            line-height: 1.4;
            font-weight: 500;
            color: $colorDark2;
            transition: $trans;
            @include button(.98);
        }
    }

    &__loader {
        background: #fff;
        border-radius: 6px;

        &Icon {
            width: 16px;
            height: 16px;
        }
    }

    &._switch &__box {
        height: 24px;
        padding: 1px 0;
    }

    &._executor._type &__box,
    &._executor._town &__box,
    &._executor._inn &__box {
        max-width: 154px;
    }

    &._executor._inactive &__box {
        max-width: 36px;
        margin-left: 14px;
        margin-right: auto;
    }

    &._executor._phone &__box {
        max-width: 138px;
    }

    &._executor._organization &__box {
        max-width: 60px;
    }

    &._executor._rate &__box {
        max-width: 40px;
    }

    &._executor._country &__box,
    &._executor._number &__box {
        max-width: 72px;
    }

    &._executor._view &__box {
        max-width: 90px;
    }

    &._executor._series &__box {
        max-width: 56px;
    }

    &._executor._dateGet &__box,
    &._executor._dateAction &__box,
    &._executor._birthday &__box,
    &._executor._categories &__box {
        max-width: 90px;
    }

    &._executor._code &__box {
        max-width: 74px;
    }

    &._executor._nameGet &__box,
    &._executor._addressReg &__box,
    &._executor._addressFact &__box {
        max-width: 330px;
    }

    &._executor._dateExp &__box {
        max-width: 98px;
    }

    &._car._type &__box,
    &._car._status &__box,
    &._car._sts &__box {
        max-width: 88px;
    }

    &._car._color &__box,
    &._car._brand &__box {
        max-width: 88px;
    }

    &._car._perm-mass &__box,
    &._car._full-mass &__box {
        max-width: 64px;
    }

    &._car._long &__box,
    &._car._width &__box,
    &._car._height &__box {
        max-width: 64px;
    }

    &._car._heightWithFloor &__box,
    &._car._heightCargo &__box {
        max-width: 64px;
    }

    &._tariff._long &__box,
    &._tariff._width &__box,
    &._tariff._height &__box {
        max-width: 64px;
    }

    &._tariff._minPrice &__box,
    &._tariff._minTime &__box,
    &._tariff._freeWaiting &__box {
        max-width: 58px;
    }

    &._tariff._priceTo20 &__box,
    &._tariff._priceTo50 &__box,
    &._tariff._priceTo100 &__box {
        max-width: 64px;
    }

    &._tariff._overThanMin &__box,
    &._tariff._priceOfMinute &__box,
    &._tariff._passengers &__box {
        max-width: 58px;
    }

    &._tariff._distance1OfMkad &__box,
    &._tariff._distance2OfMkad &__box,
    &._tariff._priceOfMkad &__box {
        max-width: 58px;
    }

    // &._organization._secondName &__box,
    // &._organization._email &__box {
    //     max-width: 150px;
    // }
    // &._organization._firstName &__box,
    // &._organization._phone &__box {
    //     max-width: 136px;
    // }
}