.publicLegalNav {
    width: 100%;

    &__title {
        position: relative;
        width: 100%;
        margin-bottom: 0.2rem;
        padding: 3.2rem 4.5rem;
        color: $colorDark2;
        font-weight: 600;
        font-size: 2.3rem;
        background: #ecf2fb;
        border-radius: 3rem 3rem 0 0;
        transition: $trans;

        @include mob {
            margin-bottom: 3rem;
            padding: 0;
            font-size: 3rem;
            background: none;
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        right: 3rem;
        z-index: 2;
        width: 3rem;
        height: 3rem;
        transform: translate(0, -50%);
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }
    }

    &:not(._ready) &__title {
        border-radius: 3rem;
    }

    &__content {
        @extend %noscroll;

        width: 100%;
        padding: 2.5rem 4.5rem;
        background: #f5f7fa;
        border-radius: 0 0 3rem 3rem;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
        }

        @include mob {
            max-height: calc(100vh - 30rem);
            padding: 0;
            overflow-y: auto;
            background: none;
            border-radius: 0;
        }
    }

    &__item {
        width: 100%;

        & + & {
            margin-top: 2.2rem;
            padding-top: 2.2rem;
            border-top: 1px solid rgba(#9da8b4, 0.5);
        }
    }

    &__link {
        color: $colorDark2;
        font-size: 1.8rem;
        line-height: 1.35;
        transition: $trans;

        @include mob {
            font-size: 2.3rem;
        }
    }

    &__item._current &__link {
        color: $colorMain;
        font-weight: 600;
    }
}
