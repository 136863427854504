.v2widgetWallet {
    width: 100%;
    padding: 24px;
    background: $colorBlueBack;
    border: 1px solid transparent;
    border-radius: 16px;
    transition: $trans;

    &._current {
        background: $colorBlueActive;
        border-color: rgba($colorMain, 0.5);
    }

    &._card {
        padding: 18px 24px;
    }

    &__inner {
        position: relative;
        width: 100%;
    }

    &__items {
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }

    &__item {
        &._bankName {
            width: 100%;
            margin-top: 26px;
        }

        &:not(._bankName, :first-child) {
            position: relative;
            margin-left: 24px;
            padding-left: 24px;

            &::before {
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 1px;
                height: 24px;
                background: #c8d1dd;
                content: "";
            }
        }

        &._current {
            transition: $trans;
        }
    }

    &:not(._current) &__item._current {
        opacity: 0;
        pointer-events: none;
    }

    &__support {
        max-width: 100%;
        margin-bottom: 6px;
        color: $colorGreyDark;
        font-weight: 600;
        font-size: 13px;
    }

    &__value {
        max-width: 100%;
        color: $colorDark2;
        font-size: 15px;
    }

    &__item._current &__value {
        position: relative;
        padding-left: 12px;
        font-weight: 600;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 4px;
            height: 4px;
            background: $colorMain;
            border-radius: 50%;
            transform: translate(0, -50%);
            content: "";
        }
    }

    &__item._short &__value {
        padding: 5px 7px;
        color: #fff;
        font-size: 13px;
        background: #3e454c;
        border-radius: 7px;
    }

    &__actions {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &._card &__actions {
        top: 50%;
        bottom: auto;
        transform: translate(0, -50%);
    }

    &__action {
        width: 22px;
        height: 22px;

        @include icon(#c6cbd4);

        & + & {
            margin-left: 6px;
        }
    }
}
