.dealGroupTrigger {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 16px;

    &__inner {
        position: relative;
    }

    &__preview {
        width: 40px;
        height: 40px;
        background: $colorBlueBack;
        border-radius: 50%;

        &Icon {
            display: block;
            width: 100%;
            height: 100%;
            margin: auto;

            @include icon($colorGreyDark);
        }
    }

    &__content {
        width: calc(100% - 40px);
        padding-left: 12px;
    }

    &__support {
        margin-bottom: 3px;
        padding-top: 2px;
        color: $colorDark2;
        font-weight: 300;
        font-size: 13px;
    }

    &__title {
        color: $colorDark2;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.3;

        & b {
            font-weight: 600;
        }

        &Description {
            margin-top: 2px;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.3;

            & b {
                font-weight: 500;
            }
        }
    }

    &__more {
        position: absolute;
        top: -6px;
        right: -9px;
        z-index: 2;
        width: 20px;
        height: 20px;

        @include icon($colorGreyDark);
        @include button(0.94);
    }
}
