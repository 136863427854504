.v2sideBarCompanyList {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #eaedee;
    border: 1px solid #fff;
    border-radius: 3px 3px 14px 14px;
    box-shadow: 0 0 32px 0 rgba(#9da8b4, 0.24);
    transition: $trans;

    &._admin {
        background: #e2ecff;
    }

    &__scroll {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        overflow-y: auto;

        &Inner {
            width: 100%;
            padding: 8px 24px;
        }
    }

    &__companies {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &._admin &__company:not(._last) {
        border-color: #fff;
    }

    &__company {
        align-items: center;
        width: 100%;
        padding: 18px 0;
        border-bottom: 1px solid transparent;
        transition: $trans;

        &:not(._last) {
            border-color: #e0e4e9;
        }

        &Logo {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 1;
            width: 54px;
            height: 54px;
            overflow: hidden;
            border-radius: 12px;
            transform: translate(0, -50%);

            & .v2avatar__text {
                font-size: 22px;
            }
        }

        &Content {
            width: 100%;
            padding-left: 66px;
        }

        &Name {
            max-width: 100%;
            margin-bottom: 4px;
            overflow: hidden;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &Balance {
            color: $colorDark2;
            font-weight: 300;
            font-size: 13px;
            white-space: nowrap;
        }
    }

    &._admin &__info {
        border-color: #fff;
    }

    &__info {
        align-items: center;
        padding: 12px 0 24px;
        border-top: 1px solid #e0e4e9;

        &Title {
            margin-bottom: 3px;
            color: $colorDark2;
            font-weight: 600;
            font-size: 16px;
            line-height: 1.3;
            white-space: nowrap;
        }

        &Description {
            margin-bottom: 10px;
            color: $colorDark2;
            font-weight: 300;
            font-size: 13.5px;
            line-height: 1.3;
            white-space: nowrap;
        }

        &Button {
            height: 32px;
            white-space: nowrap;
        }
    }
}
