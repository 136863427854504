.contentBlogArticleCard {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgba(216, 220, 228, 0.72);

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__head {
        width: 100%;
        height: 120px;
        position: relative;
        background: $colorBlueBack;
        z-index: 1;
    }

    &._emptyImage &__head {
        background: $colorMain;

        &::before {
            display: none;
        }
    }

    &:not(._info):not(._googleVision) &__head {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(#000, .32);
            z-index: 2;
            pointer-events: none;
        }
    }

    &._info &__head {
        background: $colorMain;
    }

    &__pin {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 3;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        justify-content: center;
        align-items: center;

        &Icon {
            width: 14px;
            height: 14px;
            @include icon($colorMain);
        }
    }

    &__switch {
        position: absolute;
        top: 14px;
        left: 14px;
        z-index: 3;
        width: 36px;
        height: 22px;

        &Loader {
            position: absolute;
            top: 50%;
            left: 100%;
            margin-left: 8px;
            transform: translate(0, -50%);
            z-index: 2;
            transition: $trans;
            width: 20px;
            height: 20px;

            &:not(._show) {
                opacity: 0;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;
        display: block;
        margin: auto;
    }

    // &._info &__image._parent {}

    &._info &__image:not(._parent) {
        object-fit: contain;
        width: 45%;
        height: 45%;
        margin: auto;
        filter: grayscale(1) brightness(1000%);
    }

    &._announce &__image {
        width: 45%;
        height: 45%;
        @include icon(#fff);
        transform: translate(-10px, 0);
    }

    &._legal &__image {
        width: 35%;
        height: 35%;
        @include icon(#fff);
    }

    &__content {
        width: 100%;
        height: calc(100% - 120px);
        min-height: 140px;
        background: #fff;
        padding: 16px 18px 18px 18px;
    }

    &._googleVision &__content {
        min-height: auto;
    }

    &__title {
        max-width: 100%;
        font-size: 15.5px;
        line-height: 1.3;
        font-weight: 600;
        color: $colorDark;
        margin-bottom: 6px;
    }

    &._googleVision &__title {
        // font-size: 17px;
        margin-bottom: 4px;
    }

    &__descriptions {
        width: 100%;
        margin-bottom: 12px;
    }

    &__description {
        width: 100%;
        font-size: 14px;
        line-height: 1.4;
        color: $colorGreyDark;

        // white-space: nowrap;
        &+& {
            margin-top: 4px;
        }
    }

    &._googleVision &__description {
        // font-size: 15px;
    }

    &__edit {
        align-items: center;
        font-size: 14px;
        color: $colorMain;
        margin-top: auto;

        &Icon {
            width: 12px;
            height: 12px;
            margin: 2px 0 0 6px;
            @include icon($colorMain);
        }
    }
}