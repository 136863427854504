.manualComments {
    width: 100%;
    height: 100%;
    position: relative;

    &__inner {
        @extend %noscroll;
        width: calc(100% + 44px);
        height: calc(100% + 36px);
        position: relative;
        margin-top: -18px;
        margin-left: -22px;
        overflow-y: auto;
        padding: 12px 22px;

        &Content {
            width: 100%;
            position: relative;
            transition: $trans;
        }
    }

    &__items {
        width: 100%;
        position: relative;
        transition: $trans;
        z-index: 2;

        &Inner {
            width: 100%;
        }
    }

    &__item {
        width: 100%;
        border-radius: 8px;
        background: #eff4ff;
        padding: 5px;
        transition: $trans;
        position: absolute;
        top: 0;
        left: 0;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            width: 100%;
            height: 26px;
            position: relative;
            align-items: flex-start;
            transition: $trans;
        }

        &Input {
            @extend %noscroll;
            width: calc(100% - 40px);
            height: 26px;
            padding: 3px 6px;
            font-size: 14.5px;
            line-height: 1.3;
            color: $colorDark2;
            resize: none;
            font-family: 'PTRoot';
            font-weight: 500;
            overflow: hidden;
            overflow-y: auto;
            display: block;
            transition: $trans;
        }

        &Info {
            width: 40px;
            height: 26px;
            justify-content: flex-end;
            align-items: center;
        }

        &User {
            width: 30px;
            height: 30px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Action {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }
        }

        &Date {
            position: absolute;
            bottom: 8px;
            right: 8px;
            font-size: 12px;
            color: $colorGreyDark;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &._isDisabled &__item {
        background: $colorBlueBack;
        padding-bottom: 26px;

        &Date._show {
            opacity: 1;
            visibility: visible;
        }
    }

    &._static &__item {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &._static &__item+&__item {
        margin-top: 8px;
    }

    &__btn {
        width: 100%;
        padding: 8px 5px 8px 11px;
        transition: $trans;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;

        &:not(._show) {
            opacity: 0;
        }

        &Inner {
            align-items: center;
            position: relative;
        }

        &Content {
            font-size: 14.5px;
            font-weight: 500;
            color: $colorMain;
        }

        &Action {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            width: 26px;
            height: 26px;
            z-index: 2;
        }
    }

    &._static &__btn {
        margin-top: 14px;
        position: relative;

        &:not(._show) {
            display: none;
        }
    }
}