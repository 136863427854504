.manualFiles {
    width: 100%;
    height: 100%;

    &__inner {
        display: inline-flex;
        margin-bottom: 8px;
    }

    &._executor &__inner {
        width: 100%;
    }

    &__item {
        width: 100px;
        height: 100px;
    }

    &._executor &__item {
        width: 92px;
        height: 92px;
    }

    &._executor &__item+&__item {
        margin-left: 10px;
    }

    &__info {
        align-items: center;
        max-width: 100%;
        font-size: 11px;
        font-weight: 500;
        color: $colorDark2;
        margin: auto 0 6px 0;

        &Item {
            white-space: nowrap;

            &._parent {
                margin-left: 2px;
            }
        }
    }
}